import { EmojiSadIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import Jumbotron from "../components/ui/Jumbotron";
import Button from "../packages/button/Button";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Jumbotron className="bg-secondary text-typography-1 mx-auto">
      <h1 className="text-xl py-4 md:text-2xl mb-3 flex items-center">
        <EmojiSadIcon className="h-8 pr-6" /> Sorry!, we can't find that page!
      </h1>
      <p>Is probably us, but we can't find the page you are looking for!</p>
      {/* <p>
        Can you try again and if the problem persists contact
        <a href="mailto:eopl_support@jaguarlandrover.com" className="text-info"> eopl_support@jaguarlandrover.com </a>
      </p> */}
      <br />
      <span style={{ fontSize: "25px" }}>Module under development...</span>
      <br />
      <br />
      <Button color="primary" onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </Jumbotron>
  );
};

export default NotFound;
