import { BanIcon } from "@heroicons/react/solid";
import { useNavigate, useLocation } from "react-router-dom";
import Jumbotron from "../components/ui/Jumbotron";
import Button from "../packages/button/Button";

const NotAllowed = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const from = state ? state.from.pathname : "/";

  return (
    <Jumbotron className="bg-secondary text-typography-1 mx-auto">
      <h1 className="text-lg md:text-2xl py-4 mb-3 flex items-center">
        <BanIcon className="h-12 pr-6" /> Sorry, you aren't authorised to access this page
      </h1>
      <p>
        If you need access to this page, please contact
        <a href="mailto:eopl_support@jaguarlandrover.com" className="text-info">
          {" "}
          eopl_support@jaguarlandrover.com{" "}
        </a>
        to request access
      </p>
      <br />
      <Button color="primary" onClick={() => navigate(from, { replace: true })}>
        Go Back
      </Button>
    </Jumbotron>
  );
};

export default NotAllowed;
