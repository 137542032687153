import { createSlice } from "@reduxjs/toolkit";
import { sortAz } from '../utils/utility';

const contactsSlice = createSlice({
  name: "contacts",
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    replaceContacts(state, action) {
      state.data = action.payload;
    },
    removeContact(state, action) {
      const id = action.payload;
      state.changed = true;
      state.data = state.data.filter((d) => d.id !== id);
    },
    updateContact(state, action) {
      const data = action.payload;
      const updatedData = [...state.data.filter((d) => d.id !== data.id), data];
      state.data = updatedData.sort(sortAz);
    },
  },
});

export const contactsActions = contactsSlice.actions;

export default contactsSlice;
