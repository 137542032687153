import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter } from '../packages/modal/Modal';
import Button from "../packages/button/Button";
import { deleteImage, getImages, uploadImage } from "../store/eoplActions";

const ImageUploadModal = ({ data, closeModal }) => {
    const dispatch = useDispatch();
    const [imageList, setImageList] = useState([]);
    const [parentData, setParentData] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [imagesCount, setImagesCount] = useState(0);
    const fileInput = useRef(null);

    useEffect(() => {
        setParentData({ partId: data?.id, type: data?.type });
        const fetchData = async () => {
            const imageData = await dispatch(getImages(data?.type, data?.id));
            setImageList(imageData?.map((data, index) => ({ ...data, index })));
        };
        fetchData();
    }, [data, dispatch]);

    useEffect(() => {
        if (imageList.length > 0) {
            setCurrentImage(imageList[0]);
            setImagesCount(imageList.length);
        }else{
            setImagesCount(0);
        }
    }, [imageList]);

    const handleImagePrev = () => {
        const currentIndex = currentImage?.index;
        if (currentIndex > 0) {
            setCurrentImage(imageList[currentIndex - 1]);
        }
    };

    const handleImageNext = () => {
        const currentIndex = currentImage?.index;
        if (currentIndex < imageList.length - 1) {
            setCurrentImage(imageList[currentIndex + 1]);
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = `data:image/png;base64,${currentImage?.base64Url}`;
        link.download = currentImage?.name;
        link.click();
    };

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        if (fileInput.current && typeof fileInput.current.click === 'function') {
            await dispatch(uploadImage(file, { ...parentData, name: file?.name }));
            const imageData = await dispatch(getImages(parentData?.type, parentData?.partId));
            setImageList(imageData.map((data, index) => ({ ...data, index })));
            fileInput.current.value = ''; // Clear the file input
        }
    };

    const handleDelete = async () => {
        if (currentImage) {
            await dispatch(deleteImage(parentData?.type, currentImage?.id));
            const imageData = await dispatch(getImages(parentData?.type, parentData?.partId));
            setImageList(imageData.map((data, index) => ({ ...data, index })));
        }
    };

    return (
        <Modal isOpen={true} animate={true} closeOnClickOutside={false}>
            <ModalHeader>Attached Images</ModalHeader>
            <ModalBody>
                <div className='bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        {imageList.length > 0 ? (
                            <img src={`data:image/png;base64,${currentImage?.base64Url}`} alt="Uploaded" />
                        ) : (
                            <span>No Data Available</span>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleImagePrev} disabled={currentImage?.index === 0 || imagesCount === 0}>
                    Prev
                </Button>
                <Button onClick={handleImageNext} disabled={currentImage?.index === imageList.length - 1 || imagesCount === 0}>
                    Next
                </Button>
            </ModalFooter>
            <ModalFooter style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '10px', alignItems: 'center' }}>
                <Button onClick={closeModal} color="danger">
                    Close
                </Button>
                <div style={{ textAlign: 'center' }}>
                    <b>Total images - </b> {imagesCount}
                </div>
                <input
                    ref={fileInput}
                    type='file'
                    accept=".png,.jpg,.jpeg"
                    onChange={handleUpload}
                    style={{ gridColumn: 'span 2' }}
                />
                <Button onClick={handleDelete} disabled={imagesCount === 0}>
                    Delete
                </Button>
                <Button onClick={handleDownload} disabled={imagesCount === 0}>
                    Download
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ImageUploadModal;
