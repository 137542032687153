// import { getRequest } from "../axios";
import { dashboardActions } from "./dashboardSlice";
import Toast from "../components/ui/Toast";
import { getRequest } from "../axios";

// import { customDates, timelineData } from "../components/dashboard/swimlaneData"; // Import the data

export const setLoading = (state) => {
  return async (dispatch) => {
    dispatch(dashboardActions.setLoading(state));
  };
};

export const resetDashboard = () => {
  return async (dispatch) => {
    dispatch(dashboardActions.replaceDashboard([]));
  };
};

export const getDashboard = () => {
  return async (dispatch) => {
    try {
      let dashboardTimeLine = await getRequest("dashboardTimeLine/");
      let dashboardQuaterWiseData = await getRequest("dashboardQuarters/");
      const dashboard = {
        timeline: dashboardTimeLine?.formated_data,
        customDates: [],
        quaterWiseData: dashboardQuaterWiseData?.tableData,
        currentQuaterProjectTotals: dashboardQuaterWiseData?.total_EOpl_current_quarter,
        currentQuaterBrandWiseTotals: dashboardQuaterWiseData?.current_quarter_brand,
        nextQuaterBrandWiseTotals: dashboardQuaterWiseData?.next_quarter_brand,
      };
      setTimeout(() => {
        dispatch(dashboardActions.replaceDashboard(dashboard));
        dispatch(dashboardActions.setLoading(false));
      }, 50);
    } catch (error) {
      console.error("getDashboard: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};
