import { getRequest } from '../axios';
import { dropdownActions } from './dropdownSlice';

export const getAllDropdowns = () => {
	return async (dispatch) => {
		try {
			const details = await getRequest('dropdown/');
			dispatch(dropdownActions.replaceDropdowns(details));
		} catch (error) {}
	};
};