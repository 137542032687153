import { shoppingListActions } from "./shoppingListSlice";
import axios, { deleteRequest, getRequest, postRequest, putRequest } from "../axios";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";
// import { data } from "autoprefixer";
import fileDownload from "js-file-download";
import { getFormattedDate } from "../utils/utility";
// import { exportToExcel } from "../utils/exportUtility";

export const setChanged = (value) => {
  return (dispatch) => {
    dispatch(shoppingListActions.setChanged(value));
  };
};

export const setSearchValue = (value) => {
  return (dispatch) => {
    dispatch(shoppingListActions.setSearchValue(value));
  };
};

export const resetSpo = () => {
  return (dispatch) => {
    dispatch(shoppingListActions.resetSpo());
  };
};

export const resetAllSpoData = () => {
  return (dispatch) => {
    dispatch(shoppingListActions.setSearchValue());
    dispatch(shoppingListActions.replaceEoplAllData());
  };
};

export const resetUploaded = () => {
  return (dispatch) => {
    dispatch(shoppingListActions.resetUploaded());
  };
};

export const updateField = (field, value) => {
  return (dispatch) => {
    dispatch(shoppingListActions.updateSpoData({ field: field, value: value }));
  };
};

export const resetCheckForExisting = () => {
  return (dispatch) => {
    dispatch(shoppingListActions.existingSpo());
  };
};

export const checkForExisting = (spo) => {
  return async (dispatch) => {
    try {
      const existing = await getRequest("spo/existing/" + spo);
      dispatch(shoppingListActions.existingSpo(existing));
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const uploadShoppingList = (file, eoplProgramRefId) => {
  return async (dispatch) => {
    const sendRequest = () => {
      const url = `/uploadShoppingList/`;
      const formData = new FormData();
      formData.append("excel_file", file);
      formData.append("eoplProgramId", eoplProgramRefId);
      // formData.append("VersionDetails", data.versionDetails.toString());
      dispatch(globalActions.setLoading({ loading: true, label: "Uploading Shopping List" }));
      dispatch(shoppingListActions.setUploading(true));
      return axios
        .post(url, formData, {
          responseType: "blob",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((response) => {
          const blob = new Blob([response?.data], {
            type: "application/vnd.ms-excel",
          });
          fileDownload(blob, `Shopping_List_Upload_Report ${getFormattedDate(Date.now(), true)}.xlsx`);
          if (response?.status === 201) {
            Toast({ status: "success", message: "File processed successfully database insertion" });
          } else if (response?.status === 207) {
            Toast({ status: "warning", message: "File processed with partial database insertion" });
          } else if (response?.status === 400) {
            Toast({ status: "error", message: "File processed unsuccessfully database insertion" });
          }
        })
        .catch((e) => {
          Toast({ status: "error", message: "Upload failed " + e.message });
        })
        .finally(() => {
          dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
          // dispatch(shoppingListActions.existingSpo());
          dispatch(shoppingListActions.setUploading());
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const getShoppingList = (eoplProgramRefId, indx) => {
  return async (dispatch) => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: "Getting Shopping List Data" }));
      const details = await getRequest("shoppingList/?eoplProgramId=" + eoplProgramRefId);
      dispatch(
        shoppingListActions.replaceShoppingListData({
          shoppingList:
            indx === undefined
              ? details?.map((v) => ({ ...v, isSelected: true })) || []
              : details?.map((currElement) => {
                  if (indx === currElement.pfApn) {
                    return { ...currElement, isSelected: false };
                  } else {
                    return { ...currElement, isSelected: true };
                  }
                }),
        })
      );
    } catch (error) {
      Toast({ status: "error", message: "Error in getting Program Details" });
    } finally {
      dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
    }
  };
};

export const editShoppingList = (customShoppingListObj, itemId) => {
  return async (dispatch) => {
    try {
      await putRequest("shoppingList/" + itemId + "/", customShoppingListObj.constructedObjectForApiUpdate);
      // dispatch(shoppingListActions.updateShoppingListData({shoppingList: responseData  || [] }))
      Toast({ status: "Success", message: "Shopping List Item Edited" });
    } catch (error) {
      console.error("Edit Shopping list Item: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const createShoppingList = (shoppingData) => {
  return async (dispatch) => {
    try {
      // dispatch(
      //   globalActions.setLoading({
      //     loading: true,
      //     label: `Saving Program ${data.eoplProgramRefId}`,
      //   }),
      // );
      await postRequest("shoppingList/", shoppingData);

      // dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "Success", message: "Shopping List Items Added" });
      // dispatch(fetchData());
    } catch (error) {
      console.error("Add Shopping list Item: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteShoppingListItem = (item) => {
  return async (dispatch) => {
    try {
      dispatch(setSearchValue());
      const response = await deleteRequest(`shoppingList/${item.id}/`);
      Toast({ status: "success", message: response.message });
      dispatch(shoppingListActions.removeSpoAllData(item.id));
      dispatch(setSearchValue(item));
    } catch (error) {
      console.error("updateUser: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const email = (type) => {
  let url = "";

  if (type === "pdf") url = "spo/sendgridpdf";
  if (type === "excel") url = "spo/sendgrid";

  return async (dispatch) => {
    const sendRequest = async () => {
      Toast({ status: "info", message: `${url}` });
      return axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            Toast({ status: "success", message: `${response.data}` });
            return response.data;
          }
          throw new Error(response.data.message);
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const download = (type, spo) => {
  // const url = `spo/downloadspo${type}/${spo.id}`;
  // return async (dispatch) => {
  //   try {
  //     const response = await getRequest(url, { responseType: "blob" });
  //     if (response) {
  //       if (type === "pdf") {
  //         const blob = new Blob([response], { type: "application/pdf" });
  //         fileDownload(blob, `SPO ${spo.spo}.pdf`);
  //       } else {
  //         const blob = new Blob([response], {
  //           type: "application/vnd.ms-excel",
  //         });
  //         if (!spo?.spoVersion) fileDownload(blob, `SPO ${spo.spo}.xlsx`);
  //         else
  //           fileDownload(
  //             blob,
  //             `SPO ${spo.spo}_${spo.spoVersion.versionName}.xlsx`
  //           );
  //       }
  //       if (!spo?.spoVersion)
  //         Toast({ status: "success", message: `SPO ${spo.spo} Downloaded` });
  //       else
  //         Toast({
  //           status: "success",
  //           message: `SPO ${spo.spo}_${spo.spoVersion.versionName} Downloaded`,
  //         });
  //     }
  //   } catch (error) {
  //     console.error("download: " + error.message);
  //     Toast({ status: "error", message: error.message });
  //   }
  // };
};
