import { eoplActions } from "./eoplSlice";
import axios, { deleteRequest, getRequest, postRequest, putRequest } from "../axios";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";
// import { data } from "autoprefixer";
import fileDownload from "js-file-download";
// import { exportToExcel } from "../utils/exportUtility";
import { getFormattedDate } from "../utils/utility";

export const setChanged = (value) => {
  return (dispatch) => {
    dispatch(eoplActions.setChanged(value));
  };
};

export const resetEopl = () => {
  return (dispatch) => {
    dispatch(eoplActions.resetEopl());
  };
};

export const setSearchValue = (value) => {
  return (dispatch) => {
    dispatch(eoplActions.setSearchValue(value));
  };
};

export const resetAllSpoData = () => {
  return (dispatch) => {
    dispatch(eoplActions.setSearchValue());
    dispatch(eoplActions.replaceEoplAllData());
  };
};

export const resetUploaded = () => {
  return (dispatch) => {
    dispatch(eoplActions.resetUploaded());
  };
};

export const updateField = (field, value) => {
  return (dispatch) => {
    dispatch(eoplActions.updateEoplData({ field: field, value: value }));
  };
};

export const checkForExisting = (spo) => {
  return async (dispatch) => {
    try {
      const existing = await getRequest("spo/existing/" + spo);
      dispatch(eoplActions.existingSpo(existing));
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const uploadCyclePlan = (file) => {
  return async (dispatch) => {
    const sendRequest = () => {
      const url = `/uploadProgramList/`;
      const formData = new FormData();
      formData.append("excel_file", file);
      dispatch(globalActions.setLoading({ loading: true, label: "Uploading Cycle Plan" }));
      dispatch(eoplActions.setUploading(true));
      return axios
        .post(url, formData, {
          responseType: "blob",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((response) => {
          const blob = new Blob([response?.data], {
            type: "application/vnd.ms-excel",
          });
          fileDownload(blob, `Cycle_Plan_Uploaded_Report ${getFormattedDate(Date.now(), true)}.xlsx`);
          if (response?.status === 201) {
            Toast({ status: "success", message: "File processed successfully database insertion" });
          } else if (response?.status === 207) {
            Toast({ status: "warning", message: "File processed with partial database insertion" });
          } else if (response?.status === 400) {
            Toast({ status: "error", message: "File processed unsuccessfully database insertion" });
          }
        })
        .catch((e) => {
          Toast({ status: "error", message: "Upload failed " + e.message });
        })
        .finally(() => {
          dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
          dispatch(eoplActions.setUploading());
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const cyclePlanAutomationUpload = () => {
  return async (dispatch) => {
    const sendRequest = () => {
      const url = `/cyclePlanAutomation/`;
      dispatch(globalActions.setLoading({ loading: true, label: "Uploading Cycle Plan" }));
      dispatch(eoplActions.setUploading(true));
      return axios
        .post(
          url,
          {},
          {
            responseType: "blob",
            withCredentials: true,
          }
        )
        .then((response) => {
          const blob = new Blob([response?.data], {
            type: "application/vnd.ms-excel",
          });
          fileDownload(blob, `Cycle_Plan_Uploaded_Report ${getFormattedDate(Date.now(), true)}.xlsx`);
          if (response?.status === 201) {
            Toast({ status: "success", message: "File processed successfully database insertion" });
          } else if (response?.status === 207) {
            Toast({ status: "warning", message: "File processed with partial database insertion" });
          } else if (response?.status === 400) {
            Toast({ status: "error", message: "File processed unsuccessfully database insertion" });
          }
        })
        .catch((e) => {
          Toast({ status: "error", message: "Upload failed " + e.message });
        })
        .finally(() => {
          dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
          dispatch(eoplActions.setUploading());
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const getEoplData = (eoplProgramId) => {
  let url = `programList/?eoplProgramRefId=${eoplProgramId}`;

  return async (dispatch) => {
    try {
      const eoplData = await getRequest(url);
      dispatch(eoplActions.replaceEoplData({ data: eoplData[0] }));
    } catch (error) {
      dispatch(globalActions.setLoading({ loading: false }));
      if (error.message.substring(0, 2) !== "40") {
        let status = "error";
        if (error.message === "EOPL Doesn't Exist") {
          status = "warning";
        }
        Toast({ status: status, message: error.message });
      }
    }
  };
};

export const getAllProgramData = (data) => {
  return async (dispatch) => {
    try {
      let url = "programListAlldata/";
      let label = "Fetching All EoPL Programmes";
      if (data && data?.value !== "") {
        url = `/eoplPartsFilter/?${data.partType}=${data.value}`;
        label = `Fetching Programme with Part no: ${data.value}`;
      }
      dispatch(globalActions.setLoading({ loading: true, label: label }));
      const programData = await getRequest(url);
      dispatch(globalActions.setLoading({ loading: false }));
      dispatch(eoplActions.replaceEoplAllData({ allData: programData || [] }));
    } catch (error) {
      if (error.message.substring(0, 2) !== "40") Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
    }
  };
};

export const getProgramById = (id) => {
  return async (dispatch) => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: "Getting Programme Data" }));
      const details = await getRequest(`programList/${id}/`);
      dispatch(eoplActions.updateEoplData(details));
      dispatch(globalActions.setLoading({ loading: false }));
    } catch (error) {
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: "Error in getting Programme Details" });
    }
  };
};

export const createEoplProgrammeDetails = (eoplData) => {
  return async (dispatch) => {
    try {
      //const data =
      await postRequest(`programList/`, eoplData.constructedObjectForApiCreate);
      Toast({ status: "success", message: "Programme Created" });
      // dispatch(
      //   eoplActions.updateEoplAllData({ allData:  eoplData.constructedObjectForStateUpdate, uploaded: true })
      // );
      // dispatch(eoplActions.updateEoplData( eoplData.constructedObjectForStateUpdate));
    } catch (error) {
      dispatch(eoplActions.setError());
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: error.message });
    }
  };
};

export const updateEoplProgrammeDetails = (id, eoplData) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Updating EoPL Programme ${eoplData.constructedObjectForApiUpdate.eoplProgramRefId}`,
        })
      );
      const data = await putRequest(`programList/${id}/`, eoplData.constructedObjectForApiUpdate);

      Toast({ status: "success", message: "Programme Updated" });
      dispatch(eoplActions.updateEoplAllData({ allData: data, uploaded: true }));
      dispatch(eoplActions.updateEoplData(eoplData.constructedObjectForStateUpdate));
      dispatch(globalActions.setLoading({ loading: false }));
    } catch (error) {
      dispatch(eoplActions.setError());
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteEopl = (id, spo) => {
  return async (dispatch) => {
    try {
      dispatch(setSearchValue());
      const response = await deleteRequest(`spo/delete/${id}`);
      Toast({ status: "success", message: response });
      dispatch(eoplActions.removeSpoAllData(id));
      dispatch(setSearchValue(spo));
    } catch (error) {
      console.error("updateUser: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const email = (type) => {
  let url = "";

  if (type === "pdf") url = "spo/sendgridpdf";
  if (type === "excel") url = "spo/sendgrid";

  return async (dispatch) => {
    const sendRequest = async () => {
      Toast({ status: "info", message: `${url}` });
      return axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            Toast({ status: "success", message: `${response.data}` });
            return response.data;
          }
          throw new Error(response.data.message);
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const download = (type, eoplProgramRefId) => {
  const url = `${type}/?eoplProgramId=${eoplProgramRefId}`;

  return async (dispatch) => {
    try {
      if (type === "shoppingListExport") {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: `Downloading Shopping List EoPL Programme ${eoplProgramRefId}`,
          })
        );
      } else if (type === "childPartsExport") {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: `Downloading ChildPart List for EoPL Programme ${eoplProgramRefId}`,
          })
        );
      } else if (type === "programListExport") {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: `Downloading EoPL Programme ${eoplProgramRefId}`,
          })
        );
      }
      const response = await getRequest(url, { responseType: "blob" });
      if (response) {
        const blob = new Blob([response], {
          type: "application/vnd.ms-excel",
        });
        if (type === "shoppingListExport") {
          fileDownload(blob, `Shopping List ${eoplProgramRefId + "  " + getFormattedDate(Date.now(), true)}.xlsx`);
          Toast({ status: "success", message: `Shopping List for ${eoplProgramRefId} Downloaded` });
        } else if (type === "childPartsExport") {
          fileDownload(blob, `ChildPart List ${eoplProgramRefId + "  " + getFormattedDate(Date.now(), true)}.xlsx`);
          Toast({ status: "success", message: `Child Part List for ${eoplProgramRefId} Downloaded` });
        } else if (type === "programListExport") {
          fileDownload(blob, `EoPL ${eoplProgramRefId + "  " + getFormattedDate(Date.now(), true)}.xlsx`);
          Toast({ status: "success", message: `EoPL ${eoplProgramRefId} Downloaded` });
        }
        dispatch(globalActions.setLoading({ loading: false }));
      }
    } catch (error) {
      console.error("download: " + error.message);
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: error.message });
    }
  };
};

export const updateQuarterlyStatus = (id, ...data) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Updating Quarterly Review Status`,
        })
      );
      //const response =
      await putRequest(`nextQuarterReview/${id}/`, data[0]);
      Toast({ status: "success", message: "Status Updated" });
      // dispatch(
      //   eoplActions.updateEoplAllData({ allData: data, uploaded: true })
      // );
      dispatch(eoplActions.updateEoplData(data[1]));
      dispatch(globalActions.setLoading({ loading: false }));
    } catch (error) {
      dispatch(eoplActions.setError());
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "error", message: error.message });
    }
  };
};

export const setFilters = (filters) => {
  return (dispatch) => {
    dispatch(
      eoplActions.setFilters({
        filters: filters ?? [],
      })
    );
  };
};

export const setGblFilter = (filter) => {
  return (dispatch) => {
    dispatch(
      eoplActions.setGlbFilter({
        gblFilter: filter,
      })
    );
  };
};

export const getCyclePlanStatus = () => {
  return async (dispatch) => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: "Checking Status for Cycle Plan" }));
      const details = await getRequest(`cyclePlanMetaInfo/`);
      dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
      return details;
    } catch (error) {
      Toast({ status: "error", message: "Error in getting latest Cycle Plan Status" });
    }
  };
};

export const getImages = (type, partId) => {
  return async (dispatch) => {
    try {
      let result = [];
      dispatch(globalActions.setLoading({ loading: true, label: "Getting Images" }));
      if (type === 'childParts') {
        result = await getRequest(`childPartsImages/?eoplChildPartsId=${partId}`);
      }
      if (type === 'shoppingList') {
        result = await getRequest(`shoppingListImages/?eoplShoppingListId=${partId}`);
      }
      dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
      return result;
    } catch (error) {
      Toast({ status: "error", message: "Error in getting the image" });
    }
  };
};

export const deleteImage = (type, imageId) => {
  return async () => {
    try {
      let response;
      if (type === 'childParts') {
        response = await deleteRequest(`childPartsImages/${imageId}`);
      }
      if (type === 'shoppingList') {
        response = await deleteRequest(`shoppingListImages/${imageId}/`);
      }
      if (response?.status === 200)
        Toast({ status: "success", message: response });
      if (response?.status === 404)
        Toast({ status: "error", message: response?.detail });
    } catch (error) {
      Toast({ status: "error", message: "Error in deleting the image!!" });
    }
  };
};

export const uploadImage = (file, data) => {
  return async (dispatch) => {
    const sendRequest = () => {
      let url = '';
      const formData = new FormData();
      if (data?.type === 'childParts') {
        url = `/childPartsImages/`;
        formData.append("eoplChildPartsId", data?.partId);
      }
      if (data?.type === 'shoppingList') {
        url = `/shoppingListImages/`;
        formData.append("eoplShoppingListId", data?.partId);
      }
      formData.append("name", data?.name);
      formData.append("imageUrl", file);

      dispatch(globalActions.setLoading({ loading: true, label: "Uploading Image" }));
      return axios
        .post(url, formData, {
          responseType: "json",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response?.status === 201) {
            Toast({ status: "success", message: "Image Uploaded successfully" });
          }
        })
        .catch((e) => {
          Toast({ status: "error", message: "Image Upload failed " + e.message });
        })
        .finally(() => {
          dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};
