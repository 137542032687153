import PropTypes from "prop-types";

const SimpleCard = ({ children, className }) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className={`${className} flex flex-col lg:flex-row w-full items-start lg:items-center rounded bg-secondary shadow-md`}>{children}</div>
    </div>
  );
};

SimpleCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export default SimpleCard;
