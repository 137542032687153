import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

import style from './Styles'

function Textarea(props) {
  const { label, name, ...rest } = props
  return (
    <div className={style.formControl}>
      <label htmlFor={name} className={style.label}>{label}</label>
      <Field as='textarea' id={name} name={name} {...rest} className={style.input} />
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Textarea
