import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	architecturePlatformList: [],
	vehilcelineList: [],
	eoplImpact: [],
	eoplStatus: [],
	inScopeStatus: [],
	eoplPdlCheck: [],
	reviewDateAction: []
};

const dropdownSlice = createSlice({
	name: 'dropdown',
	initialState: {
		dropdownsData: initialState
	},
	reducers: {
		replaceDropdowns: (state, action) => {
			state.dropdownsData = action.payload;
		}
	}
});

export const dropdownActions = dropdownSlice.actions;

export default dropdownSlice;
