import { classNames } from "../../utils/utility";
import PropTypes from "prop-types";

const StatusPill = ({ value, options }) => {
  const status = value ? value.toString().toLowerCase() : "unknown";

  let optionClass;

  const idx = options.indexOf(value);
  switch (idx) {
    case 0:
      optionClass = "bg-primary   text-typography-2";
      break;
    case 1:
      optionClass = "bg-primary   text-typography-2";
      break;
    case 2:
      optionClass = "bg-primary/60   text-typography-2";
      break;
    case 3:
      optionClass = "bg-primary/60   text-typography-2";
      break;
    case 4:
      optionClass = "bg-primary/40   text-typography-2";
      break;
    case 5:
      optionClass = "bg-primary/40   text-typography-2";
      break;
    case 6:
      optionClass = "bg-primary/10   text-typography-2";
      break;
    case 7:
      optionClass = "bg-primary/10   text-typography-2";
      break;
    case 8:
      optionClass = "bg-primary/60 text-typography-2";
      break;
    case 9:
      optionClass = "bg-primary/80 text-typography-2";
      break;
    default:
      optionClass = "bg-primary/10  text-typography-2";
      break;
  }

  return <span className={classNames("px-2.5 py-1 leading-wide uppercase font-semibold text-xs rounded-full shadow-sm", optionClass)}>{status}</span>;
};

export default StatusPill;

StatusPill.propTypes = {
  value: PropTypes.any,
};
