import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

import style from './Styles'


function RadioButtons(props) {
  const { label, name, options, ...rest } = props
  return (
    <div className={style.formControl}>
      <label className={style.label}>{label}</label>
      <div className={style.input}>
        <Field name={name} >
          {({ field }) => {
            return options.map(option => {
              return (
                <div className="flex items-center" key={option.key}>
                  <input
                    className={style.checkbox}
                    type='radio'
                    id={option.value}
                    {...field}
                    {...rest}
                    value={option.value}
                    checked={field.value === option.value}
                  />
                  <label htmlFor={option.value} className={style.radioLabel}>{option.key}</label>
                </div>
              )
            })
          }}
        </Field>
        <ErrorMessage component={TextError} name={name} />
      </div>
    </div>
  )
}

export default RadioButtons
