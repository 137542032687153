// import { getRequest } from '../axios';
// import Login from '../pages/auth/Login';
import { globalActions } from "./globalSlice";
import { profileActions } from "./profileSlice";

export const getActiveProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: "Checking Session..." }));
      const details = await fetch(process.env.REACT_APP_SERVER_URL + "/api/profile/", {
        credentials: "include",
        method: "GET",
      });
      const json = await details.json();
      // const json = {
      //     "username": "kswapnil",
      //     "email": "kswapnil@jaguarlandrover.com",
      //     "first_name": "Kishlay",
      //     "last_name": "Swapnil",
      //     "role": "View",
      //     "is_active": true,
      //     "uid": "DiXTpE1vfL0fHw3OWQT6xrVMLFhnvpi8"
      // }
      if (json.username) {
        dispatch(globalActions.setLoading({ loading: false }));
        dispatch(profileActions.updateLoginStatus(true));
        if (json.role === "View") {
          json.view = true;
          dispatch(profileActions.replaceProfiles(json));
        } else {
          dispatch(profileActions.replaceProfiles(json));
        }
      } else {
        dispatch(globalActions.setLoading({ loading: false }));
        // window.location.replace(process.env.REACT_APP_SERVER_URL + '/saml2/login/');
      }
    } catch (error) {}
  };
};
