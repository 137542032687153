import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllProgramData, resetAllSpoData, resetUploaded } from "../../store/eoplActions";
import { Drawer, DrawerHeader, DrawerBody } from "../../packages/drawer/Drawer";

import Table, {
  SelectColumnFilter,
  StatusPillInScope,
  StatusPillVersion,
  DateRangeColumnFilter,
  dateBetweenFilterFn,
  exactMatchMultiSelectFilter,
} from "../../components/ui/Table";

import EOPLForm from "../eopl/form/Form";
import useToggle from "../../packages/_utils/useToggle";
import { toggleCommentsRefresh } from "../../store/eoplCommentsActions";
import { getFormattedDate } from "../../utils/utility";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../packages/modal/Modal";
import Button from "../../packages/button/Button";

const AllData = () => {
  const [toggle, isOpen] = useToggle();
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const changed = useSelector((state) => state.eopl.changed);

  const handleClick = useCallback(
    (e) => {
      setIndex(e);
      toggle();
      dispatch(toggleCommentsRefresh(true));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggle]
  );

  useEffect(() => {}, [changed]);
  const data = useSelector((state) => state.eopl.allData);
  const searchValue = useSelector((state) => state.eopl.searchValue);
  const gblFilter = useSelector((state) => state.eopl.gblFilter);
  const dispatch = useDispatch();
  const [index, setIndex] = useState();

  useEffect(() => {
    dispatch(resetUploaded());
    dispatch(getAllProgramData());
    return () => {
      dispatch(resetAllSpoData());
    };
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "Programme Reference",
        accessor: "eoplProgramRefId",
        Cell: ({ cell }) => {
          return (
            <div className="flex-none">
              <button
                className="font-bold text-md text-typography-1 hover:text-primary dark:hover:text-typography-2 text-center w-full pr-2 dark:group-hover:text-typography-2"
                value={cell.row.values.id}
                onClick={() => handleClick(cell.row.index)}
              >
                {cell.row.values.eoplProgramRefId}
              </button>
            </div>
          );
        },
        className: "w-16",
      },
      {
        Header: "Programme Title",
        accessor: "name",
        Filter: SelectColumnFilter,
        filter: exactMatchMultiSelectFilter,
        className: "w-24",
      },
      {
        Header: "Type",
        accessor: "type",
        Filter: SelectColumnFilter,
        filter: "includes",
        className: "w-12 text-center",
      },
      {
        Header: "J1",
        accessor: "job1",
        Cell: (props) => {
          let date = new Date(props.value);
          return getFormattedDate(date);
        },
        className: "w-12 text-center",
      },
      {
        Header: "EoPL In- Scope",
        accessor: "inScope",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: StatusPillInScope,
        className: "w-16 text-center",
      },
      {
        Header: "Brand",
        accessor: "vehicleBrand",
        Filter: SelectColumnFilter,
        filter: exactMatchMultiSelectFilter,
        className: "w-16",
      },
      {
        Header: "EoPL Status",
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: StatusPillInScope,
        // filter: exactMatchMultiSelectFilter,
        className: "w-16 text-center",
      },
      {
        Header: "MY",
        accessor: "manufacturingYear",
        Filter: SelectColumnFilter,
        filter: exactMatchMultiSelectFilter,
        className: "w-20 text-center",
      },
      {
        Header: "ARCH",
        accessor: "architecturePlatform",
        filter: exactMatchMultiSelectFilter,
        className: "w-16 text-center",
      },
      {
        Header: "VL",
        accessor: "vehicleLine",
        Filter: SelectColumnFilter,
        filter: exactMatchMultiSelectFilter,
        className: "w-16 text-center",
      },
      {
        Header: "Version",
        accessor: "version",
        className: "w-14 text-center",
        Cell: StatusPillVersion,
      },
      {
        Header: "EoPL Start Date",
        accessor: "eoplStartDate",
        Cell: (props) => {
          let date = new Date(props.value);
          const formattedDate = getFormattedDate(date);
          return formattedDate;
        },
        Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn,
        className: "w-20 text-center",
      },
      {
        Header: "Created Date",
        accessor: "auditDateTime",
        Cell: (props) => {
          let date = new Date(props.value);
          return getFormattedDate(date);
        },
        className: "w-20 text-center",
      },
    ],
    [handleClick]
  );

  const getByPartNumber = (e) => {
    dispatch(getAllProgramData(e));
  };

  const filters = useSelector((state) => state.eopl.filters);
  const filterValue = data.length === 0 || (!searchValue && !gblFilter) ? "" : gblFilter || searchValue.toString();

  const getDrawerDetails = (e) => {
    if (changed) setReviewModalOpen(e);
    else toggle();
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={() => toggle()} position="right" changed={changed} triggerIsOpen={() => getDrawerDetails(true)}>
        <DrawerHeader closeDrawer={() => getDrawerDetails(true)}>Programme Details</DrawerHeader>
        <DrawerBody>
          <div className={`w-full`}>
            <div className="grid grid-cols-1 gap-x-2">
              <div className={`w-full`}>
                <div className="grid grid-cols-1 gap-x-2">
                  {isOpen && data[index]?.eoplProgramRefId && <EOPLForm programData={data[index]} flag={"programList"} closeDrawer={toggle} />}
                </div>
              </div>
            </div>
          </div>
        </DrawerBody>
      </Drawer>

      <Table columns={columns} data={data} title="EoPL Programmes" filterValue={filterValue} getByPartNumber={getByPartNumber} filters={filters} />

      {changed && (
        <Modal isOpen={reviewModalOpen} toggle={toggle} animate={true}>
          <ModalHeader>Close Programme Details</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-col bd-highlight mb-3">
              <div className="p-2 bd-highlight">You have unsaved changes.</div>
              <div className="p-2 bd-highlight">Click 'Close' to disregard changes.</div>
              <div className="p-2 bd-highlight">Click 'Reivew' to return to the form.</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={() => {
                toggle();
                getDrawerDetails(false);
              }}
              color="danger"
              className="mr-1"
            >
              Close
            </Button>
            <Button type="button" onClick={() => getDrawerDetails(false)} color="primary">
              Review
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default AllData;
