import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import CommentForm from "./CommentForm";
import Comment from "./Comment";

import {
  updateEoplComment,
  deleteEoplComment,
  setComments,
  toggleCommentsRefresh,
  toggleAddCommentLoading,
  toggleReplyCommentLoading,
} from "../../store/eoplCommentsActions";
import { getFormattedDate, updateObject } from "../../utils/utility";
import Button from "../../packages/button/Button";
import { AnnotationIcon, ArrowRightIcon, ReplyIcon, UserCircleIcon, TrashIcon } from "@heroicons/react/solid";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../packages/modal/Modal";
import useToggle from "../../packages/_utils/useToggle";
import SpinnerIcon from "../../packages/button/SpinnerIcon";
import { postRequest } from "../../axios";
import Toast from "../ui/Toast";
import FormField from "../../pages/eopl/form/FormField";
import { getShoppingList } from "../../store/shoppingListActions";
import { getChildPartList } from "../../store/childPartListActions";

const Comments = ({ eoplRequestId, commentsData, commentType, comment, eoplProgramRefId, indx }) => {
  const myComments = useSelector((state) => state.eoplComments.allDataComments);
  // const profileData = useSelector((state) => state.profile.profilesData);
  const profileData = useState("View");
  const [allComments, setAllComments] = useState([]);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.profile.profilesData);
  // const currentUser = useState("EoPL User");
  const eoplComments = useSelector((state) => state.eoplComments.headerComments);
  const loaded = useSelector((state) => state.eoplComments.loaded);
  const loading = useSelector((state) => state.eoplComments.loading);
  const replyLoading = useSelector((state) => state.eoplComments.replyLoading);
  const [activeComment, setActiveComment] = useState(null);
  const [detailTotalCount, setDetailTotalCount] = useState(null);
  const [commentCount, setCommentCount] = useState(0);
  const [showReply, setShowReply] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [addCommentOpen, setAddCommentOpen] = useState(false);
  const [replyCommentOpen, setReplyCommentOpen] = useState(false);
  const [replyMessage, setReplyingToMessage] = useState(null);

  useEffect(() => {
    if (myComments !== null) {
      setAllComments(myComments);
      setCommentCount(myComments?.length !== 0 ? myComments?.length : 0);
    }
  }, [myComments]);

  useEffect(() => {
    if (!eoplComments || !loaded) return;
    setAddCommentOpen(false);
  }, [eoplComments, loaded]);

  const addComment = async (text) => {
    const angularBracketsRegex = /^[^<>|=]*$/;
    if (!angularBracketsRegex.test(text)) {
      Toast({ status: "warning", message: "Invalid Characters in Comments" });
      return;
    }
    dispatch(toggleAddCommentLoading(true));
    let newComment = {};
    if (commentType === "headerComment") {
      newComment = {
        eoplProgramId: eoplRequestId,
        body: text,
        eoplShoppingListId: null,
        eoplChildPartsId: null,
      };
    } else if (commentType === "shoppingList") {
      newComment = {
        eoplProgramId: eoplRequestId,
        body: text,
        eoplShoppingListId: comment.id,
        eoplChildPartsId: null,
      };
    } else if (commentType === "childPartList") {
      newComment = {
        eoplProgramId: eoplRequestId,
        body: text,
        eoplShoppingListId: null,
        eoplChildPartsId: comment.id,
      };
    }
    const createCommentReeoplnse = await postRequest("comments/", newComment);
    if (createCommentReeoplnse) {
      Toast({ status: "success", message: "Comment Added!" });
      dispatch(toggleAddCommentLoading(false));
      setAllComments([...allComments, createCommentReeoplnse]);
      setCommentCount(commentCount + 1);
      dispatch(setComments([...allComments, createCommentReeoplnse]));
      if (commentType === "shoppingList") {
        dispatch(toggleCommentsRefresh(true));
        dispatch(getShoppingList(eoplProgramRefId, indx));
      } else if (commentType === "childPartList") {
        dispatch(toggleCommentsRefresh(true));
        dispatch(getChildPartList(eoplProgramRefId, indx));
      } else {
        dispatch(toggleCommentsRefresh(false));
      }
    }
    setActiveComment(null);
  };

  const deleteComment = (eoplCommentId) => {
    dispatch(deleteEoplComment(eoplCommentId)).then(() => {
      if (commentType === "shoppingList") {
        dispatch(getShoppingList(eoplProgramRefId, indx));
      } else if (commentType === "childPartList") {
        dispatch(getChildPartList(eoplProgramRefId, indx));
      }
    });

    toggle();
    setAllComments(allComments.filter((x) => x.id !== eoplCommentId));
    setCommentCount(commentCount - 1);
    dispatch(setComments(allComments.filter((x) => x.id !== eoplCommentId)));
    dispatch(toggleCommentsRefresh(false));
    setActiveComment(null);
  };

  const replyComment = async (text, replyId) => {
    const angularBracketsRegex = /^[^<>|=]*$/;
    if (!angularBracketsRegex.test(text)) {
      Toast({ status: "warning", message: "Invalid Characters in Comments" });
      return;
    }
    dispatch(toggleReplyCommentLoading(true));
    const newReply = {
      body: text,
      CommentId: activeComment.id,
    };
    const createReplyReeoplnse = await postRequest("commentsReply/", newReply);
    if (createReplyReeoplnse) {
      dispatch(toggleReplyCommentLoading(false));
      var copyComments = JSON.parse(JSON.stringify(allComments));
      Toast({ status: "success", message: "Reply Added!" });
      for (let index = 0; index < copyComments.length; index++) {
        const element = copyComments[index];
        if (element.id === activeComment.id) {
          copyComments[index].replies.push(createReplyReeoplnse);
        }
      }
      setAllComments(copyComments);
      dispatch(setComments(copyComments));
      dispatch(toggleCommentsRefresh(false));
      setReplyCommentOpen(false);
      setActiveComment(null);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const replies = (data) => {
    return data.map((x) => {
      return (
        <div key={x.id} className="mb-2 mfax-w-fit">
          <div className="px-5">
            <div className="shadow-sm rounded p-2 border !border-secondary-3 bg-white">
              <div className="flex items-center pb-1">
                <UserCircleIcon className="w-8 h-8 text-secondary-2 mr-2" />
                <div className="font-bold mr-4">{x.auditUserId.name}</div>
                <div className="text-xs text-gray-500">{getFormattedDate(x.auditDateTime, true)}</div>
              </div>
              <div className="bg-seconddary-4 border-b rounded p-2 leading-relaxed">
                <p className="whitespace-pre-wrap">{x.body}</p>
              </div>
            </div>
            {/* <div style={{ display: "flex", alignItems: 'center' }}>
						<UserCircleIcon className="w-6 h-6 text-secondary-2 mr-1" />
						<b style={{ textTransform: 'uppercase' }}> */}
            {/* {dropdownsData.Users.find(u => u.id === x.AuditUserId).username} */}
            {/* {"Eopl User"}
						</b>
						<span style={{ fontSize: '12px', color: '#6B7280' }}>
							&nbsp;({formatDate(x.auditDateTime)})
						</span> - {x.body}
					</div> */}
          </div>
        </div>
      );
    });
  };

  const updateComment = (text, eoplCommentId) => {
    // get the comment
    const comment = eoplComments.find((c) => c.id === eoplCommentId);
    const updatedComment = updateObject(comment, { body: text, eopl: allComments });
    dispatch(updateEoplComment(updatedComment));
    setActiveComment(null);
  };

  const [toggle, isOpen] = useToggle();

  return (
    <>
      <div className="flex items-center px-4 py-2 bg-primary dark:bg-secondary-3 dark:text-typography-1 text-white h-10">
        <h3 className="font-semibold mr-4">Comments ({commentCount})</h3>
        <div
          className={`flex gap-x-2 text-xs font-bold items-center text-white flex-row-reversse cursor-pointer`}
          onClick={() => setShowComments(!showComments)}
        >
          <FormField
            label={showComments === false ? "Show Comments" : "Hide Comments"}
            name="showComments"
            type="checkbox"
            checked={showComments}
            onClick={() => setShowComments(!showComments)}
          />
        </div>
      </div>
      <div className={`${!showComments && "hidden"} px-4 py-2 border text-sm !border-secondary-3 shadow-sm bg-primary/30 dark:bg-secondary-3-5`}>
        {allComments?.map((x) => {
          return (
            <div key={x?.id} className="mb-2 mfax-w-fit">
              {/* <span style={{fontSize: '12px'}}>Date here</span> */}
              <div className="shadow-sm rounded p-2 border !border-secondary-3 bg-white">
                <div className="flex items-center pb-1">
                  <UserCircleIcon className="w-8 h-8 text-secondary-2 mr-2" />
                  <div className="font-bold mr-4">{x?.auditUserId?.name}</div>
                  <div className="text-xs text-gray-500">{getFormattedDate(x?.auditDateTime, true)}</div>
                  <TrashIcon
                    className="h-5 ml-2"
                    style={{ color: "#c12127", cursor: "pointer" }}
                    onClick={() => {
                      if (profileData.role === "View") return;
                      setActiveComment({ id: x.id, type: "deleting" });
                      toggle();
                      // return deleteComment(x.id);
                    }}
                  />
                </div>
                <div className="bg-seconddary-4 border-b rounded p-2 leading-relaxed">
                  <span className="whitespace-pre-wrap">{x?.body}</span>
                </div>
                {/* <UserCircleIcon className="w-6 h-6 text-secondary-2 mr-1" />
								<span> */}
                {/* <b style={{textTransform: 'uppercase'}}>{dropdownsData.Users.find(u => u.id === x.AuditUserId).username}</b> */}
                {/* <b style={{ textTransform: 'uppercase' }}>{"Eopl User"}</b>
									<span style={{ fontSize: '12px', color: '#6B7280' }}>
										&nbsp;({formatDate(x.auditDateTime)})&nbsp;{x.InspectionDetailId && <>[{x.InspectionDetailId}]</>}
									</span> - <span>{x.body}</span>
								</span> Parent comment */}
                <div
                  className="mr-4 cursor-pointer hover:font-bold w-8"
                  onClick={() => {
                    if (profileData.role === "View") return;
                    setActiveComment({ id: x?.id });
                    setReplyingToMessage({ id: x?.id, message: x?.body });
                    setReplyCommentOpen(true);
                    setAddCommentOpen(false);
                  }}
                >
                  Reply
                </div>
                {/* <ReplyIcon className="mr-4 cursor-pointer hover:font-bold w-8" style={{ cursor: 'pointer' }}
									onClick={() => {
										if (profileData.role === 'View') return;
										setActiveComment({ id: x.id });
										setReplyingToMessage({ id: x.id, message: x.body });
										setReplyCommentOpen(true);
										setAddCommentOpen(false);
									}} /> */}
              </div>
              <span>{replies(x.replies)}</span> {/* Child comment */} {replyLoading && activeComment.id === x.id ? <SpinnerIcon type="danger" /> : <p></p>}
              {replyCommentOpen && activeComment.id === x.id && (
                <div className="flex-1 bg-primary/30 px-2 mt-2 pt-2 rounded">
                  <i>Replying to - {replyMessage?.message}</i>
                  <CommentForm
                    submitLabel="Reply"
                    handleSubmit={replyComment}
                    hasCancelButton
                    handleCancel={() => {
                      setReplyCommentOpen(false);
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}

        {currentUser.role !== "View" ? (
          <Button
            className="mt-4 text-sm flex items-center"
            type="button"
            onClick={() => {
              setActiveComment(null);
              setAddCommentOpen(true);
              setReplyCommentOpen(false);
            }}
            disabled={profileData.role === "View"}
          >
            {" "}
            {loading ? <SpinnerIcon type="danger" /> : <AnnotationIcon className="h-4 pr-2" />}
            Add Comment
          </Button>
        ) : null}
        {addCommentOpen && (
          <div className="flex-1 bg-primary/30 px-2 mt-2 pt-2 rounded">
            <CommentForm
              submitLabel="Add"
              handleSubmit={addComment}
              hasCancelButton
              handleCancel={() => {
                setAddCommentOpen(false);
              }}
            />
          </div>
        )}
      </div>

      <Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
        <ModalHeader>Delete Comment</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight">
              <p>Are you sure you want to remove this comment?</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger" className="ignore-click-outside">
            Cancel
          </Button>
          <Button onClick={() => deleteComment(activeComment.id)} color="primary" className="mr-1 ignore-click-outside">
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Comments;

Comments.propTypes = {
  handleSubmit: PropTypes.func,
  eoplRequestId: PropTypes.number,
  commentsData: PropTypes.array,
  commentType: PropTypes.string,
  info: PropTypes.string,
};
