import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./authSlice";
import contactsSlice from "./contactsSlice";
import dashboardSlice from "./dashboardSlice";
import genericEmail from "./genericEmailSlice";
import globalSlice from "./globalSlice";
import mailingListSlice from "./mailingListSlice";
import mailingGroupSlice from "./mailingGroupSlice";
import marketsSlice from "./marketsSlice";
import eoplCommentsSlice from "./eoplCommentsSlice";
import eoplSlice from "./eoplSlice";
import dropdownSlice from "./dropdownSlice";
import shoppingListSlice from "./shoppingListSlice";
import childPartListSlice from "./childPartListSlice";
import profileSlice from "./profileSlice";
import eoplIssueSlice from "./eoplIssueSlice"

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    contacts: contactsSlice.reducer,
    dashboard: dashboardSlice.reducer,
    global: globalSlice.reducer,
    genericEmail: genericEmail.reducer,
    mailingList: mailingListSlice.reducer,
    mailingGroup: mailingGroupSlice.reducer,
    markets: marketsSlice.reducer,
    eopl: eoplSlice.reducer,
    dropdown: dropdownSlice.reducer,
    shopping: shoppingListSlice.reducer,
    childPart: childPartListSlice.reducer,
    eoplComments: eoplCommentsSlice.reducer,
    profile: profileSlice.reducer,
    eoplIssue: eoplIssueSlice.reducer
  }
});

export default store;
