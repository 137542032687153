import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useLocalStorage from "../../packages/_utils/useLocalStorage";
// import useStateCallback from "../../packages/_utils/useStateCallback";
import Navbar from "./Navbar";
import SideNav from "./SideNav";
import { useDispatch, useSelector } from "react-redux";
import { globalActions } from "../../store/globalSlice";

const Layout = (props) => {
  const [localViewState, setLocalViewState] = useLocalStorage("view-state", "overlay");
  const [localSideNavOpen, setLocalSideNavOpen] = useLocalStorage("side-bar-open", false);
  const [localSideNavPeek, setLocalSideNavPeek] = useLocalStorage("side-bar-peek", true);

  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.global.viewState);

  const sideNavPeek = useSelector((state) => state.global.sideNavPeek);

  const [peek, setPeek] = useState(sideNavPeek);

  useEffect(() => {
    if (sideNavPeek === null) {
      setLocalSideNavPeek(localSideNavPeek);
      dispatch(globalActions.setSideNavPeek(localSideNavPeek));
    }
    setPeek(sideNavPeek ? "sm:w-20" : "sm:w-0");
  }, [dispatch, sideNavPeek, localSideNavPeek, setLocalSideNavPeek]);

  const [sideNavOpen, setSideNavOpen] = useState(localSideNavOpen);

  useEffect(() => {
    if (!viewState) {
      setLocalViewState(localViewState);
      dispatch(globalActions.setViewState(localViewState));
    }
  }, [dispatch, localViewState, setLocalViewState, viewState]);

  const handleSetViewState = () => {
    const updatedViewState = viewState === "push" ? "overlay" : "push";
    setLocalViewState(updatedViewState);
    dispatch(globalActions.setViewState(updatedViewState));
  };

  useEffect(() => {
    setLocalSideNavOpen(sideNavOpen);
  }, [sideNavOpen, localSideNavOpen, setLocalSideNavOpen]);

  return (
    <div className="bg-tertiary min-h-screen">
      <Navbar setSideNavOpen={() => setSideNavOpen(!sideNavOpen)} sideNavOpen={sideNavOpen} />
      <SideNav
        setSideNavOpen={() => setSideNavOpen(!sideNavOpen)}
        sideNavOpen={sideNavOpen}
        viewState={viewState}
        setViewState={handleSetViewState}
        sideNavPeek={sideNavPeek}
      />
      <div className="flex flex-1">
        <div
          className={`w-0 h-screen ${viewState === "push" ? (sideNavOpen ? "sm:w-72" : peek) : peek} flex-none transition-width ease-in-out duration-500`}
        ></div>
        <div className="flex-col flex-1 p-10 h-full mt-20">{props.children}</div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.array,
};

export default Layout;
