import React, { useState } from "react";
import { useEffect } from "react";

function TableComponent(props) {
  const { data } = props;
  let [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (!data || data.length === 0) return;
    if (data) setTableData(data);
  }, [data]);

  const getCurrentYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}`;
  };

  const getCurrentYearAndQuarter = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1; // getMonth() returns 0-11
    const quarter = Math.ceil(month / 3);

    return `${year} Q${quarter}`;
  };

  return (
    <div className="relative overflow-x-auto ">
      <table className="w-full border-collapse font-bold">
        {tableData.length > 0 ? (
          <thead className="bg-primary text-typography-2 text-sm">
            <tr>
              <th className="px-4 py-1 text-left sticky left-0 bg-white  z-10 whitespace-nowrap"></th>
              {tableData[0].map((header, index) => {
                if (index % 4 !== 0) return false;
                return (
                  <th
                    key={index}
                    colSpan={4}
                    className={`border-x-2 px-3 text-center whitespace-nowrap ${getCurrentYear() === header && "bg-danger text-white "}`}
                  >
                    {header}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th className=" px-4 py-1 text-left sticky left-0 bg-white  z-10 whitespace-nowrap"></th>
              {tableData[1].map((header, index) => {
                const year = tableData[0][index];

                return (
                  <th
                    key={index}
                    className={`border-x-2  border-t-2 px-3 text-center whitespace-nowrap ${
                      getCurrentYearAndQuarter() === `${year} ${header}` && "bg-danger text-white"
                    }`}
                  >
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
        ) : (
          <thead>
            <tr>
              <th>No Data Available</th>
            </tr>
          </thead>
        )}

        <tbody className="text-sm  border-t-2 border-dark">
          {tableData.slice(2).map((row, rowIndex) => {
            return (
              <tr key={rowIndex} className={rowIndex === 2 ? "bg-dark text-typography-1" : ""}>
                <td
                  className={`border-t-2  px-4 py-1 text-left sticky left-0 ${
                    rowIndex === 2 ? "bg-dark text-typography-1" : "bg-light text-typography-1"
                  } z-10 whitespace-nowrap
                
                `}
                >
                  {row[0]}
                </td>
                {row.slice(1).map((cell, cellIndex) => {
                  const year = tableData[0][cellIndex];
                  const quarter = tableData[1][cellIndex];
                  const isCurrentYearAndQuarter = getCurrentYearAndQuarter() === `${year} ${quarter}`;
                  return (
                    <td
                      key={cellIndex}
                      className={`border-t-2 text-typography-1 px-4 py-1 text-center whitespace-nowrap
                      ${
                        isCurrentYearAndQuarter ? "!bg-danger !text-white border-x-2 border-white" : rowIndex === 2 ? "bg-dark text-typography-1" : "bg-light"
                      }`}
                    >
                      {cell}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;
