import { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FieldArray } from "formik";
// import FormikControl from "../../../components/ui/Formik/FormikControl";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../../packages/modal/Modal";
import useToggle from "../../../packages/_utils/useToggle";
import Button from "../../../packages/button/Button";
// import * as Yup from "yup";
import { createChildPartList, editChildPartList, getChildPartList, deleteChildPartItem } from "../../../store/childPartListActions";
import { useSelector, useDispatch } from "react-redux";
import {
  PencilIcon, TrashIcon, SaveIcon, ChatAlt2Icon, PhotographIcon
} from "@heroicons/react/solid";
import { constructChildPartListObject } from "../../../utils/utility";
import Comments from "../../../components/comments/Comments";
import { setComments } from "../../../store/eoplCommentsActions";
import { Role } from "../../../data/roles";
import { childPartListActions } from "../../../store/childPartListSlice";
import ImageUploadModal from "../../../components/imageUploadModal";
import Tippy from "@tippyjs/react";
import { setChanged } from "../../../store/eoplActions";

const ChildPartList = (props) => {
  const { data } = props;

  const [childPartData, setChildPartData] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [enableComment, setEnableComment] = useState(true);
  const [toggle, isOpenModal] = useToggle();
  const [toggle1, isOpenPrompt] = useToggle();
  const [item, setItem] = useState();
  const [commentData, setComment] = useState();
  const [itemId, setItemId] = useState();
  const dispatch = useDispatch();
  const childPart = useSelector((state) => state.childPart.childPartList);
  const refreshComments = useSelector((state) => state.eoplComments.refreshCommentsData);
  const currentUser = useSelector((state) => state.profile.profilesData);
  const eoplProgramData = useSelector((state) => state.eopl.data);
  const [indexData, setIndexData] = useState();
  const [imageData, setImageData] = useState(null);
  const [visibleImageUpload, setVisibleImageUpload] = useState(false);

  useEffect(() => {
    setChildPartData(childPart);
  }, [childPart, isOpen]);

  const handleRemove = (values) => {
    // setchildPartData(values);
    toggle();
    setItemId(values);
  };
  const removeItem = () => {
    dispatch(deleteChildPartItem(itemId));
    toggle();
  };
  function edit(index) {
    setIsReadOnly(!isReadOnly);
    dispatch(
      childPartListActions.replaceChildPartListData({
        childPartList: childPart?.map((currElement, indx) => {
          if (index === indx) {
            return { ...currElement, isSelected: !isReadOnly };
          } else {
            return { ...currElement, isSelected: true };
          }
        }),
      })
    );
    setIsOpen((isOpen) => !isOpen);
  }

  function comment(item, index) {
    setIndexData(item.currentEpn);
    setComment(item);
    dispatch(setComments(item.comments));
    setEnableComment((enableComment) => !enableComment);
    dispatch(
      childPartListActions.replaceChildPartListData({
        childPartList: childPart?.map((currElement, indx) => {
          if (index === indx) {
            return { ...currElement, isSelected: !enableComment };
          } else {
            return { ...currElement, isSelected: true }; //equivalent to list[index]
          }
        }),
      })
    );
  }
  function save(item, index) {
    toggle1();
    setIsOpen((isOpen) => !isOpen);
    setIsReadOnly(!isReadOnly);
    setItem(item);
    dispatch(setChanged(false));
  }

  const saveItem = () => {
    const customChildPartListObj = constructChildPartListObject(item);
    dispatch(editChildPartList(customChildPartListObj, item.id)).then(() => dispatch(getChildPartList(item.eoplProgramId)));
    dispatch(setChanged(false));
    toggle1();
  };

  const handleSubmit = (values) => {
    let childPartData = values.childPartList.map((v) => ({ ...v, eoplProgramId: data.eoplProgramRefId }));
    dispatch(createChildPartList(childPartData));
  };

  const errorField = () => {
    return (
      <Tippy arrow={true} content={<span className="bg-secondary text-sm rounded px-2 py-1 shadow text-danger">Required Field</span>}>
        <span className="text-white absolute pointer-evdents-none top-1 right-1 font-bold bg-danger rounded-full w-5 h-5 text-center">!</span>
      </Tippy>
    );
  };

  const changed = useSelector((state) => state.eopl.changed);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty && !changed) {
      dispatch(setChanged(true));
    }
  }, [isDirty, dispatch, changed]);

  const toggleImgUploadModal = (partData = null) => {
    if(partData !== null){
    setVisibleImageUpload(true);
    setImageData({...partData,type : 'childParts'});
    }
    else{
      setVisibleImageUpload(false);
      setImageData(null);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{
          childPartList: childPartData,
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values, dirty }) => {
          if (dirty && !changed) {
            setTimeout(() => setIsDirty(true), 0); // Use setTimeout to defer the state update
          }

          return (
            <>
              <Form>
                <div className="!col-spfan-full whitespace-nowrap overflow-y-scroll mt-2 border-box">
                  <FieldArray name="childPartData">
                    {({}) => (
                      <div>
                        <div style={{ display: "block", maxHeight: "260px" }}>
                          <table className="w-full whitespace-nowrap rounfded-tl-lg roundefd-tr-lg">
                            <thead
                              className="text-white bg-primary dark:bg-secondary-3-5 dark:text-typography-1 font-large"
                              style={{ position: "sticky", top: 0 }}
                            >
                              <tr className="hidden md:table-row">
                                <th className="py-2.5 pl-2 text-left text-xs font-medium tracking-wider">Actions</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">Version</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">Current EPN</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">Current APN</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">New EPN</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">Part Description</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">iCreate</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">Vendor</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">Change Description</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">Vehicle Applicability</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">Backwards Compatible</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">EPN1</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">PF1</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">APN1</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">EPN2</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">PF2</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">APN2</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">EPN3</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">PF3</th>
                                <th className="py-2.5 pl-2 text-left text-xs tracking-wider">APN3</th>
                              </tr>
                            </thead>
                            <tbody className="flex-1 md:flex-none bg-secondary-4 text-secondary-1 text-xs">
                              {values.childPartList.length ? (
                                values.childPartList.map((user, index) => (
                                  <tr
                                  key={index}
                                  className={`hover:bg-primary/40 odd:bg-light bg-dark text-typography-1 dark:hover:text-typography-2 group
                                    ${!values.childPartList[index].isSelected
                                      ? `md:h-8 h-12 odd:!bg-primary/60 !bg-primary/60 dark:bg-secondary-1 dark:text-white text-typography-1`
                                      : `md:h-8 h-12 dark:bg-secondary-2 dark:text-white text-typography-1`
                                    }
                                  `}
                                  >
                                    <td className="w-40 flex ">
                                      {currentUser.role !== Role.view && (
                                        <div className="flex w-full items-center justify-around">
                                          {!values.childPartList[index].isSelected && (
                                            <SaveIcon
                                              className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50"
                                              onClick={() => {
                                                return save(user, index);
                                              }}
                                            />
                                          )}
                                          {values.childPartList[index].isSelected && (
                                            <TrashIcon className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50" onClick={() => handleRemove(user)} />
                                          )}
                                          {<div className="reladtive">
                                            <ChatAlt2Icon className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50" 
                                            onClick={() => comment(user, index)} 
                                            />
                                            {values.childPartList[index].comments.length > 0 && (
                                              <span className="absoludte text-white rounded-full w-4 h-4 text-center text-xs flex justify-center items-center p-1 bg-danger ml-5 -mt-7">
                                                {values.childPartList[index].comments.length}
                                              </span>
                                            )}
                                          </div>}
                                          {currentUser.role !== Role.view && (
                                            <PencilIcon
                                              className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50"
                                              onClick={() => {
                                                return edit(index);
                                              }}
                                            />
                                          )}
                                          {currentUser.role !== Role.view &&
                                            <PhotographIcon className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50"
                                              onClick={() => {
                                                toggleImgUploadModal(user)
                                              }} />}
                                        </div>
                                      )}
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name="version"
                                        value={user?.version}
                                        placeholder="Version"
                                        // disabled={values.shoppingList[index].isReadOnly}
                                        disabled={true}
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.currentEpn`}
                                        placeholder="Current EPN"
                                        title={user?.currentEpn}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        // disabled={values.childPartList[index].isReadOnly}
                                        disabled={true}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.currentEpn`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.currentApn`}
                                        placeholder="Current APN"
                                        title={user?.currentApn}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        // disabled={values.childPartList[index].isReadOnly}
                                        disabled={true}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.currentApn`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.newEpn`}
                                        placeholder="New EPN"
                                        title={user?.newEpn}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        // disabled={values.childPartList[index].isReadOnly}
                                        disabled={true}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.newEpn`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`childPartList.${index}.partDescription`}
                                        placeholder="Part Description"
                                        title={user?.partDescription}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.partDescription`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.iCreate`}
                                        placeholder="iCreate"
                                        title={user?.iCreate}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.iCreate`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.vendor`}
                                        placeholder="Vendor"
                                        title={user?.vendor}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.vendor`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.changeDescription`}
                                        placeholder="Change Description"
                                        title={user?.changeDescription}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.changeDescription`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.vehicleApplicability`}
                                        placeholder="Vehicle Applicability"
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.vehicleApplicability`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.backwardsCompatible`}
                                        placeholder="Backwards Compatible"
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.backwardsCompatible`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn1`}
                                        placeholder="EPN1"
                                        title={user?.epn1}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.epn1`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf1`}
                                        placeholder="PF1"
                                        title={user?.pf1}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.pf1`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn1`}
                                        placeholder="APN1"
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.apn1`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn2`}
                                        placeholder="EPN2"
                                        title={user?.epn2}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.epn2`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf2`}
                                        placeholder="PF2"
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.pf2`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn2`}
                                        placeholder="APN2"
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.apn2`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.epn3`}
                                        placeholder="EPN3"
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.epn3`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.pf3`}
                                        placeholder="PF3"
                                        disabled={!enableComment ? true : values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.pf3`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`childPartList.${index}.apn3`}
                                        placeholder="APN3"
                                        disabled={values.childPartList[index].isSelected}
                                      // disabled={isReadOnly}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.apn3`} render={() => errorField()} />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td className="p-2 font-bold text-lg" colSpan={2}>
                                    No Records Found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </div>
                {data && !enableComment && (
                  <Comments
                    commentType="childPartList"
                    commentsData={commentData.comments}
                    comment={commentData}
                    eoplRequestId={eoplProgramData.id}
                    eoplProgramRefId={data}
                    indx={indexData}
                  />
                )}
              </Form>
            </>
          );
        }}
      </Formik>
      {data && isOpenModal && (
        <Modal isOpen={isOpenModal} toggle={toggle} animate={true} closeOnClickOutside={true}>
          <ModalHeader>Delete Item</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">Remove Item From ChildPart List</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={toggle} color="danger">
              Cancel
            </Button>
            <Button type="button" onClick={removeItem} color="primary" className="mr-1">
              Remove
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {data && isOpenPrompt && (
        <Modal isOpen={isOpenPrompt} toggle={toggle1} animate={true} closeOnClickOutside={true}>
          <ModalHeader>Version Change Prompt</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">Do you want a version change for this Child Part Item?</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={toggle1} color="danger">
              No
            </Button>
            <Button type="button" onClick={saveItem} color="success" className="mr-1">
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {visibleImageUpload === true && imageData !== null && (
        <ImageUploadModal data={imageData} closeModal={() => toggleImgUploadModal()} />
      )}
    </div>
  );
};

export default ChildPartList;
