import SearchInput from './SearchInput';
import { SearchIcon } from '@heroicons/react/solid';
import useLocalStorage from '../../packages/_utils/useLocalStorage';
import PageTitle from './PageTitle';

const SearchHeader = (props) => {
  const { header, label, onClick, onChange, value, showSearch, setShowSearch, flag, isSearch } = props;
  const [localShowSearch] = useLocalStorage("show-search");

  const toggleSearch = () => {
    setShowSearch(!showSearch)
  }
  return (
    <>
      <div className={`${showSearch || !localShowSearch ? 'mt-2 opacity-100' : '-mt-[106px] opacity-0'} mb-8 transition-all duration-500 delay-100 ease-out flex flex-col xl:w-2/6 lg:w-1/2 md:w-1/2 w-full`}>
        <SearchInput
          label={label}
          icon={<SearchIcon />}
          isSearch={isSearch}
          className="w-64 shadow-sm"
          iconPosition='right'
          onClick={onClick}
          onChange={onChange}
          value={value}
          placeholder={`Enter ${label}`} />
      </div>

      {/* {flag && <PageTitle header={header} label="Show Search" showSearch={showSearch} onClick={toggleSearch} />} */}
    </>
  )
}

export default SearchHeader