import { getRequest, postRequest } from "../axios";
import { eoplIssueActions } from "./eoplIssueSlice";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";

export const resetEoplIssue = () => {
  return dispatch => {
    dispatch(eoplIssueActions.resetEoplIssue());
  };
};

export const setEoplNumberAction = (eoplNumber) => {
  return dispatch => {
    dispatch(eoplIssueActions.setEoplNumber(eoplNumber));
  };
};

export const replaceEoplIssue = (data) => {
  return dispatch => {
    dispatch(eoplIssueActions.replaceEoplIssue(data));
  };
};

export const resetEopl = () => {
  return (dispatch) => {
    dispatch(eoplIssueActions.resetEopl());
  };
};

export const resetEmailData = () => {
  return (dispatch) => {
    dispatch(eoplIssueActions.resetEoplIssue());
  };
};

export const getEoplEmailData = (eoplId) => {
  return async (dispatch) => {
	  try {
    dispatch(globalActions.setLoading({ loading: true, label: 'Loading Email Details...' }));
		const details = await getRequest('shoppingListEmail/'+eoplId+'/');
		dispatch(eoplIssueActions.replaceEoplIssue(details.template));
		dispatch(eoplIssueActions.replaceEoplShoppingListData({ shoppingListData: details.shoppingListData || [] }));
    dispatch(globalActions.setLoading({ loading: false }));
	  } catch (error) {
		Toast({ status: "error", message: 'EoPL Not Found' });
    dispatch(globalActions.setLoading({ loading: false }));
	  }
	};
};



export const getEoplIssueEmailData = (param, byId) => {
  let url = 'eoplissue/';

  if (byId) url += 'id/';
  return async dispatch => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: 'Fetching SPO email content' }));
      const eoplIssueEmailData = await getRequest(url + param);
      dispatch(eoplIssueActions.replaceEoplIssue(eoplIssueEmailData));
    } catch (error) {
      console.error("getEoplIssueEmailData: " + error.message);
      if (error.message.substring(0, 14) === "Request failed" || error.message.substring(0, 2) === "40") {
        dispatch(eoplIssueActions.setLoading(false));
      }
      if (error.message.substring(0, 14) !== "Request failed" && error.message.substring(0, 2) !== "40")
        Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false, }));
    }
  };
};

export const issueEoplEmail = (eoplIssue,eoplProgramRefId) => {
  return async dispatch => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: 'Sending email...' }));
      const response = await postRequest(`shoppingListEmail/${eoplProgramRefId}/`, eoplIssue);
      Toast({ status: "success", message: 'Email sent successfully!' });
      dispatch(eoplIssueActions.resetEoplIssue(response.template));
    } catch (error) {
      console.error("issueEoplEmail: " + error.message);
      if (error.message.substring(0, 14) === "Request failed" || error.message.substring(0, 2) === "40") {
        dispatch(eoplIssueActions.setLoading(false));
      }
      if (error.message.substring(0, 14) !== "Request failed" && error.message.substring(0, 2) !== "40")
        Toast({ status: "error", message: error.message });
    } finally {
      dispatch(globalActions.setLoading({ loading: false, }));
    }
  };
};