import { createSlice, current } from "@reduxjs/toolkit";

import _ from "underscore";

export const initialValues = {
  id: null,
  eoplProgramRefId: null,
  name: null,
  type: "",
  job1: null,
  tfle: null,
  manufacturingYear: null,
  architecturePlatform: null,
  vehicleLine: null,
  auditUserId: null,
  auditDateTime: null,
  engineersContact: null,
  impact: null,
  pdlCheck: null,
  status: null,
  inScope: null,
  eoplStart: null,
  eoplAftercare: null,
  jobLastDays: null,
  version: null,
  eoplNextQuarterReview: [],
  shoppingListPartsCount: null,
  shoppingListIssueDate: null,
  sp4: null,
  vehicleBrands: null,
};

const eoplSlice = createSlice({
  name: "eopl",
  initialState: {
    allData: [],
    data: initialValues,
    changed: false,
    uploaded: false,
    uploading: false,
    loading: true,
    searchValue: null,
    programById: null,
    filters: [],
    gblFilter: null,
  },
  reducers: {
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setUploading(state, action) {
      state.uploading = action.payload ?? false;
    },
    resetUploaded(state) {
      state.uploaded = false;
    },
    setChanged(state, action) {
      state.changed = action.payload ?? false;
    },
    setError(state) {
      state.error = true;
    },
    resetEopl(state) {
      state.data = initialValues;
      // state.changed = false;
      state.loading = false;
      // state.uploaded = false;
      // state.uploading = false;
    },
    replaceEoplData(state, action) {
      // console.log("replaceEoplData", action.payload);
      state.data = action.payload?.data ?? initialValues;
      if (action.payload?.uploaded) state.uploaded = action.payload?.uploaded;
      if (!action.payload) state.uploading = false;
      // state.changed = true;
      state.error = false;
    },
    updateField(state, action) {
      // console.log("updateField", action.payload);
      const currentData = current(state.data);
      const newData = {
        ...currentData,
        [action.payload.field]: action.payload.value,
      };
      state.data = newData;
    },
    updateEoplData(state, action) {
      state.data = action.payload;
      // state.changed = true;
      state.error = false;
    },
    // all data
    replaceEoplAllData(state, action) {
      const data = action.payload?.allData ?? [];
      state.allData = _.sortBy(data, "auditDateTime").reverse();
      // state.changed = true;
      state.error = false;
    },
    removeEoplAllData(state, action) {
      const id = action.payload;
      var data = state.allData.filter((d) => d.id !== id);
      state.allData = _.sortBy(data, "auditDateTime").reverse();
      // state.changed = true;
      state.error = false;
    },
    updateEoplAllData(state, action) {
      const data = action.payload.allData;
      const dataWithVehicleBrandField = { ...data, vehicleBrand: data.vehicleBrands };
      const updatedData = [...state.allData.filter((d) => d.id !== data.id), dataWithVehicleBrandField];
      state.allData = _.sortBy(updatedData, "auditDateTime").reverse();
      // state.changed = true;
      state.error = false;
      state.uploaded = action.payload?.uploaded ?? false;
    },
    updateProgramById: (state, action) => {
      state.programById = action.payload;
    },
    setFilters(state, action) {
      state.filters = action.payload?.filters ?? [];
    },
    setGlbFilter(state, action) {
      state.gblFilter = action.payload?.gblFilter;
    },
  },
});

export const eoplActions = eoplSlice.actions;

export default eoplSlice;
