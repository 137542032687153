const externalLinks = [
  {
    label: "JLR Parts Catalogue",
    icon: "Globe",
    to: "https://www.jlrepc.com/jlr-epc/en-GB",
  },
  {
    label: "InfoStore REDS",
    icon: "Globe",
    to: "https://infostore.jlr-apps.com/reds-search",
  },
  {
    label: "RADs Programme Direction Letters",
    icon: "Globe",
    to: "https://rads.jlrint.com/PDL/",
  },
  {
    label: "RACS Release & Catalogue System",
    icon: "Globe",
    to: "https://racs.jlrint.com/home",
  },
  {
    label: "iCREATE",
    icon: "Globe",
    to: "https://icreate.jlr-ddc.com/",
  },
  {
    label: "MPNR Master Part Number Registry",
    icon: "Globe",
    to: "https://web.mpnr-pr.jlrext.com/",
  },
  {
    label: "JLR TOPIx",
    icon: "Globe",
    to: "https://topix.landrover.jlrext.com/",
  },
  {
    label: "JLR Way EoPL Process",
    icon: "Globe",
    to: "https://jlrglobal.sharepoint.com/sites/jlrway/en-gb/wi1/Pages/jbecket1_amps_-__body_in_white_end_of_production_life_outsourcing__190118.aspx",
  },
  {
    label: "WERS Web",
    icon: "Globe",
    to: "https://www.wers.jlrint.com/",
  },
  {
    label: "SCM Inventory 360°",
    icon: "Globe",
    to: "https://eu-west-1a.online.tableau.com/#/site/jlrradar/views/RWS/SCMInventory360?%3Aiid=1",
  },
  {
    label: "EPC Download",
    icon: "ArrowDown",
    action: "downloadEpc",
  },
];

module.exports = externalLinks;
