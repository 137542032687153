import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useSelector } from "react-redux";

const PrivateRoute = ({ roles }) => {
    let location = useLocation();
	const profileData = useSelector((state) => state.profile.profilesData);

    if (!roles) {
        // console.log('network error', profileData)
        // not logged in so redirect to login page with the return url
        return <Navigate to='/networkError' state={{ from: '/' }} />
    }

    if (profileData.username === null) {
        // console.log('not signed in')
        // not logged in so redirect to login page with the return url
        return <Navigate to='' state={{ from: location }} />
    }

    // check if route is restricted by role
    if (roles && roles.indexOf(profileData.role) === -1) {
        // console.log('unauthorised')
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/unauthorised', state: { from: location } }} />
    }

    // authorised so return component
    return <Outlet />;
}

export default PrivateRoute;