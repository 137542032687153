import useLocalStorage from "../../packages/_utils/useLocalStorage";
import { FormField } from "../../packages/form/FormField";
import { tableStyles } from "./Settings";
import { useContext } from "react";

import { ThemeContext } from "../../components/layout/ThemeProvider";
import { useDispatch, useSelector } from "react-redux";
import { globalActions } from "../../store/globalSlice";
import Button from "../../packages/button/Button";

const configItems = [
  {
    id: 0,
    name: "Auto Hide Show Search",
    state: "localShowSearch",
    method: "showSearch",
  },
  {
    id: 1,
    name: "Side Navigation Overlay",
    state: 'viewState === "overlay"',
    method: "toggleViewState",
  },
  {
    id: 2,
    name: "Side Navigation Peek",
    state: "sideNavPeek",
    method: "toggleSideNavPeek",
  },
  {
    id: 3,
    name: "Selected Theme",
    method: "toggleTheme",
  },
];

const Config = () => {
  const [localShowSearch, setLocalShowSearch] = useLocalStorage("show-search");

  const showSearch = (e) => setLocalShowSearch(!localShowSearch); // eslint-disable-line no-unused-vars

  //#region Theme
  const { theme, setTheme } = useContext(ThemeContext);
  // eslint-disable-next-line no-unused-vars
  const toggleTheme = (e) => {
    switch (theme) {
      case "theme1":
        setTheme("theme2");
        break;
      case "theme2":
        setTheme("theme3");
        break;
      case "theme3":
        setTheme("theme4");
        break;
      default:
        setTheme("theme1");
        break;
    }
  };

  const getThemeName = () => {
    let themeName = "";
    switch (theme) {
      case "theme1":
        themeName = "cool";
        break;
      case "theme2":
        themeName = "warm";
        break;
      case "theme3":
        themeName = "light";
        break;
      default:
        themeName = "dark";
        break;
    }
    return themeName.toUpperCase();
  };
  //#endregion

  //#region View State
  const dispatch = useDispatch();
  const viewState = useSelector((state) => state.global.viewState);
  const [, setLocalViewState] = useLocalStorage("view-state", "overlay"); // eslint-disable-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  const toggleViewState = (e) => {
    const updatedViewState = viewState === "push" ? "overlay" : "push";
    dispatch(globalActions.setViewState(updatedViewState));
    setLocalViewState(updatedViewState);
  };
  //#endregion

  //#region Side Nav Peek
  const sideNavPeek = useSelector((state) => state.global.sideNavPeek);
  const [, setLocalSideNavPeek] = useLocalStorage("side-bar-peek", true); // eslint-disable-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  const toggleSideNavPeek = (e) => {
    dispatch(globalActions.setSideNavPeek(!sideNavPeek));
    setLocalSideNavPeek(!sideNavPeek);
  };
  //#endregion

  return (
    <>
      <table className={`${tableStyles.table}`}>
        <thead className={tableStyles.thead.base}>
          <tr>
            <th>Name</th>
            <th className={tableStyles.thead.th.actions}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {configItems &&
            configItems.map((config, i) => (
              <tr key={i} className={tableStyles.tbody.tr}>
                <td>{config.name}</td>
                <td className={tableStyles.tbody.td.actions}>
                  <div className="flex justify-end">
                    {config.name !== "Selected Theme" ? (
                      <FormField
                        name={config.name}
                        type="checkbox"
                        checked={eval(config.state) /* eslint-disable-line no-eval */}
                        onClick={() =>
                          /* eslint-disable-next-line no-eval */
                          eval(config.method).call()
                        }
                      />
                    ) : (
                      <Button
                        type="button"
                        className="ml-2 text-sm"
                        onClick={() =>
                          /* eslint-disable-next-line no-eval */
                          eval(config.method).call()
                        }
                      >
                        {getThemeName()}
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default Config;
