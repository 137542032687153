import { createSlice } from "@reduxjs/toolkit";
import { sortAz } from '../utils/utility';

const mailingGroupSlice = createSlice({
  name: "mailingGroup",
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    replaceMailingGroups(state, action) {
      state.data = action.payload;
    },
    removeMailingGroup(state, action) {
      const id = action.payload;
      state.changed = true;
      state.data = state.data.filter((d) => d.id !== id);
    },
    updateMailingGroup(state, action) {
      const data = action.payload;
      const updatedData = [...state.data.filter((d) => d.id !== data.id), data];
      state.data = updatedData.sort(sortAz);
    },
  },
});

export const mailingGroupActions = mailingGroupSlice.actions;

export default mailingGroupSlice;
