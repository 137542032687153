import { createSlice } from "@reduxjs/toolkit";
import { sortAz } from '../utils/utility';

const initialValues = {
    data: [],
    loading: null
}

const marketsSlice = createSlice({
    name: 'markets',
    initialState: initialValues,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload
        },
        replaceMarkets(state, action) {
            state.data = action.payload;
        },
        removeMarket(state, action) {
            const id = action.payload;
            state.changed = true;
            state.data = state.data.filter((d) => d.id !== id);
        },
        updateMarket(state, action) {
            const data = action.payload;
            const updatedData = [...state.data.filter((d) => d.id !== data.id), data];
            state.data = updatedData.sort(sortAz);
        },
    },
})

export const marketsActions = marketsSlice.actions;

export default marketsSlice;