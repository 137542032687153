import { getRequest, postRequest, deleteRequest, patchRequest } from "../axios";
import { contactsActions } from "./contactsSlice";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";

export const getContacts = () => {
  return async dispatch => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Fetching all contacts`,
        }),
      );
      const contacts = await getRequest("contacts/");
      dispatch(contactsActions.replaceContacts(contacts));
      dispatch(globalActions.setLoading({ loading: false }));
    } catch (error) {
      console.error("getContact: " + error.message)
      if (error.message.substring(0, 14) === "Request failed" || error.message.substring(0, 2) === "40") {
        dispatch(contactsActions.setLoading(false));
      }
      if (error.message.substring(0, 14) !== "Request failed" && error.message.substring(0, 2) !== "40")
        Toast({ status: "error", message: error.message });
    }
  };
};

export const createContact = (contactData) => {
  return async dispatch => {
    try {
      const contact = await postRequest("contacts/", contactData);
      Toast({ status: "success", message: `Contact: ${contact.email} successfully added` });
      dispatch(contactsActions.updateContact(contact));
    } catch (error) {
      console.error("createContact: " + error.message)
      Toast({ status: "error", message: error.message });
    }
  };
}

export const updateContact = (contactData) => {
  return async dispatch => {
    try {
      await patchRequest(`contacts/${contactData.id}/`, contactData);
      Toast({ status: "success", message: "Contacts Updated Successfully" });
      dispatch(contactsActions.updateContact(contactData));
    } catch (error) {
      console.error("update Contact: " + error.message)
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteContact = (id) => {
  return async dispatch => {
    try {
      await deleteRequest(`contacts/${id}/`);
      Toast({ status: "success", message: `Contact deleted` });
      dispatch(contactsActions.removeContact(id));
    } catch (error) {
      console.error("delete Contact: " + error.message)
      Toast({ status: "error", message: error.message });
    }
  };
};
