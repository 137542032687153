import Select from "react-select";

const customStyles = {
    control: (base, state) => ({
        ...base,
        minHeight: "2.5rem",
        padding: "0px",
        outline: '1px solid transparent',
        borderRadius: '0.25rem',
        boxShadow: state.isFocused || state.isActive ? '0 0 0 1px rgb(140,140,140)' : 0,
        borderColor: "rgb(168,168,168)",
        borderWidth: '1px',
        '&:hover': {
            border: '1px solid rgb(140,140,140)',
        },
        '&:focus': {
            // border: '1px solid rgb(140,140,140)',
            // outline: '1px solid rgb(140,140,140)',
        },
        '&:active': {
            border: '1px solid rgb(140,140,140)',
        },
        width: '100%',
    }),
    option: (base, state) => ({
        ...base,
        backgroundColor: state.isSelected ? 'rgb(87,87,87)' : '',
        '&:hover': {
            backgroundColor: state.isSelected ? 'rgb(140,140,140)' : 'rgb(232,232,232)',
            color: state.isSelected ? 'black' : 'rgb(30,30,30)',
        },
    }),
    menuPortal: (base) => ({ ...base, zIndex: "1000" })
};

export const CustomSelect = ({
    placeholder,
    form,
    field,
    options,
    isMulti = false,
    disabled,
    isCustomHandler = false,
    customHandlerCallback = {},
    ...rest
}) => {

    const onChange = (option) => {
        const val = !option ? (isMulti ? [] : '') : isMulti ? (Array.isArray(option) ? option.map(x => x.value) : []) : (option).value
        form.setFieldValue(field.name, val ?? '');
        if (isCustomHandler) customHandlerCallback(val);
    };

    const getValue = () => {
        const isArray = Array.isArray(field.value);

        let val;
        if (options) {
            if (isMulti && isArray)
                val = options.filter(option => field.value.indexOf(option.value) >= 0) ?? []
            else
                val = options.find(option => option.value === field?.value) ?? ''
        } else {
            val = isMulti ? [] : '';
        }

        return val
    };

    return (
        <>
            <Select
                {...rest}
                name={field.name}
                value={getValue()}
                onChange={onChange}
                placeholder={placeholder}
                options={options}
                isMulti={isMulti}
                styles={customStyles}
                isDisabled={disabled}
                isClearable
                className="w-full"
                menuPortalTarget={document.body}
            />
        </>
    );
};

export default CustomSelect;
