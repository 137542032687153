import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard, resetDashboard, setLoading } from "../../store/dashboardActions";
import Timeline from "../../components/dashboard/timeline";
import BarChart from "./charts/BarChart";
import YearlyData from "../../components/dashboard/yearlyData";
import { getFormattedDate } from "../../utils/utility";

const Dashboard = () => {
  const dashboardData = useSelector((state) => state.dashboard);
  const [dashboardClubedData, setDashboardClubedData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getDashboard());
    return () => {
      dispatch(resetDashboard());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dashboardData) return;

    if (dashboardData) {
      setDashboardClubedData(dashboardData);
    }
  }, [dashboardData]);

  return (
    <>
      <div className="overflow-auto p-4 text-heading bg-primary/20 rounded-lg">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-semibold">EOPL Dashboard</h1>
          <div className="flex items-center">
            <p className="text-md font-semibold">Reporting for EOPL Projects can be found within our Tableau reporting suite {">"}</p>
            <div className="relative">
              <span className="absolute text-xs font-bold border border-2 border-black	 bg-danger/60 px-2 py-1 -top-2 -right-4 rounded-full rotate-12">
                Coming Soon
              </span>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {/* <a href="#" target="blank"> */}
              <img className="bg-white rounded p-2 shadow-sm ml-4" width="120px" src="/tableau.png" alt="tableau" />
              {/* </a> */}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 my-4 hd-[500px] mdax-h-[288px] overflow-hidden xl:max-w-none max-w-fit">
          <div className="w-full col-span-12">
            <div className="flex flex-col flex-1 flex-grow shadow-md  w-full bg-primary relative h-full overflow-auto">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary">
                <p>EOPL Runouts Timeline</p>
              </div>
              <Timeline data={dashboardClubedData?.timeline} />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 my-4 overflow-hidden xl:max-w-none max-w-fit">
          <div className="w-full col-span-12">
            <div className="flex flex-col flex-1 flex-grow shadow-md  w-full bg-white relative h-full overflow-audto">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary">
                <p>Active Projects</p>
              </div>
              <div className="flex justify-centeDr items-streDtch w-full p-2 my-3">
                <YearlyData data={dashboardClubedData?.quaterWiseData} />
              </div>
            </div>
          </div>
        </div>

        <div className="grid gdrid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 my-4 h-70 max-h-[280px] overflow-hidden xl:max-w-nodne dmax-w-fit">
          <div className="w-full col-span-1">
            <div className="shadow-md pb-6 w-full bg-white relative h-full">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary">
                <p>Total EOPL Projects</p>
                <p>
                  {dashboardClubedData?.currentQuaterProjectTotals?.Quarter} {dashboardClubedData?.currentQuaterProjectTotals?.Year}
                </p>
              </div>
              <div className="flex justify-center items-stretch w-full p-3 ">
                {/* <PieChart /> */}
                {/* <LineChart /> */}
                <BarChart data={dashboardClubedData?.currentQuaterProjectTotals} />
              </div>
            </div>
          </div>

          <div className="w-full col-span-1">
            <div className="shadow-md pb-6 w-full bg-white  relative font-bold h-full">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary">
                <p>Total Active</p>
                <p>{dashboardClubedData?.currentQuaterBrandWiseTotals?.currentQuarter}</p>
              </div>
              <div className="flex items-end w-full space-x-2 px-4 my-3">
                <p
                  className={`text-4xl w-full text-center ${
                    dashboardClubedData?.currentQuaterBrandWiseTotals?.currentQuarterCount !==
                    dashboardClubedData?.currentQuaterBrandWiseTotals?.countJaguar +
                      dashboardClubedData?.currentQuaterBrandWiseTotals?.countLandRover +
                      dashboardClubedData?.currentQuaterBrandWiseTotals?.countDual
                      ? "text-danger"
                      : "text-typography-1 dark:text-white"
                  }`}
                >
                  {dashboardClubedData?.currentQuaterBrandWiseTotals?.currentQuarterCount}
                </p>
              </div>
              <div className="flex items-efnd w-full px-2 my-3 items-stretch">
                <p className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">JAG</span>
                  <span className="block text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-brand-1 text-white py-1 px-3">
                    {dashboardClubedData?.currentQuaterBrandWiseTotals?.countJaguar}
                  </span>
                </p>
                <p className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">LR</span>
                  <span className="block text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-brand-2 text-white py-1 px-3">
                    {dashboardClubedData?.currentQuaterBrandWiseTotals?.countLandRover}
                  </span>
                </p>
                <p className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">Dual</span>
                  <span className="block text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-secondary-1 text-white py-1 px-3">
                    {dashboardClubedData?.currentQuaterBrandWiseTotals?.countDual}
                  </span>
                </p>
              </div>
              <span className="text-typography-1 dark:text-white px-4 py-2 text-sm font-bold flex items-center justify-center">
                Active Projects End: {getFormattedDate(dashboardClubedData?.currentQuaterBrandWiseTotals?.activeProjectEnd)}{" "}
              </span>
            </div>
          </div>

          <div className="w-full col-span-1">
            <div className="shadow-md pb-6 w-full bg-white relative font-bold h-full">
              <div className="flex justify-between h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary">
                <p>Next Quarter</p>
                <p>{dashboardClubedData?.nextQuaterBrandWiseTotals?.nextQuarter}</p>
              </div>
              <div className="flex items-end w-full space-x-2 px-4 my-3">
                <p
                  className={`text-4xl w-full text-center ${
                    dashboardClubedData?.nextQuaterBrandWiseTotals?.nextQuarterProjectCount !==
                    dashboardClubedData?.nextQuaterBrandWiseTotals?.countJaguar +
                      dashboardClubedData?.nextQuaterBrandWiseTotals?.countLandRover +
                      dashboardClubedData?.nextQuaterBrandWiseTotals?.countDual
                      ? "text-danger"
                      : "text-typography-1 dark:text-white"
                  }`}
                >
                  {dashboardClubedData?.nextQuaterBrandWiseTotals?.nextQuarterProjectCount}
                </p>
              </div>
              <div className="flex items-efnd w-full px-2 my-3 items-stretch">
                <p className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">JAG</span>
                  <span className="block text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-brand-1 text-white py-1 px-3">
                    {dashboardClubedData?.nextQuaterBrandWiseTotals?.countJaguar}
                  </span>
                </p>

                <p className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">LR</span>
                  <span className="block text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-brand-2 text-white py-1 px-3">
                    {dashboardClubedData?.nextQuaterBrandWiseTotals?.countLandRover}
                  </span>
                </p>
                <p className="text-sm w-full text-typography-1 dark:text-white text-center font-semibold">
                  <span className="pb-2 block">Dual</span>
                  <span className="block text-md w-16 mx-auto rounded-full shadow-lg shadow-black bg-secondary-1 text-white py-1 px-3">
                    {dashboardClubedData?.nextQuaterBrandWiseTotals?.countDual}
                  </span>
                </p>
              </div>
              <span className="text-typography-1 dark:text-white px-4 py-2 text-sm font-bold flex items-center justify-center">
                Next Future Project Starts: {getFormattedDate(dashboardClubedData?.nextQuaterBrandWiseTotals?.nextFutureProjectStart)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
