import { createSlice } from "@reduxjs/toolkit";

const genericEmailSlice = createSlice({
  name: "spoIssue",
  initialState: {
    data: {},
    loading: true,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    resetGenericEmail(state, action) {
      state.data = {};
      state.loading = false;
    },
    replaceGenericEmail(state, action) {
      state.data = action.payload;
    },
  },
});

export const genericEmailActions = genericEmailSlice.actions;

export default genericEmailSlice;
