import { childPartListActions } from "./childPartListSlice";
import axios, { deleteRequest, getRequest, postRequest, putRequest } from "../axios";
import Toast from "../components/ui/Toast";
import { globalActions } from "./globalSlice";
import fileDownload from "js-file-download";
import { getFormattedDate } from "../utils/utility";

export const setChanged = (value) => {
  return (dispatch) => {
    dispatch(childPartListActions.setChanged(value));
  };
};

export const setSearchValue = (value) => {
  return (dispatch) => {
    dispatch(childPartListActions.setSearchValue(value));
  };
};

export const resetSpo = () => {
  return (dispatch) => {
    dispatch(childPartListActions.resetSpo());
  };
};

export const resetAllSpoData = () => {
  return (dispatch) => {
    dispatch(childPartListActions.setSearchValue());
    dispatch(childPartListActions.replaceEoplAllData());
  };
};

export const resetUploaded = () => {
  return (dispatch) => {
    dispatch(childPartListActions.resetUploaded());
  };
};

export const updateField = (field, value) => {
  return (dispatch) => {
    dispatch(childPartListActions.updateSpoData({ field: field, value: value }));
  };
};

export const resetCheckForExisting = () => {
  return (dispatch) => {
    dispatch(childPartListActions.existingSpo());
  };
};

export const checkForExisting = (spo) => {
  return async (dispatch) => {
    try {
      const existing = await getRequest("spo/existing/" + spo);
      dispatch(childPartListActions.existingSpo(existing));
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const uploadChildPartList = (file, eoplProgramRefId) => {
  return async (dispatch) => {
    const sendRequest = () => {
      const url = `/uploadChildParts/`;
      const formData = new FormData();
      formData.append("excel_file", file);
      formData.append("eoplProgramId", eoplProgramRefId);
      dispatch(globalActions.setLoading({ loading: true, label: "Uploading Child Part List" }));
      dispatch(childPartListActions.setUploading(true));
      return axios
        .post(url, formData, {
          responseType: "blob",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        })
        .then((response) => {
          const blob = new Blob([response?.data], {
            type: "application/vnd.ms-excel",
          });
          fileDownload(blob, `Child_Part_List_Upload_Report ${getFormattedDate(Date.now(), true)}.xlsx`);
          if (response?.status === 201) {
            Toast({ status: "success", message: "File processed successfully database insertion" });
          } else if (response?.status === 207) {
            Toast({ status: "warning", message: "File processed with partial database insertion" });
          } else if (response?.status === 400) {
            Toast({ status: "error", message: "File processed unsuccessfully database insertion" });
          }
        })
        .catch((e) => {
          Toast({ status: "error", message: "Upload failed " + e.message });
        })
        .finally(() => {
          dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
          dispatch(childPartListActions.setUploading());
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const getChildPartList = (eoplProgramRefId, indx) => {
  return async (dispatch) => {
    try {
      dispatch(globalActions.setLoading({ loading: true, label: "Getting Child Part List Data" }));
      const details = await getRequest("myChildParts/?eoplProgramId=" + eoplProgramRefId);
      dispatch(
        childPartListActions.replaceChildPartListData({
          childPartList:
            indx === undefined
              ? details?.map((v) => ({ ...v, isSelected: true })) || []
              : details?.map((currElement) => {
                if (currElement.currentEpn === indx) {
                  return { ...currElement, isSelected: false };
                } else {
                  return { ...currElement, isSelected: true };
                }
              }),
        })
      );
    } catch (error) {
      Toast({ status: "error", message: "Error in getting Program Details" });
    } finally {
      dispatch(globalActions.setLoading({ loading: false, label: "Completed" }));
    }
  };
};

export const editChildPartList = (customChildPartListObj, itemId) => {
  return async (dispatch) => {
    try {
      await putRequest("myChildParts/" + itemId + "/", customChildPartListObj.constructedObjectForApiUpdate);
      // dispatch(childPartListActions.updateChildPartListData({ childPartList: responseData || [] }));
      Toast({ status: "Success", message: "ChildPart List Item Edited" });
    } catch (error) {
      console.error("Edit ChildPart list Item: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const createChildPartList = (childPartData) => {
  return async (dispatch) => {
    try {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Saving Child Part ${childPartData?.eoplProgramId}`,
        })
      );
      await postRequest("myChildParts/", childPartData);
      dispatch(globalActions.setLoading({ loading: false }));
      Toast({ status: "Success", message: "ChildPart List Items Added" });
      // dispatch(fetchData());
    } catch (error) {
      console.error("Add ChildPart list Item: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteChildPartItem = (item) => {
  return async (dispatch) => {
    try {
      dispatch(setSearchValue());
      const response = await deleteRequest(`myChildParts/${item.id}/`);
      Toast({ status: "success", message: response.message });
      dispatch(childPartListActions.removeSpoAllData(item.id));
      dispatch(setSearchValue(item));
    } catch (error) {
      console.error("updateUser: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const email = (type) => {
  let url = "";

  if (type === "pdf") url = "spo/sendgridpdf";
  if (type === "excel") url = "spo/sendgrid";

  return async (dispatch) => {
    const sendRequest = async () => {
      Toast({ status: "info", message: `${url}` });
      return axios
        .get(url)
        .then((response) => {
          if (response.status === 200) {
            Toast({ status: "success", message: `${response.data}` });
            return response.data;
          }
          throw new Error(response.data.message);
        })
        .catch((error) => {
          throw new Error(error.message);
        });
    };

    try {
      await sendRequest();
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const download = (type, spo) => {
  //const url = `spo/downloadspo${type}/${spo.id}`;
  // return async (dispatch) => {
  //   try {
  //     const response = await getRequest(url, { responseType: "blob" });
  //     if (response) {
  //       if (type === "pdf") {
  //         const blob = new Blob([response], { type: "application/pdf" });
  //         fileDownload(blob, `SPO ${spo.spo}.pdf`);
  //       } else {
  //         const blob = new Blob([response], {
  //           type: "application/vnd.ms-excel",
  //         });
  //         if (!spo?.spoVersion) fileDownload(blob, `SPO ${spo.spo}.xlsx`);
  //         else
  //           fileDownload(
  //             blob,
  //             `SPO ${spo.spo}_${spo.spoVersion.versionName}.xlsx`
  //           );
  //       }
  //       if (!spo?.spoVersion)
  //         Toast({ status: "success", message: `SPO ${spo.spo} Downloaded` });
  //       else
  //         Toast({
  //           status: "success",
  //           message: `SPO ${spo.spo}_${spo.spoVersion.versionName} Downloaded`,
  //         });
  //     }
  //   } catch (error) {
  //     console.error("download: " + error.message);
  //     Toast({ status: "error", message: error.message });
  //   }
  // };
};
