import { useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import "primeicons/primeicons.css";
import "primereact/resources/themes/nova/theme.css";
import "primereact/resources/primereact.css";
import { useSelector, useDispatch } from "react-redux";
import { uploadCyclePlan, getCyclePlanStatus, cyclePlanAutomationUpload } from "../../store/eoplActions";
import { useNavigate } from "react-router-dom";
import { globalActions } from "../../store/globalSlice";
import useLocalStorage from "../../packages/_utils/useLocalStorage";
// import useToggle from "../../packages/_utils/useToggle";
import Button from "../../packages/button/Button";
import { getFormattedDate } from "../../utils/utility";

const headerClassName = "!bg-primary border-2 border-primary space-x-2";
const contentClassName = "h-20 !text-sm p-0 !cursor-grabing !border-primary hover:!border-dashed border-2 hover:!border-primary";

const chooseOptions = {
  label: "Choose",
  icon: "pi pi-fw pi-plus",
  className: "!bg-secondary hover:!bg-secondary/70 !text-typography-1 dark:!text-white !font-semi-bold focus:!outline-0 focus:!shadow-none !border-none",
};
const uploadOptions = {
  label: "Upload",
  icon: "pi pi-upload",
  className: "!text-white !bg-success hover:!bg-success/80 !font-semi-bold !font-semi-bold focus:!outline-0 focus:!shadow-none !border-none",
};
const cancelOptions = {
  label: "Cancel",
  icon: "pi pi-times",
  className: "!bg-danger hover:!bg-danger/80 !font-semi-bold !font-semi-bold focus:!outline-0 focus:!shadow-none !border-none",
};

// let first = true;

const CyclePlanUpload = () => {
  const uploadRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.global.loading);
  const [localShowSearch] = useLocalStorage("show-search");
  const [showUpload, setShowUpload] = useState(true);
  const [files, setFiles] = useState();
  const [confirmed, setConfirmed] = useState();
  const [cyclePlanStatus, setCyclePlanStatus] = useState(null);

  // useEffect(() => {
  //   return () => {
  //     first = true;
  //   };
  // }, [dispatch]);

  useEffect(() => {
    setShowUpload(false);
    uploadRef.current.clear();
    setFiles();
  }, [loading, dispatch]);

  // const [toggle, isOpen] = useToggle();

  useEffect(() => {
    if (confirmed && files) {
      dispatch(uploadCyclePlan(files)).then(() => {
        dispatch(
          globalActions.setLoading({
            loading: true,
            label: `Refreshing All Data for Programme`,
          })
        );
        setTimeout(() => {
          navigate("/eopl/allprograms");
          dispatch(globalActions.setLoading({ loading: false }));
        }, [3000]);
      });
    }
  }, [dispatch, files, confirmed, navigate]);

  const onUploadHandler = (event) => {
    setConfirmed(false);
    setFiles(event.files[0]);
    setConfirmed(true);
  };

  // const toggleShowHandle = () => {
  //   setShowUpload(!showUpload);
  //   // setTimeout(() => {
  //   //   dispatch(resetSpo());
  //   // }, [500]);
  // };

  const checkCyclePlanStatus = () => {
    dispatch(getCyclePlanStatus()).then((data) => {
      setCyclePlanStatus(data?.cyclePlanMeta);
    });
  };

  const cyclePlanAutomation = () => {
    dispatch(cyclePlanAutomationUpload()).then(() => {
      dispatch(
        globalActions.setLoading({
          loading: true,
          label: `Refreshing All Data for Programme`,
        })
      );
      setTimeout(() => {
        navigate("/eopl/allprograms");
        dispatch(globalActions.setLoading({ loading: false }));
      }, [3000]);
    });
  };

  return (
    <div>
      <div
        className={`${
          showUpload || !localShowSearch ? "mt-0 opacity-100" : "-mt-[249px] opacity-0"
        } transition-all duration-500 delay-100 ease-out flex flex-col w-full mb-8`}
      >
        <h2 className="mb-4 text-typography-1 text-lg font-bold md:text-2xl">EoPL Cycle Plan Automated Upload</h2>
        <div className="!bg-primary h-20 !text-sm flex items-center space-x-4 pl-4 rounded">
          <Button
            className={`${chooseOptions.className} h-10`}
            tippy="Check Status"
            onClick={() => {
              checkCyclePlanStatus();
            }}
          >
            Check Cycle Plan Status &nbsp;
          </Button>
          <Button
            tippy="Upload"
            className={`${uploadOptions.className} h-10`}
            disabled={!cyclePlanStatus?.cyclePlanUpdateStatus}
            onClick={() => {
              cyclePlanAutomation();
            }}
          >
            Upload the Cycle Plan
          </Button>
        </div>
        {cyclePlanStatus && (
          <div style={{ display: "ruby", alignItems: "center" }}>
            <p>
              <strong>File Name :</strong>
              {cyclePlanStatus?.cyclePlanFile}
            </p>
            &nbsp;&nbsp;&nbsp;
            <p>
              <strong>Last Modified Date :</strong> {getFormattedDate(cyclePlanStatus?.cyclePlanLastModified)}
            </p>
          </div>
        )}
      </div>
      <div
        className={`${
          showUpload || !localShowSearch ? "mt-0 opacity-100" : "-mt-[249px] opacity-0"
        } transition-all duration-500 delay-100 ease-out flex flex-col w-full mb-8`}
      >
        <h2 className="mb-4 text-typography-1 text-lg font-bold md:text-2xl">EoPL Cycle Plan Upload</h2>

        <FileUpload
          headerClassName={headerClassName}
          contentClassName={contentClassName}
          ref={uploadRef}
          name="spo_upload"
          multiple={false}
          accept=".xlsx"
          customUpload={true}
          uploadHandler={onUploadHandler}
          chooseOptions={chooseOptions}
          uploadOptions={uploadOptions}
          cancelOptions={cancelOptions}
          emptyTemplate={
            <p className="h-full p-2 !text-md font-semibold flex items-center">
              Drag and drop only Excel or CSV files here you want to upload, or use the choose button.
            </p>
          }
        />
      </div>
      {/* <>
        <PageTitle
          header="EoPl Cycle Plan Details"
          label="Show Upload"
          showSearch={showUpload}
          onClick={toggleShowHandle}
        />
        {!programmeData.id && (
          <p className={`text-xl text-typography-1`}>
            Please upload an Cycle Plan first
          </p>
        )}
      </> */}
      {/* {programmeData.id && !first && (
        <EOPLForm id={programmeData.id} byId={true} fromUpload={true} />
      )} */}

      {/* {existingSpo && isOpen && (
        <ConfirmationModal
          title="Upload EoPL Cycle Plan"
          strapline={
            <>
              <p>
                SPO <strong>{existingSpo.programmeData}</strong> has been found in the
                database
              </p>
              <br />
            </>
          }
          data={existingSpo}
          confirmAction={uploadConfirmHandler}
          isOpen={isOpen}
          toggle={toggle}
        />
      )} */}
    </div>
  );
};

export default CyclePlanUpload;
