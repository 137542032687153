import React, { useCallback, useEffect, useState } from 'react';

// import "./Intro.css";
import './IntroAnimation.css';

const Intro = () => {
  const [runAnimation, setRunAnimation] = useState(false);

  const handleRunAmimation = useCallback(
    () => setRunAnimation(!runAnimation),
    [runAnimation]
  );

  useEffect(() => {
    if (!runAnimation) handleRunAmimation('10vh');
  }, [handleRunAmimation, runAnimation]);

  return (
    <div id='container'>
      <div
        id='logoWrapper'
        onClick={handleRunAmimation}
        onKeyPress={handleRunAmimation}
        className={runAnimation ? 'run-animation' : ''}
      >
        <svg
          id='appHeadline'
          width='80%'
          height='80%'
          viewBox='0 0 516 123'
          fill='none'
          className={runAnimation ? 'run-animation' : ''}
          xmlns='http://www.w3.org/2000/svg'
        >
          <defs>
            <filter id='demo1'>
              {/* <!--Blur effect--> */}
              <feGaussianBlur stdDeviation='5' result='blur1' />
              {/* <!--Lighting effect--> */}
              <feSpecularLighting
                result='spec1'
                in='blur1'
                specularExponent='1000'
                lightingColor='#A3A4A6'
              >
                {/* <!--Light source effect--> */}
                <fePointLight x='-200' y='0' z='200'>
                  <animate
                    attributeName='x'
                    values='-200;516;800'
                    dur='3s'
                    repeatCount='indefinite'
                    // begin="2.5s"
                    begin='0s'
                    restart='always'
                  />
                </fePointLight>
              </feSpecularLighting>
              {/* <!--Composition of inputs--> */}
              <feComposite
                in='SourceGraphic'
                in2='spec1'
                operator='arithmetic'
                k1='0'
                k2='1'
                k3='1'
                k4='0'
              />
            </filter>
          </defs>
          <g filter='url(#demo1)'>
            <path d='M434.209 95.28H489.799V115H410.749V2.28999H434.209V95.28Z' />
            <path d='M301.401 115H277.941V2.28999H321.971C330.584 2.28999 338.008 3.13999 344.241 4.84C350.474 6.54 355.631 9.43 359.711 13.51C362.658 16.57 364.981 20.3667 366.681 24.9C368.381 29.4333 369.231 34.59 369.231 40.37C369.231 46.49 368.324 52.0433 366.511 57.03C364.698 61.9033 361.978 66.04 358.351 69.44C354.951 72.6133 350.814 75.05 345.941 76.75C341.068 78.3367 334.948 79.13 327.581 79.13H301.401V115ZM333.701 59.58C335.628 59.58 337.101 59.3533 338.121 58.9C339.141 58.4467 340.048 57.6533 340.841 56.52C342.088 54.7067 343.108 52.3267 343.901 49.38C344.808 46.4333 345.261 43.43 345.261 40.37C345.261 34.1367 343.731 29.6033 340.671 26.77C338.858 25.07 336.534 23.88 333.701 23.2C330.868 22.52 327.411 22.18 323.331 22.18H301.401V59.58H333.701Z' />
            <path d='M177.81 116.7C170.33 116.7 163.19 115.34 156.39 112.62C149.59 109.9 143.583 106.047 138.37 101.06C133.157 96.0733 128.963 90.01 125.79 82.87C122.73 75.6167 121.2 67.57 121.2 58.73C121.2 49.7767 122.73 41.73 125.79 34.59C128.963 27.45 133.157 21.3867 138.37 16.4C143.583 11.3 149.59 7.39 156.39 4.66999C163.19 1.94999 170.33 0.589994 177.81 0.589994H178.15C185.63 0.589994 192.77 1.94999 199.57 4.66999C206.37 7.39 212.377 11.3 217.59 16.4C222.803 21.3867 226.94 27.45 230 34.59C233.173 41.73 234.76 49.7767 234.76 58.73C234.76 67.57 233.173 75.6167 230 82.87C226.94 90.01 222.803 96.0733 217.59 101.06C212.377 106.047 206.37 109.9 199.57 112.62C192.77 115.34 185.63 116.7 178.15 116.7H177.81ZM145.68 58.73C145.68 69.95 148.513 78.62 154.18 84.74C159.96 90.86 167.837 93.92 177.81 93.92H178.15C188.123 93.92 195.943 90.9167 201.61 84.91C207.39 78.79 210.28 70.0633 210.28 58.73C210.28 47.3967 207.39 38.7267 201.61 32.72C195.943 26.6 188.123 23.54 178.15 23.54H177.81C167.837 23.54 159.96 26.6 154.18 32.72C148.513 38.7267 145.68 47.3967 145.68 58.73Z' />
            <path d='M81.8571 2.28999V22.01H24.0571V47.68H76.2471V67.23H24.0571V95.28H83.5571V115H0.59711V2.28999H81.8571Z' />
          </g>
        </svg>

        <svg
          id='appStrapline'
          width='505'
          height='30'
          viewBox='0 0 505 30'
          fill='none'
          className={runAnimation ? 'run-animation' : ''}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M493.793 0.783997V4.496H482.913V9.328H492.737V13.008H482.913V18.288H494.113V22H478.497V0.783997H493.793Z' />
          <path d='M460.058 9.68H469.53V13.328H460.058V22H455.642V0.783997H470.65V4.496H460.058V9.68Z' />
          <path d='M442.29 0.783997H446.706V22H442.29V0.783997Z' />
          <path d='M423.942 18.288H434.406V22H419.526V0.783997H423.942V18.288Z' />
          <path d='M394.672 0.783997H398.928V22H395.632C395.419 22 395.259 21.9787 395.152 21.936C395.046 21.8933 394.928 21.7867 394.8 21.616L386.576 11.28C386.299 10.9387 386.011 10.5867 385.712 10.224C385.435 9.84 385.168 9.47733 384.912 9.136C384.656 8.79466 384.422 8.48533 384.208 8.208C384.016 7.90933 383.856 7.67466 383.728 7.504V22H379.472V0.783997H382.8C383.014 0.783997 383.163 0.80533 383.248 0.847998C383.355 0.890664 383.472 0.99733 383.6 1.168L391.184 10.672C391.526 11.12 391.878 11.568 392.24 12.016C392.603 12.464 392.934 12.8907 393.232 13.296C393.552 13.7013 393.83 14.0747 394.064 14.416C394.32 14.7573 394.523 15.0347 394.672 15.248V0.783997Z' />
          <path d='M360.937 22.32C359.529 22.32 358.185 22.064 356.905 21.552C355.625 21.04 354.494 20.3147 353.513 19.376C352.531 18.4373 351.742 17.296 351.145 15.952C350.569 14.5867 350.281 13.072 350.281 11.408C350.281 9.72266 350.569 8.20799 351.145 6.86399C351.742 5.51999 352.531 4.37866 353.513 3.43999C354.494 2.47999 355.625 1.74399 356.905 1.23199C358.185 0.719989 359.529 0.463989 360.937 0.463989H361.001C362.409 0.463989 363.753 0.719989 365.033 1.23199C366.313 1.74399 367.443 2.47999 368.425 3.43999C369.406 4.37866 370.185 5.51999 370.761 6.86399C371.358 8.20799 371.657 9.72266 371.657 11.408C371.657 13.072 371.358 14.5867 370.761 15.952C370.185 17.296 369.406 18.4373 368.425 19.376C367.443 20.3147 366.313 21.04 365.033 21.552C363.753 22.064 362.409 22.32 361.001 22.32H360.937ZM354.889 11.408C354.889 13.52 355.422 15.152 356.489 16.304C357.577 17.456 359.059 18.032 360.937 18.032H361.001C362.878 18.032 364.35 17.4667 365.417 16.336C366.505 15.184 367.049 13.5413 367.049 11.408C367.049 9.27466 366.505 7.64266 365.417 6.51199C364.35 5.35999 362.878 4.78399 361.001 4.78399H360.937C359.059 4.78399 357.577 5.35999 356.489 6.51199C355.422 7.64266 354.889 9.27466 354.889 11.408Z' />
          <path d='M337.952 0.783997H342.368V22H337.952V0.783997Z' />
          <path d='M323.545 22H319.129V4.496H312.057V0.783997H330.617V4.496H323.545V22Z' />
          <path d='M306.276 17.68C305.721 18.2987 305.092 18.896 304.388 19.472C303.684 20.0267 302.916 20.5173 302.084 20.944C301.273 21.3707 300.399 21.712 299.46 21.968C298.543 22.2027 297.572 22.32 296.548 22.32C295.012 22.32 293.615 22.0427 292.356 21.488C291.097 20.9333 290.02 20.176 289.124 19.216C288.249 18.2347 287.567 17.0827 287.076 15.76C286.607 14.416 286.372 12.9653 286.372 11.408C286.372 9.72266 286.649 8.20799 287.204 6.86399C287.759 5.49866 288.505 4.34666 289.444 3.40799C290.383 2.44799 291.471 1.72266 292.708 1.23199C293.967 0.719989 295.289 0.463989 296.676 0.463989C298.916 0.463989 300.804 1.02932 302.34 2.15999C303.876 3.26932 305.017 4.82666 305.764 6.83199L302.916 8.65599L300.964 6.86399C300.175 6.13866 299.428 5.59466 298.724 5.23199C298.041 4.86932 297.252 4.68799 296.356 4.68799C295.631 4.68799 294.937 4.82666 294.276 5.10399C293.636 5.35999 293.071 5.76532 292.58 6.31999C292.089 6.87466 291.695 7.57866 291.396 8.43199C291.119 9.26399 290.98 10.2453 290.98 11.376C290.98 12.3787 291.108 13.296 291.364 14.128C291.641 14.96 292.036 15.6747 292.548 16.272C293.06 16.848 293.689 17.296 294.436 17.616C295.183 17.936 296.047 18.096 297.028 18.096C297.689 18.096 298.361 18.0107 299.044 17.84C299.748 17.6693 300.505 17.392 301.316 17.008L304.548 15.44L306.276 17.68Z' />
          <path d='M278.787 13.264C278.787 14.8 278.595 16.0907 278.211 17.136C277.827 18.16 277.262 19.0453 276.515 19.792C275.704 20.624 274.68 21.2533 273.443 21.68C272.227 22.1067 270.883 22.32 269.411 22.32C267.96 22.32 266.627 22.1067 265.411 21.68C264.195 21.2533 263.171 20.624 262.339 19.792C261.592 19.0453 261.027 18.16 260.643 17.136C260.259 16.0907 260.067 14.8 260.067 13.264V0.783997H264.483V12.88C264.483 13.84 264.558 14.64 264.707 15.28C264.856 15.8987 265.144 16.4213 265.571 16.848C265.976 17.232 266.52 17.5413 267.203 17.776C267.907 17.9893 268.664 18.096 269.475 18.096C270.286 18.096 271.043 17.9893 271.747 17.776C272.451 17.5413 272.995 17.232 273.379 16.848C273.806 16.4213 274.094 15.8987 274.243 15.28C274.392 14.64 274.467 13.84 274.467 12.88V0.783997H278.787V13.264Z' />
          <path d='M232.811 22V0.783997H239.883C241.995 0.783997 243.83 0.99733 245.387 1.424C246.944 1.82933 248.267 2.50133 249.355 3.44C250.379 4.31466 251.158 5.41333 251.691 6.736C252.224 8.03733 252.491 9.59466 252.491 11.408C252.491 13.2 252.224 14.7573 251.691 16.08C251.158 17.3813 250.379 18.4693 249.355 19.344C248.267 20.2827 246.944 20.9653 245.387 21.392C243.83 21.7973 241.995 22 239.883 22H232.811ZM239.755 18.16C241.312 18.16 242.624 18.032 243.691 17.776C244.758 17.4987 245.622 17.04 246.283 16.4C247.371 15.3547 247.915 13.68 247.915 11.376C247.915 10.2027 247.776 9.21066 247.499 8.4C247.243 7.58933 246.838 6.91733 246.283 6.384C245.622 5.744 244.758 5.296 243.691 5.04C242.624 4.76266 241.312 4.624 239.755 4.624H237.227V18.16H239.755Z' />
          <path d='M214.276 22.32C212.868 22.32 211.524 22.064 210.244 21.552C208.964 21.04 207.833 20.3147 206.852 19.376C205.87 18.4373 205.081 17.296 204.484 15.952C203.908 14.5867 203.62 13.072 203.62 11.408C203.62 9.72266 203.908 8.20799 204.484 6.86399C205.081 5.51999 205.87 4.37866 206.852 3.43999C207.833 2.47999 208.964 1.74399 210.244 1.23199C211.524 0.719989 212.868 0.463989 214.276 0.463989H214.34C215.748 0.463989 217.092 0.719989 218.372 1.23199C219.652 1.74399 220.782 2.47999 221.764 3.43999C222.745 4.37866 223.524 5.51999 224.1 6.86399C224.697 8.20799 224.996 9.72266 224.996 11.408C224.996 13.072 224.697 14.5867 224.1 15.952C223.524 17.296 222.745 18.4373 221.764 19.376C220.782 20.3147 219.652 21.04 218.372 21.552C217.092 22.064 215.748 22.32 214.34 22.32H214.276ZM208.228 11.408C208.228 13.52 208.761 15.152 209.828 16.304C210.916 17.456 212.398 18.032 214.276 18.032H214.34C216.217 18.032 217.689 17.4667 218.756 16.336C219.844 15.184 220.388 13.5413 220.388 11.408C220.388 9.27466 219.844 7.64266 218.756 6.51199C217.689 5.35999 216.217 4.78399 214.34 4.78399H214.276C212.398 4.78399 210.916 5.35999 209.828 6.51199C208.761 7.64266 208.228 9.27466 208.228 11.408Z' />
          <path d='M188.984 15.28C188.856 15.0667 188.739 14.9493 188.632 14.928C188.547 14.8853 188.387 14.864 188.152 14.864H183.704V22H179.288V0.783997H187.641C189.07 0.783997 190.339 0.911997 191.448 1.168C192.558 1.40266 193.486 1.79733 194.232 2.352C195 2.90666 195.576 3.62133 195.96 4.496C196.366 5.37066 196.568 6.43733 196.568 7.696C196.568 9.104 196.238 10.3413 195.576 11.408C194.936 12.4747 194.094 13.3387 193.048 14L197.88 22H193.432C193.219 22 193.059 21.9893 192.952 21.968C192.867 21.9253 192.76 21.7867 192.632 21.552L188.984 15.28ZM190.072 11.184C190.648 11.184 191.064 10.992 191.32 10.608C191.555 10.2453 191.737 9.81866 191.865 9.328C191.993 8.816 192.056 8.272 192.056 7.696C192.056 6.62933 191.8 5.85066 191.288 5.36C190.947 5.06133 190.499 4.848 189.944 4.72C189.39 4.592 188.664 4.528 187.768 4.528H183.704V11.184H190.072Z' />
          <path d='M159.068 22H154.652V0.783997H162.94C164.562 0.783997 165.959 0.943997 167.132 1.264C168.306 1.584 169.276 2.128 170.044 2.896C170.599 3.472 171.036 4.18666 171.356 5.04C171.676 5.89333 171.836 6.864 171.836 7.952C171.836 9.104 171.666 10.1493 171.324 11.088C170.983 12.0053 170.471 12.784 169.788 13.424C169.148 14.0213 168.37 14.48 167.452 14.8C166.535 15.0987 165.383 15.248 163.996 15.248H159.068V22ZM165.148 11.568C165.511 11.568 165.788 11.5253 165.98 11.44C166.172 11.3547 166.343 11.2053 166.492 10.992C166.727 10.6507 166.919 10.2027 167.068 9.648C167.239 9.09333 167.324 8.528 167.324 7.952C167.324 6.77866 167.036 5.92533 166.46 5.392C166.119 5.072 165.682 4.848 165.148 4.72C164.615 4.592 163.964 4.528 163.196 4.528H159.068V11.568H165.148Z' />
          <path d='M124.452 9.68H133.924V13.328H124.452V22H120.036V0.783997H135.044V4.496H124.452V9.68Z' />
          <path d='M101.5 22.32C100.092 22.32 98.7485 22.064 97.4685 21.552C96.1885 21.04 95.0578 20.3147 94.0765 19.376C93.0952 18.4373 92.3058 17.296 91.7085 15.952C91.1325 14.5867 90.8445 13.072 90.8445 11.408C90.8445 9.72266 91.1325 8.20799 91.7085 6.86399C92.3058 5.51999 93.0952 4.37866 94.0765 3.43999C95.0578 2.47999 96.1885 1.74399 97.4685 1.23199C98.7485 0.719989 100.092 0.463989 101.5 0.463989H101.564C102.972 0.463989 104.316 0.719989 105.596 1.23199C106.876 1.74399 108.007 2.47999 108.988 3.43999C109.97 4.37866 110.748 5.51999 111.324 6.86399C111.922 8.20799 112.221 9.72266 112.221 11.408C112.221 13.072 111.922 14.5867 111.324 15.952C110.748 17.296 109.97 18.4373 108.988 19.376C108.007 20.3147 106.876 21.04 105.596 21.552C104.316 22.064 102.972 22.32 101.564 22.32H101.5ZM95.4525 11.408C95.4525 13.52 95.9858 15.152 97.0525 16.304C98.1405 17.456 99.6232 18.032 101.5 18.032H101.564C103.442 18.032 104.914 17.4667 105.98 16.336C107.068 15.184 107.612 13.5413 107.612 11.408C107.612 9.27466 107.068 7.64266 105.98 6.51199C104.914 5.35999 103.442 4.78399 101.564 4.78399H101.5C99.6232 4.78399 98.1405 5.35999 97.0525 6.51199C95.9858 7.64266 95.4525 9.27466 95.4525 11.408Z' />
          <path d='M52.6273 22V0.783997H59.6993C61.8113 0.783997 63.6459 0.99733 65.2033 1.424C66.7606 1.82933 68.0833 2.50133 69.1713 3.44C70.1953 4.31466 70.9739 5.41333 71.5073 6.736C72.0406 8.03733 72.3073 9.59466 72.3073 11.408C72.3073 13.2 72.0406 14.7573 71.5073 16.08C70.9739 17.3813 70.1953 18.4693 69.1713 19.344C68.0833 20.2827 66.7606 20.9653 65.2033 21.392C63.6459 21.7973 61.8113 22 59.6993 22H52.6273ZM59.5713 18.16C61.1286 18.16 62.4406 18.032 63.5073 17.776C64.5739 17.4987 65.4379 17.04 66.0993 16.4C67.1873 15.3547 67.7313 13.68 67.7313 11.376C67.7313 10.2027 67.5926 9.21066 67.3153 8.4C67.0593 7.58933 66.6539 6.91733 66.0993 6.384C65.4379 5.744 64.5739 5.296 63.5073 5.04C62.4406 4.76266 61.1286 4.624 59.5713 4.624H57.0433V18.16H59.5713Z' />
          <path d='M39.5348 0.783997H43.7908V22H40.4948C40.2814 22 40.1214 21.9787 40.0148 21.936C39.9081 21.8933 39.7907 21.7867 39.6628 21.616L31.4388 11.28C31.1614 10.9387 30.8734 10.5867 30.5748 10.224C30.2974 9.84 30.0307 9.47733 29.7747 9.136C29.5187 8.79466 29.2841 8.48533 29.0708 8.208C28.8788 7.90933 28.7188 7.67466 28.5908 7.504V22H24.3347V0.783997H27.6628C27.8761 0.783997 28.0254 0.80533 28.1107 0.847998C28.2174 0.890664 28.3348 0.99733 28.4628 1.168L36.0468 10.672C36.3881 11.12 36.7401 11.568 37.1028 12.016C37.4654 12.464 37.7961 12.8907 38.0948 13.296C38.4148 13.7013 38.6921 14.0747 38.9268 14.416C39.1828 14.7573 39.3854 15.0347 39.5348 15.248V0.783997Z' />
          <path d='M15.5883 0.783997V4.496H4.70825V9.328H14.5323V13.008H4.70825V18.288H15.9083V22H0.292252V0.783997H15.5883Z' />
        </svg>
      </div>
    </div>
  );
};

export default Intro;
