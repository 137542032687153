import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateObject } from "../../utils/utility";
import useToggle from "../../packages/_utils/useToggle";
import {
  getMailingGroups,
  createMailingGroup,
  updateMailingGroup,
  deleteMailingGroup,
} from "../../store/mailingGroupActions";
import Button from "../../packages/button/Button";
import Icon from "../../components/ui/Icon";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../packages/modal/Modal";
import { tableStyles } from "./Settings";

import { Role } from "../../data/roles";

const initialMailingGroup = {
  id: 0,
  name: "",
};

const MailingGroup = () => {
  const dispatch = useDispatch();
  const mailingGroups = useSelector((state) => state.mailingGroup.data);
  const currentUser = useSelector((state) => state.profile.profilesData);

  useEffect(() => {
    dispatch(getMailingGroups());
  }, [dispatch]);

  const [toggle, isOpen] = useToggle();
  const [modalTitle, setModalTitle] = useState("");
  const [selectedMailingGroup, setSelectedMailingGroup] =
    useState(initialMailingGroup);

  const addClick = () => {
    setModalTitle("Create New Mailing Group");
    toggle();
    setSelectedMailingGroup(initialMailingGroup);
  };

  const createClick = () => {
    dispatch(createMailingGroup(selectedMailingGroup));
    toggle();
  };

  const updateClick = () => {
    dispatch(updateMailingGroup(selectedMailingGroup));
    toggle();
  };

  const editClick = (mailingGroup) => {
    setModalTitle("Edit Mailing Group");
    setSelectedMailingGroup(mailingGroup);
    toggle();
  };

  const deleteClick = (id) => {
    dispatch(deleteMailingGroup(id));
  };

  const onChangeHandler = (e, field) => {
    let updatedMailingGroup = updateObject(selectedMailingGroup, {
      [field]: e.target.value,
    });
    setSelectedMailingGroup(updatedMailingGroup);
  };

  return (
    <>
      {currentUser.role !== Role.view && (
        <Button onClick={addClick} color="primary">
          Add Mailing Group
        </Button>
      )}
      <table className={`${tableStyles.table} w-1/2`}>
        <thead className={tableStyles.thead.base}>
          <tr>
            <th>Name</th>
            {currentUser.role !== Role.view && (
              <th className={tableStyles.thead.th.actions}>Actions</th>
            )}
          </tr>
        </thead>
        <tbody>
          {mailingGroups &&
            mailingGroups.map((mailingGroup, i) => (
              <tr key={i} className={tableStyles.tbody.tr}>
                <td>{mailingGroup.name}</td>
                {currentUser.role !== Role.view && (
                  <td className={tableStyles.tbody.td.actions}>
                    <Button
                      color="primary"
                      className="btn mr-1"
                      size="sm"
                      onClick={() => editClick(mailingGroup)}
                    >
                      <Icon iconName="Pencil" />
                    </Button>
                    <Button
                      color="danger"
                      className="btn mr-1"
                      size="sm"
                      onClick={() => deleteClick(mailingGroup.id)}
                    >
                      <Icon iconName="Trash" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <Modal
        isOpen={isOpen}
        toggle={toggle}
        animate={true}
        closeOnClickOutside={true}
      >
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 w-96 bd-highlight">
              <div className="input-group mb-3">
                <span className="input-group-text">Mailing Group</span>
                <input
                  type="text"
                  className="form-control"
                  value={selectedMailingGroup.name}
                  onChange={(e) => onChangeHandler(e, "name")}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
          {selectedMailingGroup.id === 0 ? (
            <Button onClick={createClick} color="primary" className="mr-1">
              Create
            </Button>
          ) : null}

          {selectedMailingGroup.id !== 0 ? (
            <Button onClick={updateClick} color="primary" className="mr-1">
              Update
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default MailingGroup;
