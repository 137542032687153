import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FieldArray } from "formik";
import ShoppingList from "./ShoppingList";
import { shoppingListObject } from "../../../store/shoppingListSlice";
import { createShoppingList, getShoppingList, uploadShoppingList } from "../../../store/shoppingListActions";
import Button from "../../../packages/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { ArrowLeftIcon, DownloadIcon, TrashIcon, UploadIcon, PlusIcon } from "@heroicons/react/solid";
import { download } from "../../../store/eoplActions";
import React from "react";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "../../../packages/modal/Modal";
import useToggle from "../../../packages/_utils/useToggle";
import * as Yup from "yup";
import { Role } from "../../../data/roles";
import Tippy from "@tippyjs/react";

const ShoppingListAdd = (props) => {
  const { data, prev } = props;
  const [formData] = useState(shoppingListObject);
  const currentUser = useSelector((state) => state.profile.profilesData);
  const dispatch = useDispatch();
  const fileInput = React.createRef();
  const handlePrev = (values) => {
    prev(values);
  };
  const validationSchema = Yup.object().shape({
    shoppingList: Yup.array().of(
      Yup.object().shape({
        epc: Yup.string().nullable().required("Required").typeError("Please add a valid data"),
        callOut: Yup.string().nullable().required("Required").typeError("Please add a valid data"),
        pf: Yup.string().nullable().required("Required").typeError("Please add a valid data"),
        apn: Yup.string().nullable().required("Required").typeError("Please add a valid data"),
        //pfApn: Yup.string().nullable().required("Required").typeError("Please add a valid data"),
        epn: Yup.string().nullable().required("Required").typeError("Please add a valid data"),
        description: Yup.string().nullable().required("Required").typeError("Please add a valid data"),
      })
    ),
  });
  const [file, setFile] = useState(null);
  const [toggle, isOpenModal] = useToggle();
  const handleSubmit = (values) => {
    let shoppingData = values.shoppingList.map((v) => ({ ...v, eoplProgramId: data.eoplProgramRefId }));
    dispatch(createShoppingList(shoppingData)).then(
      () => dispatch(getShoppingList(data.eoplProgramRefId))
      // setFormData(shoppingData)
    );
  };

  const shopping = useSelector((state) => state.shopping.shoppingList);
  const excelDownloadHandler = () => dispatch(download("shoppingListExport", data.eoplProgramRefId));
  const triggerInputFile = (event) => {
    if (fileInput.current !== undefined && fileInput.current.click !== undefined) {
      dispatch(uploadShoppingList(file, data.eoplProgramRefId)).then(() => dispatch(getShoppingList(data.eoplProgramRefId)), setFile(null));
      toggle();
    }
  };
  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = (values) => {
    toggle();
  };

  const errorField = () => {
    return (
      <Tippy arrow={true} content={<span className="bg-secondary text-sm rounded px-2 py-1 shadow text-danger">Required Field</span>}>
        <span className="text-white absolute pointer-evdents-none top-1 right-1 font-bold bg-danger rounded-full w-5 h-5 text-center">!</span>
      </Tippy>
    );
  };

  return (
    <div>
      <Formik
        initialValues={{
          shoppingList: [formData],
        }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values, setFieldTouched, setFieldValue, errors }) => {
          return (
            <>
              {currentUser.role !== Role.view && (
                <Form>
                  <div className="!col-spfan-full whitespace-nowrap overflow-y-scroll mt-2 border-box" style={{ maxHeight: "236px" }}>
                    <FieldArray name="shoppingList">
                      {({ push, remove }) => (
                        <div>
                          <div className="!col-spfan-full whitespace-nowrap mt-2 border-box">
                            <table className="w-full whitespace-nowrap rounfded-tl-lg roundefd-tr-lg">
                              <thead
                                className="text-white bg-primary dark:bg-secondary-3-5 dark:text-typography-1 font-large"
                                style={{ position: "sticky", top: 0 }}
                              >
                                <tr className="hidden md:table-row">
                                  <th className="py-2.5 pl-2 text-left text-xs tracking-wider">
                                    <Button color="secondary" className=" text-sm !text-primary flex items-center" type="button" onClick={() => push()}>
                                      <PlusIcon className="h-4 w-4" />
                                    </Button>
                                  </th>
                                  <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider w-24">EPC</th>
                                  <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider w-24">Callout</th>
                                  <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider w-24">PF</th>
                                  <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider">APN</th>
                                  <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider">PF+APN</th>
                                  <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider">EPN</th>
                                  <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider">Part Description</th>
                                </tr>
                              </thead>
                              <tbody className="flex-1 md:flex-none bg-secondary-4 text-secondary-1 text-sm">
                                {values.shoppingList.map((user, index) => (
                                  <tr key={index} className={`md:h-8 h-12 odd:bg-primary/30 dark:bg-secondary-2 dark:text-white text-typography-1`}>
                                    <td className="text-center">
                                      <Button className=" text-sm" type="button" onClick={() => remove(index)}>
                                        <TrashIcon className="h-4 w-4" />
                                      </Button>
                                    </td>
                                    <td className="relative">
                                      <Field type="text" className="w-24 text-center p-1" name={`shoppingList.${index}.epc`} placeholder="EPC" />
                                      <ErrorMessage name={`shoppingList.${index}.epc`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field type="text" className="w-24 text-center p-1" name={`shoppingList.${index}.callOut`} placeholder="Callout" />
                                      <ErrorMessage name={`shoppingList.${index}.callOut`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-24 text-center p-1"
                                        name={`shoppingList.${index}.pf`}
                                        placeholder="PF"
                                        onChange={(e) => {
                                          setFieldValue(`shoppingList.${index}.pf`, e.target.value);
                                          setFieldValue(`shoppingList.${index}.pfApn`, `${e.target.value}${values.shoppingList[index].apn}`);
                                        }}
                                      />
                                      <ErrorMessage name={`shoppingList.${index}.pf`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`shoppingList.${index}.apn`}
                                        placeholder="apn"
                                        onChange={(e) => {
                                          setFieldValue(`shoppingList.${index}.apn`, e.target.value);
                                          setFieldValue(`shoppingList.${index}.pfApn`, `${values.shoppingList[index].pf}${e.target.value}`);
                                        }}
                                      />
                                      <ErrorMessage name={`shoppingList.${index}.apn`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`shoppingList.${index}.pfApn`}
                                        placeholder="PF+APN"
                                        disabled
                                      />
                                    </td>
                                    <td className="relative">
                                      <Field type="text" className="w-full text-center p-1" name={`shoppingList.${index}.epn`} placeholder="EPN" />
                                      <ErrorMessage name={`shoppingList.${index}.epn`} render={() => errorField()} />
                                    </td>
                                    <td className="relative">
                                      <Field
                                        type="text"
                                        className="w-full text-left p-1"
                                        name={`shoppingList.${index}.description`}
                                        placeholder="Part Description"
                                      />
                                      <ErrorMessage name={`shoppingList.${index}.description`} render={() => errorField()} />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </Form>
              )}
              {currentUser.role !== Role.view && (
                <div className="grid-cols-3 gap-4px-4 py-3 bg-white text-right md:px-6 rounded-b">
                  <Button
                    disabled={values.shoppingList.length === 0}
                    onClick={() => {
                      if (Object.keys(errors).length === 0) {
                        return submitForm();
                      } else {
                        Object.getOwnPropertyNames(errors).forEach((err) => {
                          setFieldTouched(true);
                        });
                      }
                    }}
                    className={`place-self-center col-start-2`}
                  >
                    Submit
                  </Button>
                </div>
              )}

              <ShoppingList data={data.eoplProgramRefId} />
              <div className="grid grid-cols-2 gap-4px-4 py-3 bg-white text-left sm:px-6 rounded-b">
                <Button
                  type="button"
                  onClick={() => {
                    return handlePrev(values);
                  }}
                  className={`bg-primary col-start-1 place-self-start flex items-center`}
                >
                  <ArrowLeftIcon className="h-4 mr-2" />
                  Project Details
                </Button>
                {currentUser.role !== Role.view && (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      className="bg-primary col-start-3 place-self-end flex items-center !px-2"
                      tippy="Download"
                      color="primary"
                      disabled={data?.eoplProgramRefId && shopping?.length === 0}
                      onClick={() => {
                        excelDownloadHandler();
                      }}
                    >
                      Export as CSV &nbsp;
                      <DownloadIcon className="h-4 w-4" />
                    </Button>
                    &nbsp;
                    <Button
                      className="bg-primary col-start-4 place-self-end flex items-center !px-2"
                      tippy="Upload"
                      color="primary"
                      onClick={() => {
                        handleUpload();
                      }}
                    >
                      Upload Shopping List &nbsp;
                      <UploadIcon className="h-4 w-4" />
                    </Button>
                  </div>
                )}
              </div>
            </>
          );
        }}
      </Formik>
      {isOpenModal && (
        <Modal isOpen={isOpenModal} toggle={toggle} animate={true} closeOnClickOutside={true}>
          <ModalHeader>Upload Shopping List</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-2">
              <div className="p-3 bd-highlight">
                <input
                  ref={fileInput}
                  placeholder="Please Upload Shopping List Over Here"
                  type="file"
                  accept=".xlsx,.csv"
                  onChange={handleChange}
                  // style={styles.input}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              // onClick={() => {
              //   return submitForm();
              // }}
              disabled={!file}
              onClick={() => triggerInputFile()}
              color="success"
              className="place-self-center col-start-2"
            >
              Upload
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default ShoppingListAdd;
