import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import useToggle from "../../packages/_utils/useToggle";
import Button from "../../packages/button/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../packages/modal/Modal";

import { getContacts, updateContact, createContact, deleteContact } from "../../store/contactsActions";

import { updateObject } from "../../utils/utility";
import Icon from "../../components/ui/Icon";

import { Role } from "../../data/roles";
import SearchInput from "../../components/ui/SearchInput";
import { tableStyles } from "./Settings";

const initialContact = {
  id: 0,
  username: "",
  first_name: "",
  last_name: "",
  email: "",
};

const Contacts = () => {
  const dispatch = useDispatch();
  const [toggle, isOpen] = useToggle();
  const currentUser = useSelector((state) => state.profile.profilesData);
  const contacts = useSelector((state) => state.contacts.data);
  const [selectedContact, setSelectedContact] = useState(initialContact);
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState(contacts);

  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    dispatch(getContacts());
  }, [dispatch]);

  const addClick = () => {
    setModalTitle("Create New Contact");
    toggle();
    setSelectedContact(initialContact);
  };

  const editClick = (contact) => {
    setModalTitle("Edit Contact");
    setSelectedContact(contact);
    toggle();
  };

  const createClick = () => {
    dispatch(createContact(selectedContact));
    toggle();
    setSearchValue(selectedContact.email);
  };

  const updateClick = () => {
    dispatch(updateContact(selectedContact));
    toggle();
    setSearchValue(selectedContact.email);
  };

  const deleteClick = (id) => {
    dispatch(deleteContact(id));
  };

  const onChangeHandler = (e, field) => {
    let updatedContact = updateObject(selectedContact, {
      [field]: e.target.value,
    });
    setSelectedContact(updatedContact);
  };

  const searchClickHandler = (e) => {};

  const searchChangeHandler = (e) => {
    setSearchValue(e);
    setFilteredList(filterList(e));
  };

  //#region filter
  const filterList = useCallback(
    (value) => {
      if (!value) return contacts;
      return contacts.filter(
        (c) =>
          c.username?.toLowerCase().includes(value.toLowerCase()) ||
          c.email?.toLowerCase().includes(value.toLowerCase()) ||
          c.first_name?.toLowerCase().includes(value.toLowerCase()) ||
          c.last_name?.toLowerCase().includes(value.toLowerCase())
      );
    },
    [contacts]
  );

  useEffect(() => {
    setFilteredList(filterList(searchValue));
  }, [filterList, searchValue]);
  //#endregion

  return (
    <div className="">
      <div className="flex fle justify-between items-center">
        <div className="flex">
          <SearchInput
            label={"Filter"}
            // icon={<SearchIcon />}
            className="w-64 shadow-sm ml-4 dark:text-white"
            iconPosition="right"
            onClick={searchClickHandler}
            onChange={searchChangeHandler}
            value={searchValue}
            placeholder={`Start typing to filter list`}
          />
        </div>
        <p className="text-left w-full pl-4">{`${filteredList?.length} Contact${filteredList?.length > 1 ? "s" : ""} ${
          filteredList.length < contacts.length ? "Filtered" : ""
        }`}</p>
        {currentUser.role !== Role.view && (
          <div className="flex-none">
            <Button onClick={addClick} color="primary">
              Add New Contact
            </Button>
          </div>
        )}
      </div>

      <table className={tableStyles.table}>
        <thead className={tableStyles.thead.base}>
          <tr>
            <th>User Name</th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            {currentUser.role !== Role.view && <th className={tableStyles.thead.th.actions}>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {filteredList &&
            filteredList.map((contact, i) => (
              <tr key={i} className={tableStyles.tbody.tr}>
                <td>{contact.username}</td>
                <td>{contact.email}</td>
                <td>{contact.first_name}</td>
                <td>{contact.last_name}</td>
                {currentUser.role !== Role.view && (
                  <td className={tableStyles.tbody.td.actions}>
                    <Button color="primary" className="btn mr-1 " size="sm" onClick={() => editClick(contact)}>
                      <Icon iconName="Pencil" />
                    </Button>
                    <Button color="danger" className="btn mr-1" size="sm" onClick={() => deleteClick(contact.id)}>
                      <Icon iconName="Trash" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 w-96 bd-highlight">
              <div className="input-group mb-3 grid grid-cols-3">
                <span className="input-group-text">Email</span>
                <input
                  type="text"
                  className="form-control !w-full col-span-2"
                  value={selectedContact.email ?? ""}
                  onChange={(e) => onChangeHandler(e, "email")}
                />
              </div>
              <div className="input-group mb-3 grid grid-cols-3">
                <span className="input-group-text">User Name</span>
                <input
                  type="text"
                  className="form-control !w-full col-span-2"
                  value={selectedContact.username ?? ""}
                  onChange={(e) => onChangeHandler(e, "username")}
                />
              </div>
              <div className="input-group mb-3 grid grid-cols-3">
                <span className="input-group-text">First Name</span>
                <input
                  type="text"
                  className="form-control !w-full col-span-2"
                  value={selectedContact.first_name ?? ""}
                  onChange={(e) => onChangeHandler(e, "first_name")}
                />
              </div>

              <div className="input-group mb-3 grid grid-cols-3">
                <span className="input-group-text">Last Name</span>
                <input
                  type="text"
                  className="form-control !w-full col-span-2"
                  value={selectedContact.last_name ?? ""}
                  onChange={(e) => onChangeHandler(e, "last_name")}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
          {selectedContact.id === 0 ? (
            <Button onClick={createClick} color="primary" className="mr-1">
              Create
            </Button>
          ) : null}

          {selectedContact.id !== 0 ? (
            <Button onClick={updateClick} color="primary" className="mr-1">
              Update
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Contacts;
