import { createSlice } from "@reduxjs/toolkit";
import { sortAz } from '../utils/utility';

const mailingListSlice = createSlice({
  name: "mailingList",
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    replaceMailingLists(state, action) {
      state.data = action.payload;
    },
    removeMailingList(state, action) {
      const id = action.payload;
      state.changed = true;
      state.data = state.data.filter((d) => d.id !== id);
    },
    updateMailingList(state, action) {
      const data = action.payload;
      const updatedData = [...state.data.filter((d) => d.id !== data.id), data];
      state.data = updatedData.sort(sortAz);
    },
  },
});

export const mailingListActions = mailingListSlice.actions;

export default mailingListSlice;
