import { cloneElement } from "react";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  AdjustmentsIcon,
  CashIcon,
  ChartBarIcon,
  ChartPieIcon,
  CogIcon,
  ColorSwatchIcon,
  HomeIcon,
  MoonIcon,
  PencilIcon,
  SunIcon,
  TableIcon,
  TrashIcon,
  UserIcon,
  XIcon,
  SwitchVerticalIcon,
  UploadIcon,
  MailIcon,
  ViewGridIcon,
  DocumentAddIcon,
  ArchiveIcon,
  HashtagIcon,
  GlobeAltIcon,
} from "@heroicons/react/solid";

const Icon = ({ iconName, className }) => {
  let icon;

  switch (iconName) {
    case "Home":
      icon = <HomeIcon />;
      break;
    case "ChartPie":
      icon = <ChartPieIcon />;
      break;
    case "ChartBar":
      icon = <ChartBarIcon />;
      break;
    case "Form":
    case "Table":
      icon = <TableIcon />;
      break;
    case "User":
      icon = <UserIcon />;
      break;
    case "Cog":
      icon = <CogIcon />;
      break;
    case "Upload":
      icon = <UploadIcon />;
      break;
    case "Archive":
      icon = <ArchiveIcon />;
      break;
    case "Sun":
      icon = <SunIcon />;
      break;
    case "HashTag":
      icon = <HashtagIcon />;
      break;
    case "Mail":
      icon = <MailIcon />;
      break;
    case "Push":
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="transparent" stroke={"currentColor"} aria-hidden="true">
          <rect x="2" y="2" width="16" height="16" rx="2" ry="2" />
          <line x1="7" y1="2" x2="7" y2="18" />
        </svg>
      );
      break;
    case "Overlay":
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="transparent" stroke={"currentColor"} aria-hidden="true">
          <rect x="2" y="2" width="16" height="16" rx="2" ry="2" />
        </svg>
      );
      break;

    case "Moon":
      icon = <MoonIcon />;
      break;
    case "ColorSwatch":
      icon = <ColorSwatchIcon />;
      break;
    case "Adjustments":
      icon = <AdjustmentsIcon />;
      break;
    case "Pencil":
      icon = <PencilIcon />;
      break;
    case "Trash":
      icon = <TrashIcon />;
      break;
    case "Cash":
      icon = <CashIcon />;
      break;
    case "ArrowDown":
      icon = <ArrowDownIcon />;
      break;
    case "ArrowUp":
      icon = <ArrowUpIcon />;
      break;
    case "SwitchVertical":
      icon = <SwitchVerticalIcon />;
      break;
    case "ViewGrid":
      icon = <ViewGridIcon />;
      break;
    case "DocumentAdd":
      icon = <DocumentAddIcon />;
      break;
    case "Globe":
      icon = <GlobeAltIcon />;
      break;
    default:
      icon = <XIcon />;
      break;
  }

  if (!className) icon = cloneElement(icon, { className: `w-4 h-4` });

  return icon;
};

export default Icon;
