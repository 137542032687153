import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import useToggle from "../../packages/_utils/useToggle";
import Button from "../../packages/button/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../packages/modal/Modal";

import {
  getUsers,
  updateUser,
  createUser,
  deleteUser,
  // deleteUser,
} from "../../store/authActions";

import { updateObject } from "../../utils/utility";
import Icon from "../../components/ui/Icon";

import { Role } from "../../data/roles";
import { tableStyles } from "./Settings";
import { FormField } from "../../packages/form/FormField";
import StatusPill from "../../components/ui/StatusPill";
import SearchInput from "../../components/ui/SearchInput";
import Toast from "../../components/ui/Toast";
import SelectInput from "../../components/ui/SelectInput";

const initialUser = {
  id: 0,
  first_name: "",
  last_name: "",
  email: "",
  role: "",
  is_verified: false,
};
const rolesArray = [
  { value: "View", label: "View" },
  { value: "User", label: "User" },
  { value: "Admin", label: "Admin" },
];
const Users = () => {
  const dispatch = useDispatch();
  const [toggle, isOpen] = useToggle();
  const currentUser = useSelector((state) => state.profile.profilesData);
  const users = useSelector((state) => state.auth.users);
  const [selectedUser, setSelectedUser] = useState({});

  const [modalTitle, setModalTitle] = useState("");
  const [statusPillOptions, setStatusPillOptions] = useState([]);

  useEffect(() => {
    if (users.length === 0 || statusPillOptions.length > 0) return;
    const roles = users.map((row) => row.role);
    const uniqueRoles = [...new Set(roles)].sort();
    setStatusPillOptions(uniqueRoles);
  }, [users, statusPillOptions]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const addClick = () => {
    setModalTitle("Create New User");
    toggle();
    setSelectedUser(initialUser);
  };

  const editClick = (user) => {
    setModalTitle("Edit User");
    setSelectedUser(user);
    toggle();
  };

  const createClick = () => {
    const emailRegex = /@partner\.jaguarlandrover\.com$|@jaguarlandrover\.com$|@unipart\.com$/;
    const checkEmail = selectedUser.email;

    if (!emailRegex.test(checkEmail)) {
      Toast({ status: "warning", message: "Invalid Email" });
      return;
    }

    if (
      selectedUser.username === "" ||
      selectedUser.first_name === "" ||
      selectedUser.last_name === "" ||
      selectedUser.email === "" ||
      selectedUser.Role === ""
    ) {
      Toast({ status: "warning", message: "All fields are mandatory" });
      return;
    }

    const existingUserName = users.find((x) => x.username === selectedUser.username);
    const existingEmail = users.find((x) => x.email === selectedUser.email);

    if (existingUserName) {
      Toast({ status: "warning", message: "Existing Username" });
      return;
    }

    if (existingEmail) {
      Toast({ status: "warning", message: "Existing Email" });
      return;
    }

    const newUser = {
      username: selectedUser.username,
      first_name: selectedUser.first_name,
      last_name: selectedUser.last_name,
      email: selectedUser.email,
      role: selectedUser.role,
      is_verified: true,
    };
    dispatch(createUser(newUser));
    toggle();
    setSearchValue(selectedUser.username);
  };

  const updateClick = () => {
    const emailRegex = /@partner\.jaguarlandrover\.com$|@jaguarlandrover\.com$|@unipart\.com$/;
    const checkEmail = selectedUser.email;

    if (!emailRegex.test(checkEmail)) {
      Toast({ status: "warning", message: "Invalid Email" });
      return;
    }

    if (
      selectedUser.username === "" ||
      selectedUser.first_name === "" ||
      selectedUser.last_name === "" ||
      selectedUser.email === "" ||
      selectedUser.Role === ""
    ) {
      Toast({ status: "warning", message: "All fields are mandatory" });
      return;
    }

    const newUser = {
      username: selectedUser.username,
      first_name: selectedUser.first_name,
      last_name: selectedUser.last_name,
      id: selectedUser.id,
      email: selectedUser.email,
      role: selectedUser.role,
      is_verified: true,
    };
    dispatch(updateUser(newUser));
    toggle();
    setSearchValue(selectedUser.username);
  };

  const onRoleChangeHandler = (value, field) => {
    let updatedRole = updateObject(selectedUser, {
      [field]: value,
    });
    setSelectedUser(updatedRole);
  };

  const onChangeHandler = (e, field) => {
    let updatedUser = updateObject(selectedUser, {
      [field]: e.target.value,
    });
    setSelectedUser(updatedUser);
  };

  //#region filter
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState(users);

  const searchClickHandler = (e) => {};

  const searchChangeHandler = (e) => {
    setSearchValue(e);
    setFilteredList(filterList(e));
  };

  const deleteClick = (id) => {
    dispatch(deleteUser(id));
  };

  const filterList = useCallback(
    (value) => {
      if (!value) return users;
      return users.filter(
        (c) =>
          c.username?.toLowerCase().includes(value.toLowerCase()) ||
          c.email?.toLowerCase().includes(value.toLowerCase()) ||
          c.first_name?.toLowerCase().includes(value.toLowerCase()) ||
          c.last_name?.toLowerCase().includes(value.toLowerCase()) ||
          c.role?.toLowerCase().includes(value.toLowerCase())
      );
    },
    [users]
  );

  useEffect(() => {
    setFilteredList(filterList(searchValue));
  }, [filterList, searchValue]);
  //#endregion

  return (
    <div className="w-4/5">
      <div className="flex fle justify-between items-center">
        <div className="flex">
          <SearchInput
            label={"Filter"}
            // icon={<SearchIcon />}
            className="w-64 shadow-sm ml-4 dark:text-white"
            iconPosition="right"
            onClick={searchClickHandler}
            onChange={searchChangeHandler}
            value={searchValue}
            placeholder={`Start typing to filter list`}
          />
        </div>
        <p className="text-left w-full pl-4">{`${filteredList.length} User${filteredList.length > 1 ? "s" : ""} ${
          filteredList.length < users.length ? "Filtered" : ""
        }`}</p>
        {currentUser.role === Role.admin && (
          <div className="flex-none">
            <Button onClick={addClick} color="primary">
              Add New User
            </Button>
          </div>
        )}
      </div>

      <table className={`${tableStyles.table}`}>
        <thead className={tableStyles.thead.base}>
          <tr>
            <th>User Name</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Active</th>
            {currentUser.role === Role.admin && <th className={tableStyles.thead.th.actions}>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {filteredList &&
            filteredList.map((user, i) => (
              <tr key={i} className={tableStyles.tbody.tr}>
                <td>{user.username}</td>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.email}</td>
                <td>
                  <StatusPill value={user.role} options={statusPillOptions} />{" "}
                </td>
                <td>
                  <FormField name="userActive" type="checkbox" disabled checked={user.is_verified} onClick={(e) => onChangeHandler(e, "is_verified")} />
                </td>
                {currentUser.role === Role.admin && (
                  <td className={tableStyles.tbody.td.actions} style={{ display: "block" }}>
                    <Button color="primary" className="btn mr-1" size="sm" onClick={() => editClick(user)}>
                      <Icon iconName="Pencil" />
                    </Button>
                    <Button color="danger" className="btn mr-1" size="sm" onClick={() => deleteClick(user.id)}>
                      <Icon iconName="Trash" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <form className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 w-96 bd-highlight">
              <div className="input-group mb-3 grid grid-cols-3">
                <span className="input-group-text">First Name</span>
                <input
                  type="text"
                  className="form-control !w-full col-span-2"
                  value={selectedUser.first_name}
                  onChange={(e) => onChangeHandler(e, "first_name")}
                />
              </div>

              <div className="input-group mb-3 grid grid-cols-3">
                <span className="input-group-text">Last Name</span>
                <input
                  type="text"
                  className="form-control !w-full col-span-2"
                  value={selectedUser.last_name}
                  onChange={(e) => onChangeHandler(e, "last_name")}
                />
              </div>
              <div className="input-group mb-3 grid grid-cols-3">
                <span className="input-group-text">Email</span>
                <input type="text" className="form-control !w-full col-span-2" value={selectedUser.email} onChange={(e) => onChangeHandler(e, "email")} />
              </div>
              <div className="input-group mb-3 grid grid-cols-3">
                <span className="input-group-text">User Name</span>
                <input
                  type="text"
                  className="form-control !w-full col-span-2"
                  value={selectedUser.username ?? ""}
                  onChange={(e) => onChangeHandler(e, "username")}
                />
              </div>
              {currentUser.role === "Admin" && (
                <div className={`flefx flexd-col w-full text-sm items-center mb-4`}>
                  <SelectInput
                    className="border rounded ml-4"
                    name={"role"}
                    value={selectedUser.role}
                    label={"Role"}
                    options={rolesArray}
                    onChangeHandler={(e) => onRoleChangeHandler(e, "role")}
                  />
                </div>
              )}
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
          {selectedUser.id === 0 ? (
            <Button onClick={createClick} color="primary" className="mr-1">
              Create
            </Button>
          ) : null}

          {selectedUser.id !== 0 ? (
            <Button onClick={updateClick} color="primary" className="mr-1">
              Update
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Users;
