import { useSelector } from "react-redux";

import externalLinks from "../../data/externalLinks";
import Icon from "../../components/ui/Icon";
import Toast from "../../components/ui/Toast";

const style = {
  sideNav: {
    button:
      "focus:outline-none flex px-6 m-auto items-center justify-center text-typography-1 focus:bg-primary focus:text-secondary hover:bg-primary bg-primary/60 rounded-lg text-secondary transition duration-300 ease-in-out relative",
    icon: `fill-stroke flex flex-none h-full w-16 items-center p-3`,
    text: `flex w-full h-full text-base items-center p-2 whitespace-nowrap`,
  },
  profile: {
    text: "flex-none cursor-pointer text-typography-2 hover:bg-tertiary/60 w-full h-10 w-10 md:p-2 transition-colors duration-300 ease-in-out",
  },
};

const Links = () => {
  // const profileData = useSelector((state) => state.profile.profilesData);
  // if (!profileData.is_active) {
  //   return <Navigate to="/unauthorised" state={{ from: "/" }} />;
  // }

  const user = useSelector((state) => state.profile.profilesData);

  // eslint-disable-next-line no-unused-vars
  const downloadEpc = () => {
    Toast({ status: "success", message: "EPC Downloaded..!" });
  };

  const externalLinkOptions = (group, i) => {
    const items = externalLinks.filter((m) => m.group === group);
    const groupedItems = [];

    for (let i = 0; i < items.length; i += 3) {
      groupedItems.push(items.slice(i, i + 3));
    }

    return groupedItems.map((group, index) => (
      <div key={index} className="grid gdrid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4 my-4 h-30 max-h-[140px] overflow-hidden xl:max-w-nodne dmax-w-fit">
        {group.map((option, i) => {
          let iconName = option.icon;
          let labelName = option.label;

          const content = (
            <div className="w-full col-span-1" key={i}>
              <div className="shadow-md pb-2 w-full bg-secondary relative h-full">
                <p className="h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary">{option.label}</p>
                <div className="flex space-x-2 p-2  items-center justify-center">
                  <button
                    tabIndex={0}
                    key={i}
                    className={style.sideNav.button}
                    onClick={
                      option.action
                        ? () => {
                            eval(option.action).call(); // eslint-disable-line no-eval
                          }
                        : null
                    }
                  >
                    <div className={`${style.sideNav.icon} !h-6 `}>
                      <Icon iconName={iconName} />
                    </div>
                    <p className={style.sideNav.text}>{labelName}</p>
                  </button>
                </div>
              </div>
            </div>
          );

          if (!option.to) return content;

          return (
            <div className="w-full col-span-1" key={i}>
              <a href={option.to} target="blank">
                <div className="shadow-md pb-6 w-full bg-secondary relative h-full">
                  <p className="h5 w-full text-typography-2 p-3 px-4 font-semibold bg-primary">{option.label}</p>
                  <div className="flex w-full space-x-2 px-4 items-center justify-center ">
                    <div className={style.sideNav.icon}>
                      <Icon iconName={iconName} className="w-4 h-4" />
                    </div>
                    <p className="text-3xl text-center font-bold">Click Here</p>
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    ));
  };

  return (
    <>
      <div className="overflow-auto pdx-4 mdd:px-6 !text-typography-1 xl:Xmax-w-[calc(100%-0.5rem)] xl:madx-w-fit ">
        <h1 className="text-2xl font-semibold text-secondary-1 pb-2">Hello {user.first_name},</h1>
        <div className="flex items-center">
          <p className="text-md font-semibold text-secondary-1 pt-2">Redirect Links to other useful pages are here {">"}</p>
        </div>
        {externalLinkOptions()}
      </div>
    </>
  );
};

export default Links;
