import { useState, useEffect } from "react";
import { Formik, Field } from "formik";
import { FieldArray } from "formik";
import Button from "../../../packages/button/Button";
import { useSelector, useDispatch } from "react-redux";
import { Role } from "../../../data/roles";
import { getFormattedDate, updateObject } from "../../../utils/utility";
import { updateQuarterlyStatus } from "../../../store/eoplActions";
import moment from "moment";

const NxtQuaterReviewView = () => {
  const [nxtQuaterReviewData, setnxtQuaterReviewData] = useState([]);
  // const [countPastReviews, setCountPastReviews] = useState();
  const dispatch = useDispatch();
  const eoplProgramData = useSelector((state) => state.eopl.data);
  const currentUser = useSelector((state) => state.profile.profilesData);
  const reviewActionOptions = useSelector((state) => state.dropdown.dropdownsData?.reviewDateAction);

  useEffect(() => {
    // if (nxtQuaterReviewData.length > 0) return;
    // console.log(nxtQuaterReviewData);
    let updatedObject = eoplProgramData?.eoplNextQuarterReview?.map((v) => ({
      ...v,
      auditDateTime: getFormattedDate(v.auditDateTime),
      nextQtrReviewDate: getFormattedDate(v.nextQtrReviewDate),
      reviewDateAction: +v.reviewDateAction,
    }));
    setnxtQuaterReviewData(updatedObject);

    // console.log(updatedObject);
    // const countOfPastReviews = nxtQuaterReviewData.filter((reviewData) => {
    //   const reviewDate = moment(reviewData.nextQtrReviewDate, "DD/MM/YYYY");
    //   return reviewDate.isBefore(moment()) && !reviewDate.reviewDateStatus && reviewData.reviewDateAction === 3;
    // }).length;

    // console.log("update past review", countOfPastReviews);
    // setCountPastReviews(countOfPastReviews);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eoplProgramData]);

  const updateQuarterlyReview = (values, id) => {
    const eoplProgramStateUpdate = updateObject({ ...eoplProgramData }, { eoplNextQuarterReview: values.nextReviewData });
    let objectToSave = values.nextReviewData.find((e) => e.id === id);
    const { nextQtrReviewDate, ...nextQuarterReviewStatus } = objectToSave;
    dispatch(updateQuarterlyStatus(nextQuarterReviewStatus.id, nextQuarterReviewStatus, eoplProgramStateUpdate));
  };

  return (
    <div>
      <Formik initialValues={{ nextReviewData: nxtQuaterReviewData }} enableReinitialize={true}>
        {({ values }) => {
          const countPastReviews = nxtQuaterReviewData.filter((reviewData) => {
            const reviewDate = moment(reviewData.nextQtrReviewDate, "DD/MM/YYYY");
            return reviewDate.isBefore(moment()) && !reviewDate.reviewDateStatus && +reviewData.reviewDateAction === 3;
          }).length;
          // console.log(countPastReviews);

          return (
            <>
              <div className="pb-4">
                {countPastReviews > 0 && <p className="font-bold text-danger">There are {countPastReviews} quarterly reviews that have lapsed.</p>}
                <div className="border-2 border-tertiary-warm-6 whitespace-nowrap overflow-y-scroll mt-2 border-box pb-2">
                  <FieldArray name="nextReviewData">
                    {() => (
                      <div>
                        <div style={{ display: "block", maxHeight: "260px" }}>
                          <table className="table-auto mb-6 w-full whitespace-nowrap border-x-2 border-tertiary-warm-6 rounded rounfded-tl-lg roundefd-tr-lg text-center">
                            <thead
                              className="text-white bg-primary dark:bg-secondary-3-5 dark:text-typography-1 text-sm"
                              style={{ position: "sticky", top: 0 }}
                            >
                              <tr className="hidden md:table-row">
                                <th className="py-2 pl-2 tracking-wider">Quarterly Review Date</th>
                                <th className="py-2 pl-2 tracking-wider">Last Modified Date</th>
                                <th className="py-2 pl-2 tracking-wider">Notes</th>
                                <th className="py-2 pl-2 tracking-wider w-20" colSpan={2}>
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="flex-1 md:flex-none bg-secondary-4 dark:bg-secondary-3 text-secondary-1 text-sm">
                              {values.nextReviewData.length ? (
                                values.nextReviewData.map((item, index) => {
                                  const nextReviewDate = moment(values.nextReviewData[index].nextQtrReviewDate, "DD/MM/YYYY");
                                  const isBeforeToday = nextReviewDate.isBefore(moment()) && !values.nextReviewData[index].reviewDateStatus;
                                  const isOutstanding = isBeforeToday && +values.nextReviewData[index].reviewDateAction === 3;
                                  // console.log(item);
                                  return (
                                    <tr
                                      key={index}
                                      className={`md:h-8 h-12 odd:bg-primary/30 dark:bg-secondary-2 dark:text-white text-typography-1 ${
                                        isBeforeToday && isOutstanding && "text-danger font-bold"
                                      }`}
                                    >
                                      <td className="w-48">
                                        <Field
                                          type="text"
                                          className={`text-center ${item.reviewDateStatus && `font-bold`} w-full py-1 px-2`}
                                          name={`nextReviewData.${index}.nextQtrReviewDate`}
                                          disabled={true}
                                        />
                                      </td>
                                      <td className="w-48">
                                        <Field
                                          type="text"
                                          className={`text-center ${item.reviewDateStatus && `font-bold`} w-full py-1 px-2`}
                                          name={`nextReviewData.${index}.auditDateTime`}
                                          disabled={true}
                                        />
                                      </td>
                                      <td>
                                        <Field
                                          type="text"
                                          className={`w-full py-1 px-2 bg-white/50 ${item.reviewDateStatus && `font-bold`}`}
                                          name={`nextReviewData.${index}.notes`}
                                          placeholder={"Enter notes..."}
                                          disabled={currentUser.role !== Role.view && !(item.reviewDateStatus || isBeforeToday)}
                                          value={item?.notes ?? ""}
                                        />
                                      </td>
                                      <td className="w-28">
                                        <Field
                                          className={`text-center w-full py-1 px-2 bg-white/50 ${item.reviewDateStatus && `font-bold`}`}
                                          as="select"
                                          id={`nextReviewData[${index}].reviewDateAction`}
                                          name={`nextReviewData[${index}].reviewDateAction`}
                                          value={item.reviewDateAction}
                                          disabled={currentUser.role !== Role.view && !(item.reviewDateStatus || isBeforeToday)}
                                        >
                                          {reviewActionOptions.map((element) => (
                                            <option className="text-center" key={element?.id} value={element?.id}>
                                              {element?.name}
                                            </option>
                                          ))}
                                        </Field>
                                      </td>
                                      <td className="w-28 text-center">
                                        {currentUser.role !== Role.view && (item.reviewDateStatus || isBeforeToday) && (
                                          <Button
                                            type="button"
                                            style={{ display: "flex" }}
                                            className={`place-self-center row-start-2 mx-2 ${isBeforeToday && isOutstanding ? "bg-danger" : "bg-"}`}
                                            onClick={() => updateQuarterlyReview(values, item.id)}
                                          >
                                            Update Status
                                          </Button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td>Quarterly reiew dates are not available!</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default NxtQuaterReviewView;
