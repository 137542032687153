import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'

import style from './Styles'

const Checkbox = (props) => {
  const { label, name, selected, labelClass, ...rest } = props
  return (
    <td className={`md:table-cell w-full md:w-auto flex flex-row p-0 relative border !border-secondary-3 h-10 min-w-[80px] ${!selected && 'hidden'}`} >
      <Field name={name}>
        {({ field }) => {
          return (
            <>
              <label htmlFor={name} className={`${style.tableInputlabel} ${labelClass} md:hidden`}>{label} :</label>
              <div className={'flex items-center md:justify-center w-full'}>
                <input
                  className={style.checkbox}
                  type='checkbox'
                  {...field}
                  {...rest}
                  checked={field.value ?? false}
                />
              </div>
            </>
          )
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </td >
  )
}

export default Checkbox
