import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateObject } from "../../utils/utility";
import useToggle from "../../packages/_utils/useToggle";
import { getMailingLists, createMailingList, updateMailingList, deleteMailingList } from "../../store/mailingListActions";
import Button from "../../packages/button/Button";
import Icon from "../../components/ui/Icon";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../packages/modal/Modal";
import { tableStyles } from "./Settings";
import SearchInput from "../../components/ui/SearchInput";
import SelectInput from "../../components/ui/SelectInput";

import { Role } from "../../data/roles";

const initialMailingList = {
  id: 0,
  name: "",
};

const MailingList = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.profile.profilesData);

  const mailingLists = useSelector((state) => state.mailingList.data);
  const [searchValue, setSearchValue] = useState("");
  const [filteredList, setFilteredList] = useState(mailingLists);

  const contacts = useSelector((state) => state.contacts.data);
  const [contactsOptionList, setContactsOptionList] = useState();

  const mailingGroups = useSelector((state) => state.mailingGroup.data);
  const [mailingGroupsOptionList, setMailingGroupsOptionList] = useState();

  useEffect(() => {
    dispatch(getMailingLists());
  }, [dispatch]);

  var tempData = JSON.parse(JSON.stringify(mailingLists));
  Object.preventExtensions(tempData);

  if (contacts.length > 0 && mailingGroups.length > 0) {
    for (let index = 0; index < tempData.length; index++) {
      const element = tempData[index];
      tempData[index].username = contacts.find((x) => x.id === element.contactId).username;
      tempData[index].email = contacts.find((x) => x.id === element.contactId).email;
      tempData[index].first_name = contacts.find((x) => x.id === element.contactId).first_name;
      tempData[index].last_name = contacts.find((x) => x.id === element.contactId).last_name;
      tempData[index].mailingGroupName = mailingGroups.find((x) => x.id === element.mailingGroupId).name;
    }
  }

  const filterList = useCallback(
    (value) => {
      if (!value) return mailingLists;
      return mailingLists.filter(
        (c) =>
          c.username?.toLowerCase().includes(value.toLowerCase()) ||
          c.email?.toLowerCase().includes(value.toLowerCase()) ||
          c.first_name?.toLowerCase().includes(value.toLowerCase()) ||
          c.last_name?.toLowerCase().includes(value.toLowerCase())
      );
    },
    [mailingLists]
  );

  useEffect(() => {
    setFilteredList(filterList(searchValue));
  }, [filterList, searchValue]);

  useEffect(() => {
    const options = contacts.map((option, i) => {
      return { value: option.id, label: option.email };
    });
    setContactsOptionList(options);
  }, [contacts]);

  useEffect(() => {
    const options = mailingGroups.map((option, i) => {
      return { value: option.id, label: option.name };
    });
    setMailingGroupsOptionList(options);
  }, [mailingGroups]);

  const [toggle, isOpen] = useToggle();
  const [modalTitle, setModalTitle] = useState("");
  const [selectedMailingList, setSelectedMailingList] = useState(initialMailingList);

  const addClick = () => {
    setModalTitle("Add Contact to Mailing List");
    toggle();
    setSelectedMailingList(initialMailingList);
  };

  const createClick = () => {
    const mailingList = {
      contactId: selectedMailingList["contactId"],
      mailingGroupId: selectedMailingList["mailingGroupId"],
    };
    dispatch(createMailingList(mailingList));
    setSearchValue(contacts.find((c) => c.id === mailingList.contactId).username);
    toggle();
  };

  const updateClick = () => {
    const mailingList = {
      id: selectedMailingList["id"],
      contactId: selectedMailingList["contactId"],
      mailingGroupId: selectedMailingList["mailingGroupId"],
    };
    dispatch(updateMailingList(mailingList));
    setSearchValue(contacts.find((c) => c.id === mailingList.contactId).username);
    toggle();
  };

  const editClick = (mailingList) => {
    setModalTitle("Edit Mailing List");
    setSelectedMailingList(mailingList);
    toggle();
  };

  const deleteClick = (id) => {
    dispatch(deleteMailingList(id));
  };

  const onChangeHandler = (value, field) => {
    let updatedMailingList = updateObject(selectedMailingList, {
      [field]: value,
    });
    setSelectedMailingList(updatedMailingList);
  };

  const searchChangeHandler = (e) => {
    setSearchValue(e);
    setFilteredList(filterList(e));
  };

  return (
    <div className="">
      <div className="flex fle justify-between items-center">
        <div className="flex">
          <SearchInput
            label={"Filter"}
            // icon={<SearchIcon />}
            className="w-64 shadow-sm ml-4 dark:text-white"
            iconPosition="right"
            // onClick={searchClickHandler}
            onChange={searchChangeHandler}
            value={searchValue}
            placeholder={`Start typing to filter list`}
          />
        </div>
        <p className="text-left w-full pl-4">{`${tempData.length} Contact${tempData.length > 1 ? "s" : ""} ${
          tempData.length < contacts.length ? "Filtered" : ""
        }`}</p>
        {currentUser.role !== Role.view && (
          <div className="flex-none">
            <Button onClick={addClick} color="primary">
              Add to Mailing List
            </Button>
          </div>
        )}
      </div>

      <table className={`${tableStyles.table}`}>
        <thead className={tableStyles.thead.base}>
          <tr>
            <th>Id</th>
            <th>User Name</th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Mailing List</th>
            {currentUser.role !== Role.view && <th className={tableStyles.thead.th.actions}>Actions</th>}
          </tr>
        </thead>
        <tbody>
          {tempData &&
            tempData.map((mailingList, i) => (
              <tr key={i} className={tableStyles.tbody.tr}>
                <td>{mailingList.id}</td>
                <td>{mailingList.username}</td>
                <td>{mailingList.email}</td>
                <td>{mailingList.first_name}</td>
                <td>{mailingList.last_name}</td>
                <td>{mailingList.mailingGroupName}</td>
                {currentUser.role !== Role.view && (
                  <td className={tableStyles.tbody.td.actions}>
                    <Button color="primary" className="btn mr-1" size="sm" onClick={() => editClick(mailingList)}>
                      <Icon iconName="Pencil" />
                    </Button>
                    <Button color="danger" className="btn mr-1" size="sm" onClick={() => deleteClick(mailingList.id)}>
                      <Icon iconName="Trash" />
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>

      <Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 w-full bd-highlight">
              <div className="input-group mb-3">
                {/* <span className="input-group-text">Mailing List</span>
                <input
                  type="text"
                  className="form-control"
                  value={selectedMailingList.name}
                  onChange={(e) => onChangeHandler(e, "name")}
                /> */}
                <div className={`flefx flexd-col w-full text-sm items-center mb-4`}>
                  <SelectInput
                    className="border rounded ml-4"
                    name={"contacts"}
                    value={selectedMailingList.contactId}
                    label={"Contact"}
                    options={contactsOptionList}
                    onChangeHandler={(e) => onChangeHandler(e, "contactId")}
                  />
                </div>
                <div className={`flefx flexd-col w-full text-sm items-center`}>
                  <SelectInput
                    className="border rounded w-60 ml-4"
                    name={"mailingGroups"}
                    value={selectedMailingList.mailingGroupId}
                    label={"Mailing Group"}
                    options={mailingGroupsOptionList}
                    onChangeHandler={(e) => onChangeHandler(e, "mailingGroupId")}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger">
            Cancel
          </Button>
          {selectedMailingList.id === 0 ? (
            <Button onClick={createClick} color="primary" className="mr-1">
              Add
            </Button>
          ) : null}

          {selectedMailingList.id !== 0 ? (
            <Button onClick={updateClick} color="primary" className="mr-1">
              Update
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MailingList;
