import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
// import toast from "react-hot-toast";
import { useTable, useFilters, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from "react-table"; // new

import Tippy from "@tippyjs/react";
import fileDownload from "js-file-download";
/* Extract Data */
import { useExportData } from "react-table-plugins";
import Papa from "papaparse";
import XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
/* Extract Data */

import style from "./Formik/Controls/Styles";

import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon, SearchIcon, DownloadIcon, XIcon } from "@heroicons/react/solid";

import { classNames } from "../../utils/utility";
import Icon from "./Icon";
import SearchInput from "./SearchInput";
import SelectInput from "./SelectInput";
import Button from "../../packages/button/Button";
import { setSearchValue, setFilters, setGblFilter } from "../../store/eoplActions";
import { useDispatch, useSelector } from "react-redux";

export function AvatarCell({ value, column, row }) {
  return (
    <div className="flex items-center">
      <div className="flex-shrink-0 h-10 w-10">
        <img className="h-10 w-10 rounded-full" src={row.original[column.imgAccessor]} alt="" />
      </div>
      <div className="ml-4">
        <div className="text-sm font-medium">{value}</div>
        <div className="text-sm">{row.original[column.emailAccessor]}</div>
      </div>
    </div>
  );
}

AvatarCell.propTypes = {
  value: PropTypes.string,
  column: PropTypes.object,
  row: PropTypes.object,
};

// const options = [
//   '', 'v1', 'v2', 'v3', 'v4', 'v5', 'v6', 'v7', 'v8', 'v9', 'v10'
// ]

export function StatusPillVersion({ value }) {
  const status = value ? "v" + value.toString().toLowerCase() : "unknown";

  let optionClass;
  let idx = status.charAt(status.length - 1);

  switch (idx) {
    case "0":
      optionClass = "bg-tertiary/60   text-typography-2";
      break;
    case "1":
      optionClass = "bg-tertiary-warm-1   text-typography-2";
      break;
    case "2":
      optionClass = "bg-tertiary-cool-1-5   text-typography-2";
      break;
    case "3":
      optionClass = "bg-tertiary-warm-2   text-typography-2";
      break;
    case "4":
      optionClass = "bg-tertiary-cool-3   text-typography-1";
      break;
    case "5":
      optionClass = "bg-tertiary-warm-3   text-typography-1";
      break;
    case "6":
      optionClass = "bg-primary/30   text-typography-1";
      break;
    case "7":
      optionClass = "bg-tertiary-warm-4   text-typography-1";
      break;
    case "8":
      optionClass = "bg-tertiary/60-5 text-typography-2";
      break;
    case "9":
      optionClass = "bg-tertiary-warm-1-5 text-typography-2";
      break;
    default:
      optionClass = "bg-primary/30  text-typography-2";
      break;
  }

  if (status === "unknown") return "";

  return <span className={classNames("px-2.5 py-1 leading-wide font-semibold text-xs rounded-full shadow-sm", optionClass)}>{status}</span>;
}

StatusPillVersion.propTypes = {
  value: PropTypes.any,
};

export const StatusPillInScope = ({ value }) => {
  let status = value ? value.toString().toUpperCase() : "unknown";

  let optionClass;
  switch (status) {
    case "YES":
    case "OPEN":
      optionClass = "bg-success/80   text-white";
      break;
    case "NO":
    case "CANCELLED":
      optionClass = "bg-danger/80   text-white";
      break;
    case "TBC":
      optionClass = "bg-warning/80  text-black";
      break;
    case "CLOSED":
      optionClass = "bg-info/80   text-white";
      break;
    default:
      optionClass = "bg-dark  text-typography-1";
      break;
  }

  if (status === "unknown") return "";
  if (status === "NOT APPLICABLE") status = "N/A";

  return <span className={classNames("px-2.5 py-1 leading-wide font-semibold text-xs rounded-full shadow-sm whitespace-nowrap", optionClass)}>{status}</span>;
};

StatusPillInScope.propTypes = {
  value: PropTypes.any,
};

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, initalSearch }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(initalSearch);
  const onChange = useAsyncDebounce((value) => {
    dispatch(setGblFilter(value));
    setGlobalFilter(value || undefined);
  }, 200);

  // useEffect(() => {
  //   if (initalSearch) {
  //     const count = preGlobalFilteredRows.filter(p => p.values.spo === initalSearch).length;
  //     if (count > 0) {
  //       setValue(initalSearch);
  //       onChange(initalSearch);
  //     }
  //     else {
  //       setValue();
  //       onChange();
  //       dispatch(setSearchValue())
  //     }
  //   }
  // }, [dispatch, initalSearch, onChange, preGlobalFilteredRows])
  useEffect(() => {
    if (initalSearch && initalSearch !== "REMOVE") {
      // get a count of the rows where the spo is matched
      const count = preGlobalFilteredRows.filter((p) => p.values.spo === initalSearch).length;

      // get a count of the rows where anything is matched.
      const gblCount = preGlobalFilteredRows.filter((p) =>
        Object.values(p.values).some((value) => typeof value === "string" && value.toLowerCase().includes(initalSearch.toLowerCase()))
      ).length;

      if (count > 0) {
        setValue(initalSearch);
        onChange(initalSearch);
      } else if (gblCount > 0) {
        setValue(initalSearch);
        onChange(initalSearch);
      } else {
        // This will clear the search value if a match isn't found.
        // setValue();
        // onChange();
        // dispatch(setSearchValue());
      }
    } else if (initalSearch === "REMOVE") {
      setValue();
      onChange();
      dispatch(setGblFilter());
    }
  }, [dispatch, initalSearch, onChange, preGlobalFilteredRows, globalFilter]);

  const searchHandler = (value) => {
    setValue(value);
    onChange(value);
    if (value === "") {
      dispatch(setSearchValue());
      dispatch(setGblFilter());
    }
  };

  return (
    <div className={`flex flex-col`}>
      <SearchInput
        isSearch={true}
        name="Search"
        label="Search"
        icon={<SearchIcon />}
        className="w-40 shadow-sm"
        iconPosition="right"
        onClick={(e) => searchHandler(e)}
        onChange={(e) => searchHandler(e)}
        value={value ?? ""}
        placeholder="Search..."
      />
    </div>
  );
}

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
};

const UpdateFilters = (filters, id, value) => {
  const existingFilter = filters.find((filter) => filter.id === id);

  let updatedFilters = existingFilter
    ? [
        // Update existing filter value
        ...filters.filter((filter) => filter.id !== id),
        { ...existingFilter, value }, // Replace value with new selection
      ]
    : [
        // Add new filter
        ...filters,
        { id, value },
      ];

  updatedFilters = updatedFilters.filter((m) => m.value !== "" && m.value.length !== 0);

  return updatedFilters;
};

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id, render, filter }, initialState }) {
  const dispatch = useDispatch();
  // Calculate the options for filtering
  // using the preFilteredRows

  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]?.toString());
    });
    // return [...options.values()];

    return [...options.values()].sort((a, b) => a.localeCompare(b));
  }, [id, preFilteredRows]);

  // if (preFilteredRows.length === 0) return
  const optionList = options.map((option, i) => {
    if (!option) option = "None";
    return { value: i, label: option };
  });

  const filters = useSelector((state) => state.eopl.filters);

  const selectHandle = (id, value) => {
    const updatedFilters = UpdateFilters(filters, id, value);

    dispatch(setFilters(updatedFilters));
    setFilter(value || undefined);
  };

  // useEffect(() => {
  //   if (filterValue && filterValue.length > 0 && filterValue[0] === undefined) filterValue.shift();
  // }, [filterValue]);
  // Use initialSelected for default value
  const initialSelected = initialState?.filters?.find((f) => f.id === id)?.value;

  const initialValue = initialSelected ? initialSelected : filterValue || [];

  // const isMulti = filterOptions && filterOptions[id] ? filterOptions[id].isMulti : false || false;
  // Render a multi-select box
  return (
    <div className={`flex flex-col w-[9.5rem] text-sm`}>
      <SelectInput
        byLabel={true}
        isMulti={filter !== "includes"}
        name={id}
        value={initialValue}
        label={render("Header")}
        options={optionList}
        onChangeHandler={(e) => selectHandle(id, e)}
      />
    </div>
  );
}

SelectColumnFilter.propTypes = {
  column: PropTypes.object,
};

export const PageSizeOptions = ({ state, setPageSize }) => {
  const [value, setValue] = useState(10);

  const optionList = [5, 10, 20, 50, 100].map((option, i) => {
    return { value: option, label: `Show ${option} Rows` };
  });

  const selectHandle = (value) => {
    var result = optionList.find((option) => option.value === value);
    if (!result) {
      setValue(10);
      setPageSize(10);
      return;
    }
    setValue(result.value);
    setPageSize(result.value);
  };

  return (
    <div className={`flex flex-col w-64 text-sm`}>
      <SelectInput name="pageSize" menuPlacement="top" value={value} options={optionList} onChangeHandler={(e) => selectHandle(e)} />
    </div>
  );
};

export const DataExportOptions = ({ exportData, data }) => {
  const [value, setValue] = useState({});

  const optionList = [
    // { value: 0, type: "csv", isAll: true, label: 'Export All Data as CSV' },
    // { value: 1, type: "csv", isAll: true, label: 'Export Table as CSV' },
    // { value: 2, type: "csv", isAll: false, label: 'Export Current View as CSV' },
    { value: 0, type: "xlsx", isAll: true, label: "Export All Data as XLSX" },
    { value: 1, type: "xlsx", isAll: true, label: "Export Table as XLSX" },
    {
      value: 2,
      type: "xlsx",
      isAll: false,
      label: "Export Current View as XLSX",
    },
    // { value: 6, type: "pdf", isAll: true, label: 'Export Table as PDF' },
    // { value: 7, type: "pdf", isAll: false, label: 'Export Current View as PDF' }
  ];

  const selectHandle = (e) => {
    var result = optionList.find((option) => option.value === e);
    setValue(result);
  };

  return (
    <div className={`flex gap-x-2 text-sm`}>
      <SelectInput name="dataExport" menuPlacement="top" className="w-72" value={value?.value} options={optionList} onChangeHandler={(e) => selectHandle(e)} />
      <Button
        className="!px-2"
        tippy="Download"
        color="primary"
        disabled={!value?.label}
        onClick={() => {
          if (value.value === 0) getAllExportFileBlob(data);
          else exportData(value.type, value.isAll);
        }}
      >
        <DownloadIcon className="h-4 w-4" />
      </Button>
    </div>
  );
};

function getAllExportFileBlob(data) {
  // CSV example
  const csvString = Papa.unparse({ data });
  const blob = new Blob([csvString], { type: "text/csv" });
  fileDownload(blob, `eopl-data.csv`);
}

function getExportFileBlob({ columns, data, fileType, fileName }) {
  if (fileType === "csv") {
    // CSV example
    const headerNames = columns.map((col) => col.exportValue);
    const csvString = Papa.unparse({ fields: headerNames, data });
    return new Blob([csvString], { type: "text/csv" });
  } else if (fileType === "xlsx") {
    // XLSX example

    const header = columns.map((c) => c.exportValue);
    const compatibleData = data.map((row) => {
      const obj = {};
      header.forEach((col, index) => {
        obj[col] = row[index];
      });
      return obj;
    });

    let wb = XLSX.utils.book_new();
    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
      header,
    });
    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);

    // Returning false as downloading of file is already taken care of
    return false;
  }
  //PDF example
  if (fileType === "pdf") {
    const headerNames = columns.map((column) => column.exportValue);
    const doc = new JsPDF("landscape");
    doc.autoTable({
      head: [headerNames],
      body: data,
      margin: { top: 10, left: 5, right: 5, bottom: 10 },
      styles: {
        minCellHeight: 10,
        halign: "left",
        valign: "center",
        fontSize: 10,
      },
    });
    doc.save(`${fileName}.pdf`);

    return false;
  }

  // Other formats goes here
  return false;
}

export function DateRangeColumnFilter({ column: { filterValue = [], preFilteredRows, setFilter, id, Header } }) {
  const [min, max] = useMemo(() => {
    let min = new Date();
    let max = new Date();

    if (preFilteredRows.length > 0) {
      preFilteredRows.forEach((row) => {
        const rowDate = new Date(row.values[id]);
        min = rowDate < min ? rowDate : min;
        max = rowDate > max ? rowDate : max;
      });
    }

    return [min, max];
  }, [id, preFilteredRows]);

  const minDate = filterValue[0] || min.toISOString().slice(0, 10);
  const maxDate = filterValue[1] || max.toISOString().slice(0, 10);

  const [selectedMin, setSelectedMin] = useState(minDate);
  const [selectedMax, setSelectedMax] = useState(maxDate);

  const handleMinChange = (event) => {
    const newMin = event.target.value;
    setSelectedMin(newMin);
    setSelectedMax(selectedMax);
    setFilter([newMin, selectedMax]);
  };

  const handleMaxChange = (event) => {
    const newMax = event.target.value;
    setSelectedMax(newMax);
    setSelectedMin(selectedMin);
    setFilter([selectedMin, newMax]);
  };

  return (
    <div className="flex">
      <div>
        <label className={style.label}>{Header} From</label>
        <input
          className={`${style.input} !w-auto`}
          min={min.toISOString().slice(0, 10)}
          max={max.toISOString().slice(0, 10)}
          onChange={handleMinChange}
          type="date"
          value={minDate}
        />
      </div>
      <div>
        <label className={style.label}>{Header} To</label>
        <input
          className={style.input}
          min={min.toISOString().slice(0, 10)}
          max={max.toISOString().slice(0, 10)}
          onChange={handleMaxChange}
          type="date"
          value={maxDate}
        />
      </div>
    </div>
  );
}

export function dateBetweenFilterFn(rows, id, filterValues) {
  const sd = filterValues[0] ? new Date(filterValues[0]) : undefined;
  const ed = filterValues[1] ? new Date(filterValues[1]) : undefined;
  if (ed || sd) {
    return rows.filter((r) => {
      let cellDate = new Date(r.values[id] + "Z");

      if (ed && sd) {
        return cellDate.valueOf() >= sd.valueOf() && cellDate.valueOf() <= ed.valueOf();
      } else if (sd) {
        return cellDate.valueOf() === sd.valueOf();
      } else {
        return cellDate.valueOf() === ed.valueOf();
      }
    });
  } else {
    return rows;
  }
}

// Custom filter function for exact match with multiple selections
export function exactMatchMultiSelectFilter(rows, id, filterValues) {
  let filterValuesArray = Array.isArray(filterValues) ? filterValues : [filterValues]; // Ensure filterValues is an array

  if (!filterValuesArray || filterValuesArray.length === 0) {
    return rows;
  }

  return rows.filter((row) => {
    const rowValue = row.values[id] ?? "None";
    if (!filterValuesArray[0]) {
      return filterValuesArray.some((filterValue) => rowValue === filterValue || rowValue === null);
    } else {
      return filterValuesArray.some((filterValue) => rowValue === filterValue);
    }
  });
}

function Table({ columns, data, filterValue, title, getByPartNumber, filters }) {
  // const defaultColumn = useMemo(
  //   () => ({
  //     width: 200,
  //     minWidth: 50,
  //     maxWidth: 100
  //   }),
  //   []
  // );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    // pageOptions,
    rows,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    exportData,
    setAllFilters,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        filters: filters,
        // globalFilter: 'comp',
      },
      // defaultColumn,
      getExportFileBlob,
      dateBetweenFilterFn,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useExportData
  );

  const { globalFilter } = state;
  // Function to set filters based on filters array

  const applyFilters = () => {
    const allFilters = filters
      .filter((m) => m.id !== "spo")
      .map((filter) => ({
        id: filter.id,
        value: filter.value,
      }));
    setAllFilters(allFilters);
  };

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue]);

  const dispatch = useDispatch();

  const renderedHeaders = useMemo(() => {
    return headerGroups.map((headerGroup) => headerGroup.headers.map((column, i) => (column.Filter ? <div key={i}>{column.render("Filter")}</div> : null)));
  }, [headerGroups]);

  const { pageIndex } = state;

  let pageInfo = "No records";
  let recordInfo = "";

  if (rows.length > 0) {
    pageInfo = `Page: ${pageIndex + 1} of ${pageCount}`;

    recordInfo = `Record Count: ${rows.length}`;
    if (rows.length < preGlobalFilteredRows.length) recordInfo += `  of ${preGlobalFilteredRows.length}`;
  }

  const [partNumberSearch, setPartNumberSearch] = useState("");
  const [partNumberType, setPartNumberType] = useState("epn");

  const handleRadioChange = (value) => {
    setPartNumberType(value);
  };

  const searchClickHandler = (e) => {
    let serachobject = {
      partType: partNumberType,
      value: e,
    };
    getByPartNumber(serachobject);
  };

  const searchChangeHandler = (e) => {
    setPartNumberSearch(e);
    if (e === "") getByPartNumber(e);
  };

  // Render the UI for your table
  return (
    <>
      <div className="flex gap-4 text-typography-1 font-bold pb-2 mb-2 items-center justify-between border-b border-secondary-1">
        <p className="flex-none text-lg font-bold md:text-2xl">{title}</p>
        <SearchInput
          label={"Part Number"}
          icon={<SearchIcon />}
          className="shadow-sm -ml-4 dark:text-white font-normal"
          iconPosition="right"
          onClick={searchClickHandler}
          onChange={searchChangeHandler}
          value={partNumberSearch}
          isSearch={true}
          placeholder={`Enter Part No.`}
        />
        <input type="radio" id="epn" value="epn" checked={partNumberType === "epn"} onChange={() => handleRadioChange("epn")} />
        <label htmlFor="epn">EPN</label>
        <input type="radio" id="apn" value="apn" checked={partNumberType === "apn"} onChange={() => handleRadioChange("apn")} />
        <label htmlFor="apn">APN</label>
        <div className="text-center">
          <p className={`text-xs m-0 ${filters.length > 0 || globalFilter ? "text-typography-1" : "text-typography-1/40"}`}>Clear Filters</p>
          <Tippy arrow={true} content={<span className="bg-secondary-2 text-sm rounded px-2 py-1 shadow text-white">Remove all filters</span>}>
            <Button
              className="!px-2 m-0 !text-xs bg-primary dark:bg-secondary-4 dark-text-typography-1"
              tippy="Download"
              disabled={filters.length === 0 && !globalFilter}
              onClick={() => {
                setAllFilters([]);
                dispatch(setFilters());
                dispatch(setSearchValue());
                setGlobalFilter();
                dispatch(setGblFilter("REMOVE"));
              }}
            >
              <XIcon className="w-14 h-3 dark:text-typography-1" aria-hidden="true" />
            </Button>
          </Tippy>
        </div>
        <p className="flex-1 text-center">{pageInfo}</p>
        <p className="flex-none">{recordInfo}</p>
      </div>

      <div className="sm:flex gap-x-2 sm:max-w-none max-w-sm">
        <GlobalFilter preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} initalSearch={filterValue} />
        {renderedHeaders}
      </div>
      <div className="mt-2 flex flex-col sm:madx-w-none max-w-fitX">
        <div className="-my-2 overflow-x-auto -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="p-2 space-y-8 text-sm mx-auto shadow-sm overflow-hidden text-typography-1 bg-secondary sm:rounded">
              <table {...getTableProps()} className="w-full whitespace-nowrap table-fixedX">
                <thead className="flex-1 sm:flex-none">
                  {headerGroups.map((headerGroup, i) => (
                    <tr key={i} {...headerGroup.getHeaderGroupProps()} className="hidden md:table-row">
                      {headerGroup.headers.map((column, i) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          key={i}
                          scope="col"
                          className={`${
                            column.className ?? ""
                          } bg-primary border text-typography-2 !border-secondary/40 first:roundfed-tl-lg last:roundefd-tr-lg group first:!text-center px-1 md:px-1 text-left text-xs font-medium uppercase tracking-wider`}
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                          <div className={`flex items-cendter justify-between`}>
                            <span className={`${column.className ?? ""} px-1 h-10 whitespace-normal flex items-center`}>{column.render("Header")}</span>
                            {/* Add a sort direction indicator */}
                            <span className="w-4 h-10 flex flex-none items-center">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <Icon iconName="ArrowDown" className="w-4 h-4 text-danger" />
                                ) : (
                                  <Icon iconName="ArrowUp" className="w-4 h-4 text-red-400" />
                                )
                              ) : (
                                <Icon iconName="SwitchVertical" className="w-4 h-4 text-gray-400 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="flex-1 sm:flex-none bg-secondary">
                  {page.map((row, i) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={`h-12 hover:bg-primary/40 odd:bg-light bg-dark text-typography-1 dark:hover:text-typography-2 group`}
                      >
                        {row.cells.map((cell, i) => {
                          return (
                            <td
                              key={i}
                              {...cell.getCellProps()}
                              className={`${cell.column.className} p-1 md:p-3 md:table-cell md:w-auto flex flex-row border !border-tertiary/40`}
                              role="cell"
                            >
                              {cell.column.Cell.name === "defaultRenderer" ? (
                                <>
                                  <div className="text-sm md:hidden pr-2 pl-1 font-bold text-typography-2 whitespace-normal">{cell.column.Header}:</div>
                                  <div className="text-sm md:pr-0 pl-1 pr-2 max-w-[500px] whitespace-normal">{cell.render("Cell")}</div>
                                </>
                              ) : (
                                <>
                                  <div className="text-sm md:hidden pr-2 pl-1 font-bold">{cell.column.Header}</div>
                                  <div className="text-sm md:pr-0 pl-1 pr-2 max-w-[500px] whitespace-normal">{cell.render("Cell")}</div>
                                </>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* Export Data / Row Display / Pagination */}
      <div className="py-3 flex items-center justify-between">
        <div className="flex-1 flex justify-between sm:hidden">
          <Button color="primary" onClick={() => previousPage()}>
            Previous
          </Button>
          <Button color="primary" onClick={() => nextPage()}>
            Next
          </Button>
        </div>
        <div className="hidden sm:flex-1 sm:flex gap-x-2 justify-end sm:items-center sm:justify-between">
          <DataExportOptions exportData={exportData} data={data} />
          <div className="flex gap-x-2">
            <PageSizeOptions state={state} setPageSize={setPageSize} />
            <nav className="relative z-0 flex rounded-md shadow-sm space-x-1" aria-label="Pagination">
              <Button className="!px-2" tippy="First" color="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
              </Button>
              <Button className="!px-2" tippy="Previous" color="primary" onClick={() => previousPage()} disabled={!canPreviousPage}>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
              </Button>
              <Button className="!px-2" tippy="Next" color="primary" onClick={() => nextPage()} disabled={!canNextPage}>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </Button>
              <Button className="!px-2" tippy="Last" color="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
              </Button>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

Table.propTypes = {
  value: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  preGlobalFilteredRows: PropTypes.object,
};

export default Table;
