import { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
// import Button from '../../../packages/button/Button';

import useToggle from "../../../packages/_utils/useToggle";
import { download, getProgramById, setChanged } from "../../../store/eoplActions";
import { resetComments, toggleCommentsRefresh } from "../../../store/eoplCommentsActions";

import MainDetails from "./MainDetails";
import ShoppingListAdd from "./ShoppingListAdd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
// import { updateObject } from '../../../utils/utility';
// import { FormField } from '../../../packages/form/FormField';
import { setLoading } from "../../../store/globalActions";
import { useLocation } from "react-router-dom";
import ChildPartListAdd from "./ChildPartListAdd";
import { getShoppingList } from "../../../store/shoppingListActions";
import { getChildPartList } from "../../../store/childPartListActions";
import ProgramDetails from "./ProgramForm";

const brandOptions = [
  { value: "Jaguar", label: "Jaguar" },
  { value: "Land Rover", label: "Land Rover" },
];

const EOPLForm = (props) => {
  const { programData, flag, byId, closeDrawer, fromUpload } = props;
  const eoplIdNo = programData?.id;
  const eopl = useSelector((state) => state.eopl.data);
  const allData = useSelector((state) => state.eopl.allData);
  // const error = useSelector((state) => state.eopl.error);
  const uploaded = useSelector((state) => state.eopl.uploaded);
  // const uploading = useSelector((state) => state.eopl.uploading);
  const changed = useSelector((state) => state.eopl.changed);
  // const shoppingListChanged = useSelector((state) => state.childPart.changed)
  // const childPartListChanged = useSelector((state) => state.shopping.changed)
  // const refreshComments = useSelector((state) => state.eoplComments.refreshCommentsData);
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(0);
  // store the eopl data and update it before mapping back to the store
  const [data, setData] = useState();

  useEffect(() => {
    return () => {
      dispatch(setChanged(false));
      // dispatch(resetSpo());
      dispatch(resetComments());
    };
  }, [dispatch]);

  useEffect(() => {
    if (flag === "programList") dispatch(getProgramById(programData?.id || 0));
    return () => {};
  }, [dispatch, programData?.id, flag]);

  const getEoplData = useCallback(
    (id) => {
      // return allData.filter((m) => m.id === id)[0];
      return eopl;
    },
    [eopl]
  );

  useEffect(() => {
    if (!eoplIdNo) return;
    if (!fromUpload) {
      setData(getEoplData(eoplIdNo));
    }
  }, [eoplIdNo, dispatch, byId, fromUpload, getEoplData]);

  useEffect(() => {
    if ((changed || !eopl.id) && !uploaded) {
      if (allData && !allData.id && !eopl.id) return;
      setData(getEoplData(eopl.id));
      if (changed) {
        dispatch(setLoading());
        // setCurrentStep(0);
        //dispatch(setChanged(false));
      }
    }
  }, [eopl, changed, dispatch, uploaded, allData, getEoplData]);

  // useEffect(() => {
  // 	if (programById) setData(programById);
  // }, [programById]);

  // useEffect(() => {
  //   // if (data && ((data.id === eopl.id) || (data?.id && !eopl.id))) return;
  //   // if (data && ((data.id === eopl.id))) return;
  //   if ((changed || !eopl.id) && !uploaded) {
  //     if (data && !data.id && !eopl.id) return;
  //     setData(eopl);
  //     if (changed) {
  //       // if (loading && closeDrawer) closeDrawer();
  //       dispatch(setLoading());
  //       setCurrentStep(0);
  //       // dispatch(setSearchValue(eopl.eopl))
  //       //dispatch(setChanged(false));
  //     }
  //   }
  // }, [eopl, changed, dispatch, uploaded, data]);

  const makeRequest = (formData) => {
    // const stored = data;
    // setData()
    // dispatch(setSearchValue(null))
    const isUpload = location.pathname === "/eopl/upload";
    // const isAllData = location.pathname === '/eopl/alldata';

    // setTimeout(() => {
    //   if (!eopl.eopl) {
    //     setData(stored);
    //   }
    //   dispatch(setSearchValue(eopl.eopl))
    //   dispatch(setLoading());
    //   setCurrentStep(0)
    // }, [isUpload ? 2000 : 500])
  };

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    dispatch(getShoppingList(programData?.eoplProgramRefId));
    if (final) {
      toggle();
      return;
    }

    setCurrentStep((prev) => prev + 1);
  };
  const handleLastStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    dispatch(getChildPartList(programData?.eoplProgramRefId));
    if (final) {
      toggle();
      return;
    }

    setCurrentStep((prev) => prev + 2);
  };
  // const handlePrevStep = (newData) => {
  //   setData((prev) => ({ ...prev, ...newData }));
  //   setCurrentStep((prev) => prev - 1);
  // };

  const handlePrevStep = (newData) => {
    dispatch(toggleCommentsRefresh(true));
    setData(getEoplData(eoplIdNo));
    // setCurrentStep((prev) => prev - 1);
    setCurrentStep(0);
  };
  const handleFirstStep = (newData) => {
    dispatch(toggleCommentsRefresh(true));
    setData(getEoplData(eoplIdNo));
    setCurrentStep(0);
  };
  const stepTitles = ["Project Details", "Shopping List", "Child Parts List"];
  let stepArray = [];
  if (flag === "programList") {
    stepArray = [
      <MainDetails data={data} next={handleNextStep} last={handleLastStep} brandOptions={brandOptions} closeDrawer={closeDrawer} />,
      <ShoppingListAdd data={data} prev={handlePrevStep} />,
      <ChildPartListAdd data={data} prev={handleFirstStep} />,
    ];
  } else {
    stepArray = [<ProgramDetails data={data} next={handleNextStep} last={handleLastStep} brandOptions={brandOptions} closeDrawer={closeDrawer} />];
  }
  const steps = stepArray;

  const [toggle, isOpen] = useToggle();

  const excelDownloadHandler = () => dispatch(download("excel", eopl));

  return (
    <>
      {data && (
        <div className="shadow-sm overflow-hidden sm:rounded-md min-w-full max-w-fit">
          <div className="px-4 py-2 bg-primary dark:bg-secondary-3 text-left sm:px-6 rounded-t">
            <h2 className="text-white dark:text-typography-1 font-semibold">
              {stepTitles[currentStep]} &gt;{" "}
              {!eoplIdNo && !eopl?.eopl
                ? "New Programme Creation"
                : `EOPL Programme : ${!byId ? programData.eoplProgramRefId || eopl.eopl : !eopl ? "" : eopl.eopl}`}
            </h2>
          </div>

          {steps[currentStep]}
        </div>
      )}
      {/* {data?.id && (
        <div className="flex items-center py-2 text-sm font-semibold">
          <p className="pr-4 text-typography-1">Download SPO Project</p>
          <Tippy
            arrow={true}
            content={
              <span className="bg-secondary-2 text-sm rounded px-2 py-1 shadow text-white">
                Download SPO Excel File
              </span>
            }
          >
            <FontAwesomeIcon
              onClick={excelDownloadHandler}
              className="h-8 bg-white rounded cursor-pointer text-success shadow-sm shadow-black p-2"
              icon={faFileExcel}
            />
          </Tippy>
        </div>
      )} */}
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      {/* {data && isOpen && (
        <ConfirmationModal
          title="Save SPO"
          data={data}
          confirmAction={(e) => makeRequest(e)}
          isOpen={isOpen}
          toggle={() => toggle()}
          fromUpload={fromUpload}
        />
      )} */}
    </>
  );
};

export default EOPLForm;
