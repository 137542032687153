import { SearchIcon, XIcon } from "@heroicons/react/solid";
import { classNames } from '../../utils/utility';
import toast from "react-hot-toast";
import style from './Formik/Controls/Styles'

const SearchInput = (props) => {
    const { name, className, onClick, onChange, onBlur, value, iconPosition = 'left', label, icon, placeholder, isSearch, ...rest } = props

    const iconClasses = iconPosition === 'left' ? 'inset-y-0 left-0 pl-2' : 'inset-y-0 right-0 pr-2'
    const inputClasses = iconPosition === 'left' ? 'pl-9 pr-3' : 'pl-3 pr-9'

    const clearHandler = () => {
        toast.remove();
        return onChange && onChange('')
    }

    return (
        <>
            <label htmlFor={name} className={style.label}>{label}</label>
            <div className={classNames(className, 'flex relative')}>
                <input {...rest} className={classNames(inputClasses, style.input)}
                    name={name}
                    type="text"
                    placeholder={placeholder}
                    onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                            ev.preventDefault();
                            onClick(value)
                        }
                    }}
                    onChange={e => onChange && onChange(e.currentTarget.value)}
                    onBlur={onBlur}
                    value={value}
                />
                {isSearch &&
                    <span className={classNames(iconClasses, 'absolute flex items-center pointer-events-auto')}>
                        {value !== '' && <button type="button" onClick={() => clearHandler()} className='p-1 focus:outline-none focus:shadow-outline text-secondary-2 hover:bg-secondary-3 transition ease-in-out rounded-full mr-1'>
                            <XIcon className='w-6 h-6 right-3 cursor-pointer' />
                        </button>}
                        {icon && (
                            <button type="button" onClick={() => onClick(value)} className='p-1 focus:outline-none focus:shadow-outline text-secondary-2 hover:bg-secondary-3 transition ease-in-out rounded-full'>
                                <SearchIcon className='w-6 h-6 right-3 cursor-pointer' />
                            </button>
                        )}
                    </span>
                }
            </div>
        </>
    )
}

export default SearchInput