import React from "react";
import { useSelector } from "react-redux";
import { FormField } from "../../packages/form/FormField";
import useLocalStorage from "../../packages/_utils/useLocalStorage";

const PageTitle = ({ header, label, showSearch, onClick }) => {
  const [localShowSearch] = useLocalStorage("show-search");
  const eopl = useSelector((state) => state.eopl.data);

  return (
    <div className="mt-0 mb-6 text-typography-1 ">
      <h2 className="text-lg font-bold md:text-2xl flex max-w-fit">
        <div className="mr-4">
          {!eopl.id ? (
            header
          ) : (
            <>
              <span>EOPL Project : {eopl.eoplProgramRefId} </span>
              <br />
            </>
          )}
        </div>
        {localShowSearch && (
          <div className={`flex gap-x-2 text-xs items-center text-typography-1 flex-row-reversse cursor-pointer ${showSearch && "hidden"}`} onClick={onClick}>
            <FormField label={label} name="showSearch" type="checkbox" checked={showSearch} onClick={onClick} />
          </div>
        )}
      </h2>
      {eopl.name && <h5 className="h6 py-2">Project Title : {eopl.name}</h5>}
    </div>
  );
};

export default PageTitle;
