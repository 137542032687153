import { createContext, useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import useLocalStorage from "../../packages/_utils/useLocalStorage";

export const ThemeContext = createContext();

export const ThemeProvider = ({ initialTheme, children }) => {
  const [localStorageName, setLocalStorageName] = useLocalStorage("theme", "light");
  const [theme, setTheme] = useState(localStorageName);

  const checkTheme = useCallback(
    (theme) => {
      const root = window.document.documentElement;
      root.classList.remove(...root.classList);
      if (theme === "theme4") {
        root.classList.add("dark");
      }
      root.removeAttribute("data-theme");
      root.setAttribute("data-theme", theme);
      setLocalStorageName(theme);
    },
    [setLocalStorageName]
  );

  if (initialTheme) {
    checkTheme(initialTheme);
  }

  useEffect(() => {
    checkTheme(theme);
  }, [checkTheme, theme]);

  return <ThemeContext.Provider value={{ theme, setTheme }}>{children}</ThemeContext.Provider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.object,
  initialTheme: PropTypes.string,
};
