import { createSlice } from "@reduxjs/toolkit";
import { sortAz } from "../utils/utility";

export const testValues = {
  firstName: 'Abhinav',
  lastName: 'Chauhan',
  email: 'achauh13@partner.jaguarlandrover.com',
  role: 'User',
  token: null
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {},
    users: [],
    filteredUsers: [],
    loading: true,
    token: null,
    networkError: false
  },
  reducers: {
    replaceFilteredUser(state, action) {
      state.filteredUsers = action.payload;
      state.networkError = false;
    },
    replaceUser(state, action) {
      state.user = action.payload;
      // if (action.payload?.token) {
      //   state.token = action.payload.token;
      // }
      state.loading = false
      state.networkError = false;
    },
    setLoading(state) {
      state.loading = false
      state.networkError = false;
    },
    setNetworkError(state) {
      state.networkError = true
    },
    replaceUsers(state, action) {
      state.users = action.payload.users;
      state.networkError = false;
    },
    removeUser(state, action) {
      const id = action.payload.id;
      state.changed = true;
      state.users = state.users.filter((user) => user.id !== id);
      state.networkError = false;
    },
    updateUser(state, action) {
      const data = action.payload;
      const updatedData = [...state.users.filter((d) => d.id !== data.id), data];
      state.users = updatedData.sort(sortAz);
      state.networkError = false;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
