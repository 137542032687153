import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store/index";

import "./styles/Global.css";
import App from "./App";
import { ThemeProvider } from "./components/layout/ThemeProvider";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container);

/* eslint-disable-next-line no-undef*/
// const buildType = window._env_.REACT_APP_BUILD_TYPE;
const buildType = process.env.REACT_APP_BUILD_TYPE;

root.render(
  // <React.StrictMode>
  <ThemeProvider>
    <BrowserRouter>
      <Provider store={store}>
        <App buildType={buildType} tab="home" />
      </Provider>
    </BrowserRouter>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(con-sole.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
