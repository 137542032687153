import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEoplData, resetEopl } from "../../store/eoplActions";

import EOPLForm from "./form/Form";
import SearchHeader from "../../components/ui/SearchHeader";

import { Modal, ModalHeader, ModalBody, ModalFooter } from '../../packages/modal/Modal'
import useToggle from '../../packages/_utils/useToggle';
import Button from "../../packages/button/Button";

const Edit = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { eoplProgramRefId, id } = params;
  const byId = id;
  const dispatch = useDispatch();
  const eopl = useSelector((state) => state.eopl.data);
  const [eoplNo, setEoplNo] = useState(eoplProgramRefId);
  const customTypes =  ['misc. Other', 'misc. Propulsion', 'misc. BiW'];

  //#region search header
  const [showSearch, setShowSearch] = useState(!eopl?.eoplProgramRefId);
  const [autoHide, setAutoHide] = useState(true);
  const [searchValue, setSearchValue] = useState(eoplProgramRefId || (eopl?.eoplProgramRefId ?? ''));


  useEffect(() => {
    if (eopl.id) {
      dispatch(resetEopl());
    }
  },[])
  useEffect(() => {
    if (!eopl.id) {
      setSearchValue('')
    }
  }, [eopl])

  useEffect(() => {
    return () => {
      dispatch(resetEopl());
      setEoplNo(null);
    }
  }, [dispatch])

  const searchClickHandler = (e) => {
    setAutoHide(true)
    if (!e || e === '') {
      setPathName();
      dispatch(resetEopl());
      setEoplNo(null);
    }
    else {
      setPathName(e);
      setShowSearch(false);
      dispatch(getEoplData(e));
    }
  }

  const searchChangeHandler = (e) => {
    if (e === "") {
      if (eopl.id) {
        toggle();
        return;
      }
      dispatch(resetEopl());
      setEoplNo(null)
    }
    setSearchValue(e)
  }

  const setPathName = (spoNum) => {
    let pathname = location.pathname.substring(0, location.pathname.indexOf('create') + 6);
    if (spoNum)
      pathname += "/" + spoNum
    navigate(pathname, { replace: true })
  }

  useEffect(() => {
    return () => {
      dispatch(resetEopl())
    }
  }, [dispatch])

  useEffect(() => {
    if (autoHide) {
      setShowSearch(!(eopl.id && searchValue !== ''))
    }
  }, [eopl, searchValue, autoHide])
  //#endregion

  const [toggle, isOpen] = useToggle();

const confirmClick = () => {
    toggle();
    setPathName();
    dispatch(resetEopl())
    setEoplNo(null)
    setSearchValue('')
    setShowSearch(true)
  }

  return (
    <>
      <SearchHeader
        header="EOPL Programme Create/Edit"
        label="Programme Reference"
        onClick={searchClickHandler}
        onChange={searchChangeHandler}
        value={searchValue}
        isSearch={true}
        showSearch={showSearch}
        setShowSearch={e => { 
          setShowSearch(e); 
          setAutoHide(false) 
        }} />
        {
          !customTypes?.includes(eopl?.type.trim()) && eopl?.type!=="" &&
          <span style={{ color: 'red' }}>Programme's having type custom can only be edited, please search for a different Programme</span>
        }
      <EOPLForm programData={eopl} id={id} byId={byId} />
      {isOpen &&
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          animate={true}
          closeOnClickOutside={true}
        >
          <ModalHeader>Clear EoPL Project</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">
                Clear EoPL Project form and start over
                <p className="text-xs">No changes will be made to the database.</p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={toggle} color="danger">Cancel</Button>
            <Button onClick={confirmClick} color="primary" className="mr-1">
              Clear
            </Button>
          </ModalFooter>
        </Modal>
      }
    </>
  );
};

export default Edit;
