import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import SearchHeader from "../../components/ui/SearchHeader";
import IssueEmail from "./IssueEmail";
import { getEoplEmailData, resetEoplIssue, resetEopl, setEoplNumberAction } from "../../store/eoplIssueActions";
import useToggle from "../../packages/_utils/useToggle";
import Button from "../../packages/button/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../packages/modal/Modal";
import { getContacts } from "../../store/contactsActions";
import { getMailingLists } from "../../store/mailingListActions";
import { getMailingGroups } from "../../store/mailingGroupActions";
import { getUsers } from "../../store/authActions";

const Issue = () => {
  const profileData = useSelector((state) => state.profile.profilesData);
  if (!profileData.is_active) {
    //return <Navigate to='/unauthorised' state={{ from: '/' }} />;
  }

  const dispatch = useDispatch();
  const eopl = useSelector((state) => state.eoplIssue.data);
  // const allData = useSelector((state) => state.eoplRequest.copyData);
  const [toggleFlag, flag] = useToggle();

  //#region Search Header
  const [searchValue, setSearchValue] = useState(eopl?.eopl ?? "");
  const [showSearch, setShowSearch] = useState(!eopl?.eopl);
  const [autoHide, setAutoHide] = useState(showSearch);
  const [toggle, isOpen] = useToggle();
  const [eoplNo, setEoplNo] = useState();

  const checkNonExistingEopl = (eoplNo) => {
    // const existingNo = allData.find(x => x.id === Number(eoplNo));
    // if (existingNo === undefined) return true;
    // else return false;
  };
  useEffect(() => {
    dispatch(getContacts());
    dispatch(getMailingLists());
    dispatch(getMailingGroups());
    dispatch(getUsers());
  }, [dispatch]);

  const searchClickHandler = (e) => {
    setAutoHide(true);
    if (!e || e === "") {
      resetData();
    } else {
      resetData();
      if (checkNonExistingEopl(e)) {
        toggle();
        return;
      }
      dispatch(getEoplEmailData(e));
      dispatch(setEoplNumberAction(e));
      setEoplNo(e);
      if (!flag) toggleFlag();
    }
  };

  const searchChangeHandler = (e) => {
    if (!e || e === "") {
      resetData();
      if (flag) toggleFlag();
    }
    setSearchValue(e);
  };

  const resetData = useCallback(() => {
    dispatch(resetEopl());
    dispatch(resetEoplIssue());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      resetData();
    };
  }, [resetData]);

  useEffect(() => {
    if (autoHide) {
      setShowSearch(!(eopl.id && searchValue !== ""));
    }
    if (!eopl.id && !showSearch) {
      setShowSearch(true);
      setSearchValue("");
    }
  }, [eopl, searchValue, autoHide, showSearch]);
  //#endregion Search Header

  return (
    <>
      <SearchHeader
        header="EOPL Programme Details"
        label="Programme Number"
        onClick={searchClickHandler}
        onChange={searchChangeHandler}
        value={searchValue}
        isSearch={true}
        showSearch={showSearch}
        flag={flag}
        setShowSearch={(e) => {
          setShowSearch(e);
          setAutoHide(!autoHide);
          resetData();
        }}
      />
      {flag ? (
        <>
          <IssueEmail programmeId={eoplNo} />
        </>
      ) : (
        <p className="text-xl mt-4 text-typography-1">Please search for a Programme first</p>
      )}
      <Modal isOpen={isOpen} toggle={toggle} animate={true} closeOnClickOutside={true}>
        <ModalHeader>Incorrect Programme Number</ModalHeader>
        <ModalBody>
          <div className="d-flex flex-row bd-highlight mb-3">
            <div className="p-2 bd-highlight">
              <p>The Programme Number you searched for does not exist</p>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger" className="ignore-click-outside">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Issue;
