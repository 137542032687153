import { cloneElement, Children, useState } from "react";
import PropTypes from "prop-types";

const style = {
  default: `py-2 px-4 inline-block focus:outline-none cursor-pointer transition-colors duration-300 ease-in-out`,
  selected: `border-primary border-b bg-primary text-typography-2`,
  notSelected: `text-title border-b border-primary hover:bg-primary hover:text-typography-2`,
};

export const Tabs = ({ children }) => {
  const childrenArray = Children.toArray(children);
  const [current, setCurrent] = useState(childrenArray[0].key);

  const newChildren = childrenArray.map((child) => cloneElement(child, { selected: child?.key === current }));

  return (
    <nav className="w-full max-w-fit">
      {childrenArray.map((child) => (
        <div
          role="link"
          tabIndex={0}
          onClick={() => setCurrent(child?.key)}
          key={child?.key}
          className={`${style.default} ${current === child?.key ? style.selected : style.notSelected}`}
        >
          {child?.props.title}
        </div>
      ))}
      <section>{newChildren}</section>
    </nav>
  );
};

Tabs.propTypes = {
  children: PropTypes.any,
};

export const Tab = ({ children, selected }) => {
  return (
    <div hidden={!selected} className="mt-4">
      {children}
    </div>
  );
};

Tab.propTypes = {
  children: PropTypes.object,
  selected: PropTypes.bool,
};
