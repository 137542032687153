import { Fragment, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FieldArray } from "formik";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../../packages/modal/Modal";
import useToggle from "../../../packages/_utils/useToggle";
import Button from "../../../packages/button/Button";
import { shoppingListActions } from "../../../store/shoppingListSlice";
import { createShoppingList, deleteShoppingListItem, editShoppingList, getShoppingList } from "../../../store/shoppingListActions";
import { useSelector, useDispatch } from "react-redux";
import Comments from "../../../components/comments/Comments";
import { PencilIcon, TrashIcon, SaveIcon, ChatAlt2Icon, PhotographIcon } from "@heroicons/react/solid";
import { constructShoppingListObject } from "../../../utils/utility";
import { setComments } from "../../../store/eoplCommentsActions";
import { Role } from "../../../data/roles";
import Tippy from "@tippyjs/react";
import { setChanged } from "../../../store/eoplActions";
import ImageUploadModal from "../../../components/imageUploadModal";

const ShoppingList = (props) => {
  const { data } = props;

  const [shoppingData, setShoppingData] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [enableComment, setEnableComment] = useState(true);
  const [itemId, setItemId] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [toggle, isOpenModal] = useToggle();
  const [toggle1, isOpenPrompt] = useToggle();
  const [item, setItem] = useState();
  const [commentData, setComment] = useState();
  const dispatch = useDispatch();
  const shopping = useSelector((state) => state.shopping.shoppingList);
  const eoplProgramData = useSelector((state) => state.eopl.data);
  const currentUser = useSelector((state) => state.profile.profilesData);
  const [indexData, setIndexData] = useState();
  const [imageData, setImageData] = useState(null);
  const [visibleImageUpload, setVisibleImageUpload] = useState(false);

  useEffect(() => {
    setShoppingData(shopping);
  }, [shopping]);

  const handleRemove = (values) => {
    // setShoppingData(values);
    toggle();
    setItemId(values);
  };

  const saveItem = () => {
    const customShoppingListObj = constructShoppingListObject(item);
    dispatch(editShoppingList(customShoppingListObj, item.id)).then(() => dispatch(getShoppingList(item.eoplProgramId)));
    toggle1();
  };

  const removeItem = () => {
    dispatch(deleteShoppingListItem(itemId));
    toggle();
  };

  function edit(index) {
    // setIsReadOnly(!isReadOnly)
    const openState = !isOpen;
    setIsOpen(openState);

    dispatch(
      shoppingListActions.replaceShoppingListData({
        shoppingList: shopping?.map((currElement, indx) => {
          if (index === indx) {
            return { ...currElement, isSelected: !openState };
          } else {
            return { ...currElement, isSelected: true }; //equivalent to list[index]
          }
        }),
      })
    );
  }

  function comment(item, index) {
    setIndexData(item.pfApn);
    setComment(item);
    dispatch(setComments(item.comments));
    setEnableComment((enableComment) => !enableComment);
    dispatch(
      shoppingListActions.replaceShoppingListData({
        shoppingList: shopping?.map((currElement, indx) => {
          if (index === indx) {
            return { ...currElement, isSelected: !enableComment };
          } else {
            return { ...currElement, isSelected: true }; //equivalent to list[index]
          }
        }),
      })
    );
  }

  function save(item) {
    toggle1();
    setIsOpen((isOpen) => !isOpen);
    setIsReadOnly(!isReadOnly);
    setItem(item);
    dispatch(setChanged(false));
  }

  const handleSubmit = (values) => {
    let shoppingData = values.shoppingList.map((v) => ({ ...v, eoplProgramId: data.eoplProgramRefId }));
    dispatch(createShoppingList(shoppingData));
  };

  const errorField = () => {
    return (
      <Tippy arrow={true} content={<span className="bg-secondary text-sm rounded px-2 py-1 shadow text-danger">Required Field</span>}>
        <span className="text-white absolute pointer-evdents-none top-1 right-1 font-bold bg-danger rounded-full w-5 h-5 text-center">!</span>
      </Tippy>
    );
  };

  const changed = useSelector((state) => state.eopl.changed);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (isDirty && !changed) {
      dispatch(setChanged(true));
    }
  }, [isDirty, dispatch, changed]);

  const toggleImgUploadModal = (partData = null) => {
    if(partData !== null){
      setVisibleImageUpload(true);
      setImageData({...partData,type : 'shoppingList'});
      }
      else{
        setVisibleImageUpload(false);
        setImageData(null);
      }
  };

  return (
    <div>
      <Formik
        initialValues={{
          shoppingList: shoppingData,
        }}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, values, dirty }) => {
          if (dirty && !changed) {
            setTimeout(() => setIsDirty(true), 0); // Use setTimeout to defer the state update
          }

          return (
            <>
              <Form>
                <div className="!col-spfan-full whitespace-nowrap overflow-y-scroll mt-2 border-box">
                  <FieldArray name="shoppingData">
                    {() => (
                      <div>
                        <div style={{ display: "block", maxHeight: "260px" }}>
                          <table className="w-full whitespace-nowrap rounfded-tl-lg roundefd-tr-lg">
                            <thead
                              className="text-white bg-primary dark:bg-secondary-3-5 dark:text-typography-1 font-large text-center"
                              style={{ position: "sticky", top: 0 }}
                            >
                              <tr className="hidden md:table-row ">
                                <th className="py-2.5 pl-2 text-left text-xs text-center font-medium tracking-wider w-40">Actions</th>
                                <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider w-12">Version</th>
                                <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider">PF+APN</th>
                                <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider">EPN</th>
                                <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider w-10">PF</th>
                                <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider">APN</th>
                                <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider w-24">EPC</th>
                                <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider w-14">Callout</th>
                                <th className="py-2.5 pl-2 text-left text-xs text-center tracking-wider">Part Description</th>
                              </tr>
                            </thead>
                            <tbody className="flex-1 md:flex-none bg-secondary-4 text-sm">
                              {values.shoppingList.length ? (
                                values.shoppingList.map((user, index) => (
                                  <tr
                                    key={index}
                                    className={`hover:bg-primary/40 odd:bg-light bg-dark text-typography-1 dark:hover:text-typography-2 group
                                      ${!values.shoppingList[index].isSelected
                                        ? `md:h-8 h-12 odd:!bg-primary/60 !bg-primary/60 dark:bg-secondary-1 dark:text-white text-typography-1`
                                        : `md:h-8 h-12 dark:bg-secondary-2 dark:text-white text-typography-1`
                                      }
                                    `}
                                  >
                                    <td className="w-40 flex ">
                                      {currentUser.role !== Role.view && (
                                        <div className="flex w-full items-center justify-around">
                                          {!values.shoppingList[index].isSelected && (
                                            <SaveIcon
                                              className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50"
                                              onClick={() => {
                                                return save(values.shoppingList[index]);
                                              }}
                                            />
                                          )}
                                          {values.shoppingList[index].isSelected && (
                                            <TrashIcon
                                              className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50"
                                              onClick={() => handleRemove(values.shoppingList[index])}
                                            />
                                          )}
                                          {
                                            <div className="reladtive">
                                              <ChatAlt2Icon
                                                className={`h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50 ${values.shoppingList[index].comments.length > 0 && "-mt-2"
                                                  }`}
                                                onClick={() => comment(values.shoppingList[index], index)}
                                              />
                                              {values.shoppingList[index].comments.length > 0 && (
                                                <span className="absoludte text-white rounded-full w-4 h-4 text-center text-xs flex justify-center items-center p-1 bg-danger ml-5 -mt-7">
                                                  {values.shoppingList[index].comments.length}
                                                </span>
                                              )}
                                            </div>
                                          }
                                          {currentUser.role !== Role.view && (
                                            <PencilIcon
                                              className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50"
                                              onClick={() => {
                                                return edit(index);
                                              }}
                                            />
                                          )}
                                          {currentUser.role !== Role.view && (
                                            <PhotographIcon className="h-7 mr-2 cursor-pointer active:text-primary focus:text-primary hover:text-secondary group-hover:text-secondary text-primary/50"
                                              onClick={() => {
                                                toggleImgUploadModal(user)
                                              }}
                                            />
                                          )}
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      <Field
                                        type="text"
                                        className="w-14 text-center p-1"
                                        name="version"
                                        value={values?.shoppingList[index]?.version}
                                        placeholder="Version"
                                        // disabled={values.shoppingList[index].isReadOnly}
                                        disabled={true}
                                      />
                                    </td>
                                    <td>
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`shoppingList.${index}.pfApn`}
                                        placeholder="PF+APN"
                                        // disabled={values.shoppingList[index].isReadOnly}
                                        disabled={true}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.pfApn`} render={() => errorField()} />
                                    </td>
                                    <td>
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`shoppingList.${index}.epn`}
                                        placeholder="EPN"
                                        title={user.epn}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        // disabled={values.shoppingList[index].isReadOnly}
                                        disabled={true}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.epn`} render={() => errorField()} />
                                    </td>
                                    <td>
                                      <Field
                                        type="text"
                                        className="w-10 text-center p-1"
                                        name={`shoppingList.${index}.pf`}
                                        placeholder="PF"
                                        // disabled={values.shoppingList[index].isReadOnly}
                                        disabled={true}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.pf`} render={() => errorField()} />
                                    </td>
                                    <td>
                                      <a href={`${process.env.REACT_APP_EPC_URL}${values.shoppingList[index].apn}`} target="_blank" rel="noopener noreferrer">
                                        <Field
                                          type="text"
                                          className={`w-full text-center p-1 cursor-pointer outline-none font-bold text-info bg-disabled/30`}
                                          name={`shoppingList.${index}.apn`}
                                          placeholder="apn"
                                        // disabled={true}
                                        />
                                      </a>
                                      <ErrorMessage name={`childPartList.${index}.apn`} render={() => errorField()} />
                                    </td>
                                    <td>
                                      <Field
                                        type="text"
                                        className="w-full text-center p-1"
                                        name={`shoppingList.${index}.epc`}
                                        placeholder="EPC"
                                        disabled={!enableComment ? true : values.shoppingList[index].isSelected}
                                      />

                                      <ErrorMessage name={`childPartList.${index}.epc`} render={() => errorField()} />
                                    </td>
                                    <td>
                                      <Field
                                        type="text"
                                        className="w-14 text-center p-1"
                                        name={`shoppingList.${index}.callOut`}
                                        placeholder="Callout"
                                        disabled={!enableComment ? true : values.shoppingList[index].isSelected}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.callOut`} render={() => errorField()} />
                                    </td>
                                    <td>
                                      <Field
                                        type="text"
                                        className="w-full text-left p-1"
                                        name={`shoppingList.${index}.description`}
                                        placeholder="Part Description"
                                        title={user.description}
                                        style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                        disabled={!enableComment ? true : values.shoppingList[index].isSelected}
                                      />
                                      <ErrorMessage name={`childPartList.${index}.description`} render={() => errorField()} />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td className="p-2 font-bold text-lg" colSpan={2}>
                                    No Records Found!
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </FieldArray>
                </div>
                {data && !enableComment && (
                  <Comments
                    commentType="shoppingList"
                    commentsData={commentData.Comments}
                    comment={commentData}
                    eoplRequestId={eoplProgramData.id}
                    eoplProgramRefId={data}
                    indx={indexData}
                  />
                )}
              </Form>
              {/* <div className="grid grid-cols-3 gap-4px-4 py-3 bg-white text-left md:px-6 rounded-b">
                <Button
                  type="button"
                  onClick={() => {
                    return handlePrev(values);
                  }}
                  className={`bg-tertiary col-start-1 place-self-start flex items-center`}
                >
                  <ArrowLeftIcon className="h-4 mr-2" />
                  Project Details
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    return submitForm();
                  }}
                  color="success"
                  className={`place-self-center col-start-2`}
                >
                  Submit
                </Button>
              </div> */}
            </>
          );
        }}
      </Formik>
      {data && isOpenModal && (
        <Modal isOpen={isOpenModal} toggle={toggle} animate={true} closeOnClickOutside={true}>
          <ModalHeader>Delete Item</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">Remove Item From Shopping List</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={toggle} color="primary">
              Cancel
            </Button>
            <Button type="button" onClick={removeItem} color="danger" className="mr-1">
              Remove
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {data && isOpenPrompt && (
        <Modal isOpen={isOpenPrompt} toggle={toggle1} animate={true} closeOnClickOutside={true}>
          <ModalHeader>Version Change Prompt</ModalHeader>
          <ModalBody>
            <div className="d-flex flex-row bd-highlight mb-3">
              <div className="p-2 bd-highlight">Do you want a version change for this Shopping List Item ?</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={toggle1} color="danger">
              No
            </Button>
            <Button type="button" onClick={saveItem} color="success" className="mr-1">
              Yes
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {visibleImageUpload === true && imageData !== null && (
        <ImageUploadModal data={imageData} closeModal={() => toggleImgUploadModal()} />
      )}
    </div>
  );
};

export default ShoppingList;
