import { getRequest, postRequest, deleteRequest, patchRequest } from "../axios";
import { mailingListActions } from "./mailingListSlice";
import Toast from "../components/ui/Toast";

export const getMailingLists = () => {
  return async (dispatch) => {
    try {
      const mailingLists = await getRequest("mailList/");
      dispatch(mailingListActions.replaceMailingLists(mailingLists));
    } catch (error) {
      console.error("getMailingList: " + error.message);
      if (error.message.substring(0, 14) === "Request failed" || error.message.substring(0, 2) === "40") {
        dispatch(mailingListActions.setLoading(false));
      }
      if (error.message.substring(0, 14) !== "Request failed" && error.message.substring(0, 2) !== "40") Toast({ status: "error", message: error.message });
    }
  };
};

export const createMailingList = (mailingListData) => {
  return async (dispatch) => {
    try {
      const mailingList = await postRequest("mailList/", mailingListData);
      Toast({ status: "success", message: `Mailing List: ${mailingList.email} successfully added` });
      dispatch(mailingListActions.updateMailingList(mailingList));
    } catch (error) {
      console.error("createMailingList: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const updateMailingList = (mailingListData) => {
  return async (dispatch) => {
    try {
      const mailingList = await patchRequest(`mailList/${mailingListData.id}/`, mailingListData);
      Toast({ status: "info", message: `Mailing List: ${mailingList.email} successfully updated` });
      dispatch(mailingListActions.updateMailingList(mailingList));
    } catch (error) {
      console.error("updateMailingList: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteMailingList = (id) => {
  return async (dispatch) => {
    try {
      await deleteRequest(`mailList/${id}/`);
      Toast({ status: "success", message: `Mailing List deleted` });
      dispatch(mailingListActions.removeMailingList(id));
    } catch (error) {
      console.error("deleteMailingList: " + error.message);
      Toast({ status: "error", message: error.message });
    }
  };
};
