import { createSlice } from "@reduxjs/toolkit";
import produce from "immer";
// import { sortComments } from './eoplCommentsActions';

const initialValues = {
  eoplComment: {},
  headerComments: [],
  detailComments: [],
  loading: false,
  replyLoading: false,
  token: null,
  loaded: null,
  allDataComments: [],
  refreshCommentsData: true,
};
const eoplCommentsSlice = createSlice({
  name: "eoplComments",
  initialState: initialValues,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload ?? false;
    },
    setReplyLoading: (state, action) => {
      state.replyLoading = action.payload ?? false;
    },
    replaceEoplComment: (state, action) => {
      state.eoplComment = action.payload.eoplComment ?? {};
    },
    replaceEoplComments: (state, action) => {
      state.loaded = action.payload.loaded;
      state.headerComments = action.payload.headerComments;
      state.detailComments = action.payload.detailComments;
      state.loading = false;
    },
    removeEoplComment: (state, action) => {
      const id = action.payload.id;
      state.changed = true;
      if (state.allDataComments.filter((eoplComment) => eoplComment.id === id).length > 0) {
        state.allDataComments = state.allDataComments.filter((eoplComment) => eoplComment.id !== id);
      }
      state.loading = false;
    },
    updateEoplComment: produce((state, action) => {
      state.loading = false;
      state.allDataComments.push(action.payload);
      // const print = state.allDataComments;
    }),
    setEoplComments: (state, action) => {
      state.allDataComments = action.payload;
    },
    toggleRefresh: (state, action) => {
      state.refreshCommentsData = action.payload ?? true;
    },
  },
});

export const eoplCommentsActions = eoplCommentsSlice.actions;

export default eoplCommentsSlice;
