import { globalActions } from "./globalSlice";

export const setLoading = (state, label, error) => {
  return (dispatch) => {
    dispatch(
      globalActions.setLoading({
        loading: state ?? false,
        label: label ?? '',
        error: error ?? false
      })
    );
  }
}

