import { forwardRef } from "react";
// import Tippy, { tippy } from '@tippyjs/react';

const style = {
  default: `text-typography-2 focus:outline-none shadfow-sm rounded px-3 py-1 font-medium transition ease-in duration-200 ignore-click-outside`,
  color: {
    primary: `bg-primary focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:opacity-40`,
    secondary: `bg-secondary focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:opacity-40`,
    success: `bg-success !text-white focus:ring-2 focus:ring-offset-2 focus:ring-success disabled:opacity-40`,
    danger: `bg-danger !text-white focus:ring-2 focus:ring-offset-2 focus:ring-danger disabled:opacity-40`,
    dark: `bg-black focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 disabled:opacity-40`,
    light: `bg-primary text-typography-1 hover:bg-secondary/30 focus:ring-2 focus:ring-offset-2 focus:ring-secondary  disabled:opacity-40`,
    warning: `bg-warning focus:ring-2 focus:ring-offset-2 focus:ring-warning disabled:opacity-40`,
    indigo: `bg-indigo-900 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-900 disabled:opacity-40`,
  },
};

const Button = forwardRef(({ color, className, children, tippy, ...props }, ref) => {
  // const InputContainer = ({ children }) => {
  //   let container = <>{children}</>

  //   if (tippy) {
  //     container = (
  //       <Tippy arrow={true} placement="bottom" content={<span className='text-sm bg-tertiary/40 rounded px-2 py-1 shadow text-typography-1'>{tippy}</span>}>
  //         {children}
  //       </Tippy>
  //     )
  //   }
  //   return container
  // }

  return (
    // <InputContainer>
    //     </InputContainer>
    <button ref={ref} className={`${style.default} ${style.color[color || "primary"]} ${className}`} {...props}>
      {children}
    </button>
  );
});

export default Button;
