import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    timeline: [],
    customDates: [],
    quaterWiseData : [],
    currentQuaterProjectTotals : null,
    currentQuaterBrandWiseTotals : null,
    nextQuaterBrandWiseTotals : null,
    // data: [{
    //   field: '',
    //   value: 0,
    //   partNumber: '',
    //   partDescription: '',
    //   brand: ''
    // }],
    loading: true,
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    replaceDashboard(state, action) {
      state.timeline = action.payload.timeline;
      state.customDates = action.payload.customDates;
      state.quaterWiseData = action.payload.quaterWiseData;
      state.currentQuaterProjectTotals = action.payload.currentQuaterProjectTotals;
      state.currentQuaterBrandWiseTotals = action.payload.currentQuaterBrandWiseTotals;
      state.nextQuaterBrandWiseTotals = action.payload.nextQuaterBrandWiseTotals;
    },
  },
});

export const dashboardActions = dashboardSlice.actions;

export default dashboardSlice;
