import { getRequest, postRequest, putRequest, deleteRequest } from "../axios";
import { eoplCommentsActions } from "./eoplCommentsSlice";
import Toast from "../components/ui/Toast";

export const resetComments = () => {
  return async (dispatch) => {
    dispatch(eoplCommentsActions.replaceEoplComments({ detailComments: null, headerComments: null, loading: null }));
  };
};

export const sortComments = (comments) => {
  return comments.sort((a, b) => new Date(a.auditDateTime).getTime() - new Date(b.auditDateTime).getTime());
};

export const getEoplComments = (id) => {
  return async (dispatch) => {
    try {
      await getRequest("comments/?RequestHeaderId=" + id);
      // Further actions here
    } catch (error) {
      // console.error("getEoplComment: " + error.message)
      if (error.message.substring(0, 14) === "Request failed" || error.message.substring(0, 2) === "40") {
        dispatch(eoplCommentsActions.setLoading(false));
      }
      if (error.message.substring(0, 14) !== "Request failed" && error.message.substring(0, 2) !== "40") Toast({ status: "error", message: error.message });
    }
  };
};

export const createEoplComment = (eoplCommentData) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setLoading(true));
      const eoplComment = await postRequest("comments/", eoplCommentData);
      dispatch(eoplCommentsActions.updateEoplComment(eoplComment));
      Toast({ status: "success", message: "Comment added" });
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const createEoplCommentReply = (eoplCommentReplyData) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setLoading(true));
      const eoplComment = await postRequest("commentsreply/", eoplCommentReplyData);
      Toast({ status: "success", message: "Reply added" });
      dispatch(eoplCommentsActions.updateEoplComment(eoplComment));
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const updateEoplComment = (eoplCommentData) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setLoading(true));
      const response = await putRequest("eoplComments", eoplCommentData);
      Toast({ status: "success", message: response });
      dispatch(eoplCommentsActions.updateEoplComment(eoplCommentData));
    } catch (error) {
      // console.error("updateEoplComment: " + error.message)
      Toast({ status: "error", message: error.message });
    }
  };
};

export const deleteEoplComment = (id) => {
  return async (dispatch) => {
    try {
      const response = await deleteRequest(`comments/${id}/`);
      Toast({ status: "success", message: response.message });
      dispatch(eoplCommentsActions.removeEoplComment({ id }));
    } catch (error) {
      Toast({ status: "error", message: error.message });
    }
  };
};

export const setComments = (commentsData) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setEoplComments(commentsData));
    } catch (error) {}
  };
};

export const toggleCommentsRefresh = (value) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.toggleRefresh(value));
    } catch (error) {}
  };
};

export const toggleAddCommentLoading = (value) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setLoading(value));
    } catch (error) {}
  };
};

export const toggleReplyCommentLoading = (value) => {
  return async (dispatch) => {
    try {
      dispatch(eoplCommentsActions.setReplyLoading(value));
    } catch (error) {}
  };
};
